import "./input.css"
import { FloatingP, InpContainer, InputC, SelectDropdown } from "./styles"
const moment = require('moment');
export const InputComponent = ({title, placeholder, dropdown, value,onChange, type,max,dropDownDefault, isExpiry, disabled, stateProp, inputType}) => {
    console.log("max date",max)
    return(
        <InpContainer>
            <FloatingP>{title}</FloatingP>
            {dropdown ? (
                <SelectDropdown onChange={(e) => onChange(e.target.value)} disabled = {disabled}>
                    <option value={value?value:-1} disabled selected>{placeholder}</option>
                    {
                        stateProp ? 
                              Object.keys(dropdown)?.map((key) => (
                                 <option key={key} value={key}>
                                  {dropdown[key].charAt(0).toUpperCase() + dropdown[key].slice(1)}
                                </option>
                            ))
                         :
                        dropdown?.map(item => (
                            <>
                            {
                                value === item ?
                                <option selected value={item}>{item}</option>
                                :
                                <option value={item}>{item}</option>
                            }
                            </>
                        ))
                    }
                </SelectDropdown>
            ):(
                type==='number'?(
                    inputType === "pincode" ? (
                        <InputC disabled = {disabled} className="numberField"  type={'number'} value={value} placeholder={placeholder} onChange={(e)=>onChange(e.target.value)} maxLength='6' 
                        onInput={(e) => {e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                    }}/>
                    ):(
                        <div className="flex align-center num-container">
                        <p>+91</p>
                        <input type={type} value={value} className="numberField" placeholder={placeholder} 
                        onChange={(e)=>onChange(e.target.value)} maxLength='10' 
                        onInput={(e) => {e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                    }}/>
                    </div>
                    )
                   
                ): type==='otp'?(
                    <InputC disabled = {disabled}  type={'number'} value={value} placeholder={placeholder} onChange={(e)=>onChange(e.target.value)}/>
                ):
                (
                    <>
                    {
                        isExpiry ?  <InputC id= "expiryPolicy" disabled = {disabled} max={max} type={type} value={value} placeholder={placeholder} onChange={(e)=>onChange(e.target.value)}/> 
                       :<InputC disabled = {disabled}  max={max} type={type} value={value} placeholder={placeholder} onChange={(e)=>onChange(e.target.value)}/>
  
                    } 
                     </>
                )
            )}
        </InpContainer>
    )
}