import { ActiveTab, BarContainer, BottomContainer, BriefcaseIcon, CloseIcon, ConsumablesIcon, DiscountIcon, DropdownContainer, DropdownIcon, EngineIcon, HamburgerIcon, HelpIcon, InactiveTab, InvoiceIcon, MainDiv, TabsContainer, Tooltip, TyreIcon, PopupHeader, PopupMessage, PopupButton, PopupContainer, ButtonContainer } from "./styles"
import DropdownImg from '../../assets/brand-new-car/arrow-down.svg'
import DiscountImg from '../../assets/car-insurance/main-page/discount.svg'
import ConsumablesImg from '../../assets/car-insurance/main-page/consumables.svg'
import EngineImg from '../../assets/car-insurance/main-page/engine.svg'
import BriefcaseImg from '../../assets/car-insurance/main-page/briefcase.svg'
import InvoiceImg from '../../assets/car-insurance/main-page/invoice.svg'
import TyreImg from '../../assets/car-insurance/main-page/tyre.svg'
import { useCallback, useEffect, useState, useRef } from "react"
import HelpImg from '../../assets/car-policy-details/help.svg'
import CloseImg from '../../assets/brand-new-car/close-btn.svg'
import HamburgerImg from '../../assets/commons/hamburger.png'
import { useSelector, useDispatch } from "react-redux";
import { axiosPOSTCall } from "../../utilities/utils/apiPost"
import WarningPopup from "./TPInsurer";
import { updateCombinedModel, addons, GODIGITMotorQuote, KotakMotorQuote, getAddons, motorEnquiryAPI, resetMotorQuoteRedux, selectIDV } from '../../Redux/CarsRedux';
// import { carsRedux } from "../../Redux/CarsRedux";

const coverOption = [
    { name: "10000", value: "10000" },
    { name: "20000", value: "20000" },
    { name: "30000", value: "30000" },
    { name: "40000", value: "40000" },
    { name: "50000", value: "50000" },
    { name: "60000", value: "60000" },
    { name: "70000", value: "70000" },
    { name: "80000", value: "80000" },
    { name: "90000", value: "90000" },
    { name: "100000", value: "100000" },
    { name: "110000", value: "110000" },
    { name: "120000", value: "120000" },
    { name: "130000", value: "130000" },
    { name: "140000", value: "140000" },
    { name: "150000", value: "150000" },
    { name: "160000", value: "160000" },
    { name: "170000", value: "170000" },
    { name: "180000", value: "180000" },
    { name: "190000", value: "190000" },
    { name: "200000", value: "200000" }
  ]
  

export const FilterBar = ({ more, onShowMore, CallQuoteAPIs, updateCover, loading, setLoading, setSortByIdv }) => {
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    // console.log("🚀 ~ file: FilterBar.js ~ line 18 ~ FilterBar ~ combinedRedux", combinedRedux.addOns)
    const [expand, setExpand] = useState('')
    const [showTooltip, setShowTooltip] = useState('')
    const [windowSize, setWindowSize] = useState()
    const [expandMenu, setExpandMenu] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [vehicleActiveIndex, setVehicleActiveIndex] = useState(-1);
    const dispatch = useDispatch();
    const { addOnsList } = useSelector((state) => state.carsRedux);
    const [selectAll, setSelectAll] = useState(false);
    const [addonsActiveIndexes, setAddonsActiveIndexes] = useState([]);
    const [IDV, setIDV] = useState(0);
    const [finalIDV, setFinalIDV] = useState(0);
    const [lowestIDV, setLowestIDV] = useState(false);
    const [finalNCB, setFinalNCB] = useState(-1);
    const [activeTab, setActiveTab] = useState(0);
    const [previousAddonsLength, setPreviousAddonsLength] = useState(combinedRedux?.addOns?.length)
    const [callApi, setCallApi] = useState(false);
    const [callApiNCB, setCallApiNCB] = useState(false);
    const [first, setFirst] = useState(true);
    const motorQuote = useSelector((state) => state.carsRedux.motorQuote);
    const idvData = useSelector((state) => state.carsRedux.selectIDV);
    const [idvAPIState, setIdvAPIState] = useState(false);
    const [policyType1, setPolicyType] = useState('');
    const [callPTypeAPI, setCallPTypeAPI] = useState(false);
    const [ncbState, setNcbState] = useState(true);
    const [idvValue, setIdvValue] = useState(0);
    const [minIdvVal, setMinidvVal] = useState(0);
    const [maxIdvVal, setMaxIdvVal] = useState(0);
    const [showPOP, setShowPOP] = useState(false);
    const [selectedAddOns, setSelectedAddOns] = useState([]);
    const [unNamedSI, setUnnamedSI] = useState("100000");
    const [electricSI, setElectricSI] = useState(10000);
    const [nonElectricalSI, setNonElectricalSI] = useState(10000);
    const [biFuelSI, setBiFuelSI] = useState(10000);
    const [belongingSI, setBelongingSi] = useState(10000);
    const [addOnsErr, setAddOnsErr]=useState({electrical:"", nonElectrical:"",lpgCng:"",belongings:""})
    const [idvRange, setIdvRange] = useState(motorQuote.map(({ slug, minIdv, maxIdv }) => { return { slug, minIdv, maxIdv } }))
    const [idv, setIdv] = useState([...new Set(motorQuote.flatMap(obj => [obj.minIdv, obj.maxIdv]).filter(value => value !== undefined))].sort((a, b) => a - b))
    const electricInputRef = useRef(null);
    const nonElectricInputRef = useRef(null);
    const biFuelInputRef = useRef(null);
    const belongingsRef = useRef(null);
    useEffect(() => {
        if (combinedRedux?.newPolicyData?.policyType) {
            setPolicyType(combinedRedux?.newPolicyData?.policyType)
            if (callPTypeAPI) {
                setCallPTypeAPI(false);
                CallQuoteAPIs('new');
            }
        }
    }, [combinedRedux?.newPolicyData?.policyType])


    const updatePolicyType = (type) => {
        setPolicyType(type);
        setCallPTypeAPI(true)
        dispatch(motorEnquiryAPI({ newPolicyData: { policyType: type }, enquiryId: combinedRedux.enquiryId })).then((res) => {
            setExpand('')
        })
    }


    useEffect(() => {

        let obj = {
            enquiryId: combinedRedux.enquiryId,
            type: combinedRedux.type,
            regDate: combinedRedux.regDate
        }

    }, [combinedRedux])

    useEffect(() => {
        if (combinedRedux.type == "old") {
            if (combinedRedux.insurance?.isClaimMadeinLastYear == true || combinedRedux.insurance?.ownerChanged == true || combinedRedux.insurance?.isPolicyExpired == true) {
                setNcbState(false);
            }
        }
    }, [combinedRedux.insurance])

    useEffect(() => {
        if (motorQuote[0]?.addons?.length > 0) {
            var data = motorQuote[0].addons.map((item, index) => {
                var objCheck = combinedRedux.addOns.find((items) => items.slug === item.slug);
                if (objCheck) {
                    return objCheck;
                }
                else {
                    return {};
                }
            })
            data = data.filter(value => Object.keys(value).length !== 0);
            setAddonsActiveIndexes(data)
            if (data?.length == combinedRedux?.addOns?.length) {
                // setSelectAll(true);
            }
        }

        if (combinedRedux.type == "old") {
            if (combinedRedux.insurance?.isClaimMadeinLastYear == true || combinedRedux.insurance?.ownerChanged == true || combinedRedux.insurance?.isPolicyExpired == true) {
                setNcbState(false);
            }
        }
        setIdvRange(motorQuote.map(({ slug, minIdv, maxIdv }) => { return { slug, minIdv, maxIdv } }));
        setIdv([...new Set(motorQuote.flatMap(obj => [obj.minIdv, obj.maxIdv]).filter(value => value !== undefined))].sort((a, b) => a - b));
        console.log("maxIdv1", idv);
        if (idv.length > 0 && ((parseInt(idv[0]) < minIdvVal) || minIdvVal == 0))
            setMinidvVal(parseInt(idv[0]))
        if (idv.length > 0 && (parseInt(idv[idv.length - 1]) > maxIdvVal)) {
            setMaxIdvVal(parseInt(idv[idv.length - 1]));
            console.log("maxIdv", maxIdvVal);
        }
        // console.log("hellojjjjj");
    }, [motorQuote])
    useEffect(() => {
        if (combinedRedux.ncb) {

            if (combinedRedux.ncb != finalNCB && callApiNCB) {
                setFinalNCB(combinedRedux.ncb)
                CallQuoteAPIs('new')
                setCallApiNCB(false)
            }
        }
        if (combinedRedux.type == "old") {
            if (combinedRedux.insurance?.isClaimMadeinLastYear == false && combinedRedux?.insurance.ownerChanged == false && combinedRedux?.insurance.isPolicyExpired == false) {
                const ncbArrTemp = ['0', '20', '25', '35', '45', '50', '55']
                // var temp = 0;
                for (let i = 0; i < ncbArrTemp.length; i++) {
                    if (ncbArrTemp[i] == combinedRedux.ncb) {
                        setActiveIndex(i)
                        console.log("i is", i);
                    }
                }
            }
            else {
                setActiveIndex(0)
            }
        }
        else {
            setActiveIndex(0)
        }



    }, [combinedRedux.ncb])

    var coverList = ['Add-on', 'Additional Cover', 'Discount'];

    const handleWindowResize = useCallback(event => {
        setWindowSize(window.innerWidth);
    }, []);

    useEffect(() => {
        if (activeTab == 0) {
            if(combinedRedux.newPolicyData?.policyType == "privateCarOD")
            dispatch(getAddons({ remove: 'tpAddOns',category: 'cover' }));
           else dispatch(getAddons({ category: 'cover' }));
        }
        else if (activeTab == 1) {
            if(combinedRedux.newPolicyData?.policyType == "privateCarOD")
                dispatch(getAddons({ remove: 'tpAddOns',category: 'addtionalCover' }));
           else dispatch(getAddons({ category: 'addtionalCover' }));
        }
        else if (activeTab == 2) {
            dispatch(getAddons({ category: 'discount' }));
        }
    }, [activeTab])


    useEffect(() => {
        if (combinedRedux.vehicleIDV && idvAPIState) {
            // if(combinedRedux.vehicleIDV != finalIDV){
            setIDV(combinedRedux.vehicleIDV)
            setFinalIDV(combinedRedux.vehicleIDV)
            setExpand('')
            CallQuoteAPIs('new')
            setIdvAPIState(false);
            // }
        }
    }, [combinedRedux.vehicleIDV])


    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    useEffect(() => {
        if (windowSize < 1290 || window.innerWidth < 1290) {

        }
    }, [windowSize])


    const handleDropdownClose = val => {
        setExpand(val)
    }


    const ncbArr = [0, 20, 25, 35, 45, 50];
    const vehicleTypeArr = [{ name: 'Individual', value: 'individual' }];
    const updateNCB = () => {
        dispatch(motorEnquiryAPI({ ncb: ncbArr[activeIndex], enquiryId: combinedRedux.enquiryId }));
        setCallApiNCB(true);
        return
    };

    const updateType = (index) => {
        setVehicleActiveIndex(index)
        setExpand('')
        dispatch(motorEnquiryAPI({ vehicleType: vehicleTypeArr[index].value, enquiryId: combinedRedux.enquiryId }));
        CallQuoteAPIs('new')
        // dispatch(GODIGITMotorQuote(combinedRedux));
    };

    const handleMouseEnter = (inputRef) => {
        inputRef.current.focus();
    };
    const handleMouseLeave = (inputRef) => {
        inputRef.current.blur();
      };

    // checked add ons
    const isCheckedAddOn = (item) => {
        const isChecked = addonsActiveIndexes.findIndex((items) => items.slug === item.slug) > -1 ? true : false
        return isChecked
    }
    // add ons handles
    const addOnsHandler = (e, slug) => {
        if (slug == "electricalAccessories") {
            if(addOnsErr.electrical)
            setAddOnsErr(val=> {return {...val,electrical:""}})
            if(e.target.value <= 30000)
            setElectricSI(e.target.value);
        }
        else if (slug == "nonElectricalAccessories") {
            if(addOnsErr.nonElectrical)
            setAddOnsErr(val=> {return {...val,nonElectrical:""}})
            if(e.target.value <= 30000)
            setNonElectricalSI(e.target.value)
        }
        else if (slug == "lpgCngCheckCover") {
            if(addOnsErr.lpgCng)
            setAddOnsErr(val=> {return {...val,lpgCng:""}})
            if(e.target.value <= 80000)
            setBiFuelSI(e.target.value)
        }
        else if (slug == "lossOfPersonalBelongings") {
            if(addOnsErr.belongings)
            setAddOnsErr(val=> {return {...val,belongings:""}})
            if(e.target.value <= 30000)
            setBelongingSi(e.target.value)
        }
      
    }


    const updateActiveAddons = (e, item, index) => {
        var objCheck = addonsActiveIndexes.find((items) => items.slug === item.slug);
        console.log("objectCheck", objCheck, addonsActiveIndexes, item)
        if (objCheck?.slug == "personalAccident") {
            setShowPOP(true);
            return;
        }
        if (objCheck) {
            const newArr = addonsActiveIndexes.filter(object => {
                return object.slug !== item.slug;
            });
            setAddonsActiveIndexes(newArr)
            if (selectAll) {
                setSelectAll(false)
            }
            if (index == -1) {
                setCallApi(true);
                setLoading(true);
            }
        }
        else {
            if (index == -1) {
                setCallApi(true);
                setLoading(true);
            }
            if (item.slug == "unnamedPassenger")
                setAddonsActiveIndexes(oldArray => [...oldArray, { slug: item.slug, siValue: unNamedSI }])
           else if (item.slug == "lpgCngCheckCover")
                setAddonsActiveIndexes(oldArray => [...oldArray, { slug: item.slug, siValue: biFuelSI }])
           else if (item.slug == "electricalAccessories")
                setAddonsActiveIndexes(oldArray => [...oldArray, { slug: item.slug, siValue: electricSI }])
           else if (item.slug == "nonElectricalAccessories")
                setAddonsActiveIndexes(oldArray => [...oldArray, { slug: item.slug, siValue: nonElectricalSI }])
           else if (item.slug == "lossOfPersonalBelongings")
            setAddonsActiveIndexes(oldArray => [...oldArray, { slug: item.slug, siValue: belongingSI}])
            else
            setAddonsActiveIndexes(oldArray => [...oldArray, { slug: item.slug }])
            var arr = [...addonsActiveIndexes, { slug: item.slug }]
            console.log("🚀 ~ file: FilterBar.js ~ line 176 ~ updateActiveAddons ~ arr", addonsActiveIndexes)
        }
    }

    useEffect(() => {
        if (callApi) {
            // dispatch(resetMotorQuoteRedux('')).then((res)=>{
            dispatch(motorEnquiryAPI({ addOns: addonsActiveIndexes, enquiryId: combinedRedux.enquiryId })).then((res) => {
            })
        }
    }, [addonsActiveIndexes])


    const selectAllFunction = (item, index) => {
        if (selectAll) {
            setAddonsActiveIndexes([])
        }
        else {
            var arr = addOnsList.map((item, index) => {
                return {
                    slug: item.slug
                }
            })
            setAddonsActiveIndexes(arr)
        }
        setSelectAll(!selectAll)
    }

    useEffect(() => {
        if (combinedRedux?.addOns && callApi) {
            dispatch(resetMotorQuoteRedux('')).then((res) => {
                CallQuoteAPIs('new')
                setCallApi(false);
            })

        }
    }, [combinedRedux.addOns])


    const updateIDV = (value) => {
        console.log("idvvalue", idv, minIdvVal, maxIdvVal);
        // return
        if (lowestIDV) {
            if (IDV == 0)
                // return;
                setIDV(0);
            let objIdv = {
                vehicleIDV: "0",
                lowestIDV: lowestIDV,
                enquiryId: combinedRedux.enquiryId,
                idvData: idvRange
            }
            console.log("objIdv are -> ", objIdv);
            dispatch(motorEnquiryAPI(objIdv));
            setLoading(true);
            setIdvAPIState(true);
            // setSortByIdv(false);
        }
        else {
            let form = nameInput.current.value;
            console.log("enetred value", form, idv)

            // if(form && form>=parseInt(minIdvVal) && form <= maxIdvVal){
            if (form) {
                setIDV(form);

                let objIdv = {
                    vehicleIDV: form,
                    lowestIDV: lowestIDV,
                    enquiryId: combinedRedux.enquiryId,
                    idvData: idvRange
                }
                console.log("objIdv are -> ", objIdv);
                dispatch(motorEnquiryAPI(objIdv));
                setLoading(true);
                setIdvAPIState(true);
                setSortByIdv(true)
            }
            else {
                alert('Please enter a valid IDV value.');
            }
            // setCallApi(true);
        }
    }

    const validateError = (obj) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key]) {
              return true
            }
        }
        return false
  }
    const updateAddons = () => {
        console.log("si list",electricSI,nonElectricalSI,biFuelSI,addOnsErr)
        if( electricSI<10000){
            setAddOnsErr(val => {
                return {
                    ...val, electrical: "inputErr"
                }
            })
        } 
        if( nonElectricalSI<10000){
            setAddOnsErr(val => {
                return {
                    ...val, nonElectrical: "inputErr"
                }
          })
        } 
        if( biFuelSI<10000){
            setAddOnsErr(val => {
                return {
                    ...val, belongings: "inputErr"
                }
            })
        }
        if( biFuelSI<10000){
            setAddOnsErr(val => {
                return {
                    ...val, lpgCng: "inputErr"
                }
            })
        }
        if (validateError(addOnsErr)) 
           return
        setExpand('')
        const newAddOnsArr = addonsActiveIndexes.map(val => { return {...val} })
        newAddOnsArr.forEach(val => {
            if (val?.slug == "unnamedPassenger") {
                val.siValue=unNamedSI
            }
           else if (val?.slug == "electricalAccessories") {
                val.siValue=electricSI
            }
            else if (val?.slug == "nonElectricalAccessories") {
                val.siValue=nonElectricalSI
            }
            else if (val?.slug == "lpgCngCheckCover") {
                val.siValue=biFuelSI
            }
            else if (val?.slug == "lossOfPersonalBelongings") {
                val.siValue=belongingSI
            }
        })
        dispatch(motorEnquiryAPI({ addOns: newAddOnsArr, enquiryId: combinedRedux.enquiryId }));
        // setAddonsActiveIndexes(addonsActiveIndexes)
        // console.log("🚀 ~ file: FilterBar.js ~ line 213 ~ updateAddons ~ addonsActiveIndexes", addonsActiveIndexes)
        setCallApi(true);
        setLoading(true);

    };
    const nameInput = useRef(null);

    const onChangeIDV = (event) => {
        // setIDV(event.target.value.replace(/\D/g, ''));
        setIDV(event.target.value.replace(/\D/g, ''));
    }

        const removeCPA = (tpInsurer, tpPolicyNo, tpEndDate) => {
            const newArr = addonsActiveIndexes.filter(object => {
                return object.slug !== "personalAccident";
            });
            dispatch(updateCombinedModel({...combinedRedux,insurance:{...combinedRedux.insurance,isValidLicence:false, tpEndDate: tpEndDate, tpInsurerName: tpInsurer, tpPolicyNumber: tpPolicyNo, isExistCCPA: true}}))
            setAddonsActiveIndexes(newArr);
            setShowPOP(false)
        }

    // const WarningPopup = () => {
    //     const closePopup = () => {
    //         // Implement your logic to close the popup
    //         setShowPOP(false)
    //         console.log('Popup closed');
    //     };
    //     const removeCPA = () => {
    //         const newArr = addonsActiveIndexes.filter(object => {
    //             return object.slug !== "personalAccident";
    //         });
    //         setAddonsActiveIndexes(newArr);
    //         setShowPOP(false)
    //     }

    //     return (
    //         <PopupContainer>
    //             {/* <CloseIcon src={CloseImg} /> */}
    //             <PopupHeader>Warning!</PopupHeader>
    //             <PopupMessage>
    //                 <h3>CPA cover is mandatory. If you want to remove it, you should have:</h3>
    //                 <div className="message">
    //                 <p>1. A valid License.</p>
    //                 <p>2. PA Cover Policy for SI 15 Lakhs and Above (Including Death , Total disability, partial disability).</p>
    //                 </div>
    //             </PopupMessage>
    //             <ButtonContainer>
    //                 <PopupButton onClick={closePopup}>Cancel</PopupButton>
    //                 <PopupButton onClick={removeCPA}>Remove</PopupButton>
    //             </ButtonContainer>

    //         </PopupContainer>
    //     );
    // };

    const DropdownFilter = ({ coverValue, prevNcb, policyType, vehicleType, addCovers, onClose }) => {
        if (coverValue) {
            return (
                <DropdownContainer>
                    <div className="flex idv">
                        <input
                            type='radio'
                            id='low-idv'
                            name="idv"
                            checked={lowestIDV}
                            onClick={() => { setLowestIDV(true) }}
                        />
                        <label for='low-idv'>Default IDV</label>
                        <div className="pos-rel">
                            <HelpIcon
                                onMouseOver={() => setShowTooltip('idv')} src={HelpImg}
                                onMouseOut={() => setShowTooltip('')}
                            />
                            {showTooltip === 'idv' ? (
                                <Tooltip>Your IDV should be at least 10% less than your previous year policy's IDV. This would be mentioned in your previous policy.</Tooltip>
                            ) : null}
                        </div>
                    </div>
                    <div className="flex">
                        <input
                            type='radio'
                            id='choose-idv'
                            name="idv"
                            checked={!lowestIDV}
                            onClick={() => { setLowestIDV(false) }}
                        />
                        <label for='choose-idv'>Choose your own IDV</label>
                    </div>
                    {!lowestIDV && <>
                        {/* <p className="small desc">Enter IDV value between</p> */}
                        <div className="flex input-div">
                            <p>₹ </p>
                            <input

                                type={'text'}
                                placeholder={'Enter IDV value'}
                                // value={IDV}
                                name={'idv'}
                                ref={nameInput}
                            // onChange={(event)=>{setIDV(event.target.value.replace(/\D/g, ''));nameInput.current.focus();}}
                            // onChange={()=>setIDV(e.target.value)}
                            />
                        </div></>}
                    <button className="button" onClick={() => updateIDV()}>Update</button>
                </DropdownContainer>
            )
        }
        if (prevNcb) {
            return (
                ncbState ? <DropdownContainer>
                    {
                        ncbArr?.map((item, index) => (
                            <div className="flex" onClick={() => combinedRedux.insurance.isClaimMadeinLastYear == false || combinedRedux.insurance.ownerChanged == false ? setActiveIndex(index) : setActiveIndex(0)}>
                                <input
                                    type='radio'
                                    id='zero'
                                    checked={activeIndex == index ? true : false}
                                />
                                <label for='zero'>{item}%</label>
                            </div>
                        ))
                    }

                    <button className="button" onClick={() => { updateNCB(); setExpand(''); }}>Update</button>
                </DropdownContainer> : null
            )
        }
        if (policyType) {
            return (
                <DropdownContainer style={{ width: 250 }}>
                    {combinedRedux.type == "old" && <div className="flex">
                        <input
                            type='radio'
                            id='standalone-tp'
                            onChange={(e) => { updatePolicyType('privateCarTP') }}
                            checked={policyType1 == 'privateCarTP' ? true : false}
                        />
                        <label for='standalone-tp'>Standalone TP</label>
                        <div className="pos-rel">
                            <HelpIcon
                                src={HelpImg}
                                onMouseOver={() => setShowTooltip('std-tp')}
                                onMouseOut={() => setShowTooltip('')}
                            />
                            {/* {showTooltip === 'std-tp' ? (
                                <Tooltip>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</Tooltip>
                            ) : null} */}
                        </div>
                    </div>
                    }
                    {combinedRedux.type == "old" && <div className="flex">
                        <input
                            type='radio'
                            id='standalone-od'
                            checked={policyType1 == 'privateCarOD' ? true : false}
                            onChange={(e) => { updatePolicyType('privateCarOD') }}
                        />
                        <label for='standalone-od'>Standalone OD</label>
                        <div className="pos-rel">
                            <HelpIcon
                                src={HelpImg}
                                onMouseOver={() => setShowTooltip('std-od')}
                                onMouseOut={() => setShowTooltip('')}
                            />
                            {/* {showTooltip === 'std-od' ? (
                                <Tooltip>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</Tooltip>
                            ) : null} */}
                        </div>
                    </div>
                    }
                    {combinedRedux.type == "old" && <div className="flex">
                        <input
                            type='radio'
                            id='comprehensive'
                            checked={policyType1 == 'privateCarComprehensive' ? true : false}
                            onChange={(e) => { updatePolicyType('privateCarComprehensive') }}
                        />
                        <label for='comprehensive'>Comprehensive</label>
                        <div className="pos-rel">
                            <HelpIcon
                                src={HelpImg}
                                onMouseOver={() => setShowTooltip('comp')}
                                onMouseOut={() => setShowTooltip('')}
                            />
                            {/* {showTooltip === 'comp' ? (
                                <Tooltip>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</Tooltip>
                            ) : null} */}
                        </div>
                    </div>
                    }
                    {combinedRedux.type == "new" && <div className="flex">
                        <input
                            type='radio'
                            id='tp-policy'
                            className="last-radio"
                            onChange={(e) => { updatePolicyType('tp-policy') }}
                            checked={policyType1 == 'tp-policy' ? true : false}
                        />
                        <label for='tp-policy'>TP 3 years + OD 1 Year</label>
                        <div className="pos-rel">
                            <HelpIcon
                                src={HelpImg}
                                onMouseOver={() => setShowTooltip('tp-3')}
                                onMouseOut={() => setShowTooltip('')}
                            />
                            {/* {showTooltip === 'tp-3' ? (
                                <Tooltip>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</Tooltip>
                            ) : null} */}
                        </div>
                    </div>
                    }
                </DropdownContainer>
            )
        }
        if (vehicleType) {
            return (
                <DropdownContainer>
                    {
                        vehicleTypeArr?.map((item, index) => (
                            <div className="flex">
                                <input
                                    type='radio'
                                    id='individual'
                                    defaultChecked={item.value == "individual"}
                                    checked={true}
                                />
                                <label for='individual'>{item.name}</label>
                            </div>
                        ))
                    }
                </DropdownContainer>
            )
        }
        if (addCovers) {
            return (
                <DropdownContainer className="add-cover">
                    <CloseIcon onClick={() => onClose('')} src={CloseImg} />
                    <TabsContainer>
                        {
                            coverList.map((item, index) => (
                                <>
                                    {
                                        activeTab == index ?
                                            <ActiveTab onClick={() => { setActiveTab(index) }}>{item}</ActiveTab>
                                            :
                                            <InactiveTab onClick={() => { setActiveTab(index) }}>{item}</InactiveTab>
                                    }
                                </>
                            ))
                        }
                    </TabsContainer>
                    <BottomContainer>
                        <div className="flex apart">
                            <p className="theme-font">{(addonsActiveIndexes?.filter(slug => addOnsList?.some(obj => obj.slug == slug.slug))).length} of {addOnsList?.length} selected</p>
                        </div>
                        <hr />
                        {showPOP && <WarningPopup closePopUp = {()=>{setShowPOP(false)}} removeCPA={removeCPA} />}
                        {
                            activeTab == 0 ?
                                <div className="grid mt-1">
                                    {
                                        addOnsList.length > 0 && addOnsList.map((item, index) => (
                                            <div>
                                                <div className="flex">
                                                    <input
                                                        type='checkbox'
                                                        id={item._id}
                                                        checked={selectAll ? selectAll : addonsActiveIndexes.findIndex((items) => items.slug === item.slug) > -1 ? true : false}
                                                        // onChange={() => setAddonsActiveIndexes(oldArray => [...oldArray,index] )}
                                                        // onChange={() => {console.log(addonsActiveIndexes.find((items)=>{items==item._id}))}}
                                                        onChange={(e) => updateActiveAddons(e, item, index)}
                                                    />
                                                    <label for='engine'>{item.name}</label>
                                                    <HelpIcon src={HelpImg} />
                                                </div>
                                                {(item?.slug == "unnamedPassenger" && isCheckedAddOn(item)) && <select
                                                    id="adhbInpu"
                                                    className="addonsInput unnamed"
                                                    onChange={e => { setUnnamedSI(e.target.value) }}
                                                    value={unNamedSI}
                                                >
                                                    {coverOption.map(val => <option value={val.value} key={val.value}>{val.name }</option>)}
                                                </select>}
                                                {
                                                     (item.name == "LPG CNG Check" && isCheckedAddOn(item)) &&
                                                       <div className="inputSI">
                                                        <input type="text"
                                                                className={`addonsInput unnamed1 ${addOnsErr.lpgCng}`}
                                                                id="inputVal1"
                                                                value={biFuelSI}
                                                                placeholder=" 10,000 to 80,000"
                                                                onChange={e=>addOnsHandler(e,item.slug)}
                                                                min={10000}
                                                                max={15000}
                                                                ref={biFuelInputRef}
                                                                onMouseEnter={()=>handleMouseEnter(biFuelInputRef)}
                                                                onMouseLeave={()=>handleMouseLeave(biFuelInputRef)}
                                                        />
                                                    </div>
                                                }
                                                {
                                                     ( item.name == "Electrical Accessories Cover" && isCheckedAddOn(item)) &&
                                                       <div className="inputSI">
                                                        <input type="text"
                                                                className={`addonsInput unnamed1 ${addOnsErr.electrical}`}
                                                                id="inputVal1"
                                                                value={electricSI}
                                                                placeholder=" 10,000 to 30,000"
                                                                onChange={e=>addOnsHandler(e, item.slug)}
                                                                min={10000}
                                                                max={30000}
                                                                ref={electricInputRef}
                                                                onMouseEnter={()=>handleMouseEnter(electricInputRef)}
                                                                onMouseLeave={()=>handleMouseLeave(electricInputRef)}
                                                        />
                                                    </div>
                                                }
                                                {
                                                     (item.name == "Non Electrical Accessories Cover" && isCheckedAddOn(item))  &&
                                                       <div className="inputSI">
                                                        <input type="text"
                                                                className={`addonsInput unnamed1 ${addOnsErr.nonElectrical}`}
                                                                id="inputVal1"
                                                                value={nonElectricalSI}
                                                                placeholder=" 10,000 to 30,000"
                                                                onChange={e=>addOnsHandler(e, item.slug)}
                                                                min={10000}
                                                                max={30000}
                                                                onMouseEnter={()=>handleMouseEnter(belongingsRef)}
                                                                onMouseLeave={()=>handleMouseLeave(belongingsRef)}
                                                                ref={belongingsRef}
                                                        />
                                                    </div>
                                                }
                                                {
                                                     (item.name == "Loss Of Personal Belongings" && isCheckedAddOn(item))  &&
                                                       <div className="inputSI">
                                                        <input type="text"
                                                                className={`addonsInput unnamed1 ${addOnsErr.belongings}`}
                                                                id="inputVal1"
                                                                value={nonElectricalSI}
                                                                placeholder=" 10,000 to 30,000"
                                                                onChange={e=>addOnsHandler(e, item.slug)}
                                                                min={10000}
                                                                max={30000}
                                                                onMouseEnter={()=>handleMouseEnter(nonElectricInputRef)}
                                                                onMouseLeave={()=>handleMouseLeave(nonElectricInputRef)}
                                                                ref={nonElectricInputRef}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                                :
                                <div className="grid mt-1">
                                    {
                                        addOnsList.map((item, index) => (
                                            <div>
                                                <div className="flex">
                                                    <input
                                                        type='checkbox'
                                                        id={item._id}
                                                        checked={selectAll ? selectAll : addonsActiveIndexes.findIndex((items) => items.slug === item.slug) > -1 ? true : false}
                                                        // onChange={() => setAddonsActiveIndexes(oldArray => [...oldArray,index] )}
                                                        // onChange={() => {console.log(addonsActiveIndexes.find((items)=>{items==item._id}))}}
                                                        onChange={(e) => updateActiveAddons(e, item, index)}
                                                    />
                                                    <label for='engine'>{item.name}</label>
                                                    <HelpIcon src={HelpImg} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                        }
                        <button className="button" style={{ marginTop: 10, width: 120, justifyContent: 'center' }} onClick={() => updateAddons()}>Update</button>
                    </BottomContainer>
                </DropdownContainer>
            )
        }
    }

    return (
        <>
            {
                loading == false ?
                    <MainDiv>
                        {
                            loading == false &&
                                windowSize < 1150 || window.innerWidth < 1150 ? (
                                <>
                                    <HamburgerIcon
                                        onClick={() => setExpandMenu(prev => !prev)}
                                        src={HamburgerImg}
                                    />
                                    {expandMenu ? (
                                        <>
                                            <BarContainer>
                                                <div className={combinedRedux?.newPolicyData?.policyType == "privateCarTP" ? "pos-rel clear" : "pos-rel"}>
                                                <li>Cover Value : <span onClick={() => {
                                                     if(combinedRedux?.newPolicyData?.policyType == "privateCarTP")
                                                        return;
                                                        if (expand) {
                                                            setExpand('')
                                                        } else {
                                                            setExpand('coverVal')
                                                        }
                                                    }
                                                    }>At the lowest <DropdownIcon src={DropdownImg} /></span> </li>
                                                    {expand === 'coverVal' ? <DropdownFilter coverValue={true} /> : null}
                                                </div>
                                                <div className="pos-rel">
                                                    <li>Previous NCB : <span onClick={() => {
                                                        if (expand) {
                                                            setExpand('')
                                                        } else {
                                                            setExpand('prevNcb')
                                                        }
                                                    }} className="no-u">{combinedRedux.insurance.isClaimMadeinLastYear == true || combinedRedux.insurance.ownerChanged == true ? 0 : combinedRedux?.ncb}% <DropdownIcon src={DropdownImg} /></span> </li>
                                                    {expand === 'prevNcb' ? <DropdownFilter prevNcb={true} /> : null}
                                                </div>
                                                {/* <div className="pos-rel">
                                                    <li>Policy Type : <span onClick={() => {
                                                        if (expand) {
                                                            setExpand('')
                                                        } else {
                                                            setExpand('policyType')
                                                        }
                                                    }} className="no-u">All <DropdownIcon src={DropdownImg} /></span> </li>
                                                    {expand === 'policyType' ? <DropdownFilter policyType={true} /> : null}
                                                </div> */}
                                                <div className="pos-rel">
                                                    <li><span onClick={() => {
                                                        if (expand) {
                                                            setExpand('')
                                                        } else {
                                                            setExpand('addCover')
                                                        }
                                                    }} className="no-u">Add Covers <DropdownIcon src={DropdownImg} /></span> </li>
                                                    {expand === 'addCover' ? <DropdownFilter onClose={handleDropdownClose} addCovers={true} /> : null}
                                                </div>

                                                <div className="pos-rel">
                                                    <li>Vehicle Type : <span onClick={() => {
                                                        if (expand) {
                                                            setExpand('')
                                                        } else {
                                                            setExpand('vehicleType')
                                                        }
                                                    }} className="no-u">All <DropdownIcon src={DropdownImg} /></span> </li>
                                                    {expand === 'vehicleType' ? <DropdownFilter vehicleType={true} /> : null}
                                                </div>
                                                <li
                                                    onClick={() => onShowMore(prev => !prev)}
                                                    className="show-more">
                                                    Show more <span className="no-u"> <DropdownIcon src={DropdownImg} /></span>
                                                </li>
                                                <li className="clear">Clear Filters</li>
                                            </BarContainer>
                                            {more ? (
                                                <BarContainer>
                                                    <p>Add Ons : </p>
                                                    <div className="flex align-center more-div">
                                                        <div className="flex">
                                                            <input id='discount' type="checkbox"
                                                            />
                                                            <DiscountIcon src={DiscountImg} />
                                                        </div>
                                                        <label for='discount'>Zero Depreciation Cover</label>
                                                    </div>
                                                    <div className="flex align-center more-div">
                                                        <div className="flex">
                                                            <input id='consumables' type="checkbox" />
                                                            <ConsumablesIcon src={ConsumablesImg} />
                                                        </div>
                                                        <label for='consumables'>Consumables</label>
                                                    </div>
                                                    <div className="flex align-center more-div">
                                                        <div className="flex">
                                                            <input id='engine' type="checkbox" />
                                                            <EngineIcon src={EngineImg} />
                                                        </div>
                                                        <label for='engine'>Engine Protect</label>
                                                    </div>
                                                    <div className="flex align-center more-div">
                                                        <div className="flex">
                                                            <input id='invoice' type="checkbox" />
                                                            <InvoiceIcon src={InvoiceImg} />
                                                        </div>
                                                        <label for='invoice'>Return to Invoice</label>
                                                    </div>
                                                    <div className="flex align-center more-div">
                                                        <div className="flex">
                                                            <input id='tyre' type="checkbox" />
                                                            <TyreIcon src={TyreImg} />
                                                        </div>
                                                        <label for='tyre'>Tyre Protect</label>
                                                    </div>
                                                    <div className="flex align-center more-div">
                                                        <div className="flex">
                                                            <input id='personal' type="checkbox" />
                                                            <BriefcaseIcon src={BriefcaseImg} />
                                                        </div>
                                                        <label for='personal'>Personal Belongings</label>
                                                    </div>
                                                </BarContainer>
                                            ) : null}
                                        </>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <BarContainer>
                                        <div className={combinedRedux?.newPolicyData?.policyType == "privateCarTP" ? "pos-rel clear" : "pos-rel"}>
                                       <li>Cover Value : {finalIDV ? finalIDV : ""} <span onClick={() => {
                                          if(combinedRedux?.newPolicyData?.policyType == "privateCarTP")
                                            return;
                                                if (expand) {
                                                    setExpand('')
                                                } else {
                                                    setExpand('coverVal')
                                                }
                                            }
                                            }><DropdownIcon src={DropdownImg} /></span> </li>
                                            {expand === 'coverVal' ? <DropdownFilter coverValue={true} /> : null}
                                        </div>
                                           
                                        <div className="pos-rel">
                                            <li>Previous NCB : <span onClick={() => {
                                                if (expand) {
                                                    setExpand('')
                                                } else {
                                                    setExpand('prevNcb')
                                                }
                                            }} className="no-u">{combinedRedux.type == "old" && combinedRedux?.insurance?.isClaimMadeinLastYear == true || combinedRedux?.insurance?.ownerChanged == true ? 0 : combinedRedux?.ncb}% <DropdownIcon src={DropdownImg} /></span> </li>
                                            {expand === 'prevNcb' ? <DropdownFilter prevNcb={true} /> : null}
                                        </div>
                                        {/* <div className="pos-rel">
                                            <li>Policy Type : <span onClick={() => {
                                                if (expand) {
                                                    setExpand('')
                                                } else {
                                                    setExpand('policyType')
                                                }
                                            }} className="no-u">{policyType1 == 'privateCarOD' ? 'Standalone OD' : policyType1 == 'privateCarComprehensive' ? 'Comprehensive' : policyType1 == 'privateCarTP' ? 'Standalone TP' : policyType1 == 'tp-policy' ? 'TP Policy' : null} <DropdownIcon src={DropdownImg} /></span> </li>
                                            {expand === 'policyType' ? <DropdownFilter policyType={true} /> : null}
                                        </div> */}
                                        <div className="pos-rel">
                                            <li><span onClick={() => {
                                                if (expand) {
                                                    setExpand('')
                                                } else {
                                                    setExpand('addCover')
                                                }
                                            }} className="no-u">Add Covers <DropdownIcon src={DropdownImg} /></span> </li>
                                            {expand === 'addCover' ? <DropdownFilter onClose={handleDropdownClose} addCovers={true} /> : null}
                                        </div>

                                        <div className="pos-rel">
                                            <li>Vehicle Type : <span onClick={() => {
                                                if (expand) {
                                                    setExpand('')
                                                } else {
                                                    setExpand('vehicleType')
                                                }
                                            }} className="no-u">All <DropdownIcon src={DropdownImg} /></span> </li>
                                            {expand === 'vehicleType' ? <DropdownFilter vehicleType={true} /> : null}
                                        </div>
                                        <li
                                            onClick={() => onShowMore(prev => !prev)}
                                            className="show-more">
                                            Show more <span className="no-u"> <DropdownIcon src={DropdownImg} /></span>
                                        </li>
                                        <li className="clear">Clear Filters</li>
                                    </BarContainer>
                                    {more ? (
                                        <BarContainer>
                                            <p>Add Ons : </p>
                                            <div className="flex align-center more-div">
                                                <div className="flex">
                                                    <input id='discount' type="checkbox"
                                                        checked={addonsActiveIndexes.findIndex((items) => items.slug === 'zeroDepreciationCover') > -1 ? true : false}
                                                        onChange={(e) => updateActiveAddons(e, { slug: 'zeroDepreciationCover' }, -1)}
                                                    />
                                                    <DiscountIcon src={DiscountImg} style={{ margin: 7 }} />
                                                </div>
                                                <label for='discount'>Zero Depreciation Covers</label>
                                            </div>
                                            <div className="flex align-center more-div">
                                                <div className="flex">
                                                    <input id='consumables' type="checkbox"
                                                        checked={addonsActiveIndexes.findIndex((items) => items.slug === 'consumables') > -1 ? true : false}
                                                        onChange={(e) => updateActiveAddons(e, { slug: 'consumables' }, -1)}
                                                    />
                                                    <ConsumablesIcon src={ConsumablesImg} style={{ margin: 7 }} />
                                                </div>
                                                <label for='consumables'>Consumables</label>
                                            </div>
                                            <div className="flex align-center more-div">
                                                <div className="flex">
                                                    <input id='engine' type="checkbox"
                                                        checked={addonsActiveIndexes.findIndex((items) => items.slug == 'engineProtection') > -1 ? true : false}
                                                        onChange={(e) => updateActiveAddons(e, { slug: 'engineProtection' }, -1)}
                                                    />
                                                    <EngineIcon src={EngineImg} style={{ margin: 7 }} />
                                                </div>
                                                <label for='engine'>Engine Protect</label>
                                            </div>
                                            <div className="flex align-center more-div">
                                                <div className="flex">
                                                    <input id='invoice' type="checkbox"
                                                        checked={addonsActiveIndexes.findIndex((items) => items.slug === 'returnToInvoice') > -1 ? true : false}
                                                        onChange={(e) => updateActiveAddons(e, { slug: 'returnToInvoice' }, -1)}
                                                    />
                                                    <InvoiceIcon src={InvoiceImg} style={{ margin: 7 }} />
                                                </div>
                                                <label for='invoice'>Return to Invoice</label>
                                            </div>
                                            <div className="flex align-center more-div">
                                                <div className="flex">
                                                    <input id='tyre' type="checkbox"
                                                        checked={addonsActiveIndexes.findIndex((items) => items.slug === 'tyreProtection') > -1 ? true : false}
                                                        onChange={(e) => updateActiveAddons(e, { slug: 'tyreProtection' }, -1)}
                                                    />
                                                    <TyreIcon src={TyreImg} style={{ margin: 7 }} />
                                                </div>
                                                <label for='tyre'>Tyre Protect</label>

                                            </div>
                                            <div className="flex align-center more-div">
                                                <div className="flex">
                                                    <input id='personal' type="checkbox" />
                                                    <BriefcaseIcon src={BriefcaseImg} style={{ margin: 7 }} />
                                                </div>
                                                <label for='personal'>Personal Belongings</label>
                                            </div>
                                        </BarContainer>
                                    ) : null}
                                </>
                            )}

                    </MainDiv>
                    : null
            }

        </>
    )
}