// Dashboard Profile Page
import { MainContainer, ProfileMainContainer, SaveBtn, UserImgContainer, Opacity, ErrorContainer, ErrorMsgContainer, ErrArrow, ErrorIcon } from "./styles"
import UserImg from '../../../assets/dashboard/user-img.png';
import EditUserSvg from '../../../assets/dashboard/edit-img.svg';
import { InputComponentDash } from "./input-component-dash/InputComponentDash";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { RxAvatar } from "react-icons/rx";
import ErrorIconImg from '../../../../src/assets/brand-new-car/exclamation.svg';
import { updateProfile } from '../../../Redux/AuthRedux'
import { uploadFile } from "../../../components/POS/uploadFile"
import { useRef } from "react";
import { validation } from "../../../utilities/validations";
import { axiosGETCall1, axiosPOSTCall1, axiosPUTCall1 } from "../../../utilities/utils/apiPost"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaFileDownload } from 'react-icons/fa';


export const Profile = ({ setEditTrigger }) => {

    const { userDetails } = useSelector((state) => state.auth);
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [userId, setUserId] = useState('');
    const profileRef = useRef();
    const cameraRef = useRef();
    const [email, setEmail] = useState('');
    const [aadhar, setAadhar] = useState('');
    const [psswd, setPsswd] = useState({
        currentPassword: '',
        password: '',
        cPassword: '',
    })
    const [profile, setProfile] = useState('');
    const [profileToggler, setProfileToggler] = useState(true);
    const [error, setError] = useState({});
    const [rmDetails, setRmDetails] = useState("");
    const [posDetails, setPosDetails] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();


    // change PasswordPage
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setPsswd({
            ...psswd,
            [name]: value
        });
    };
  
    const handleBulkDownload = async () => {
      console.log('Downloading documents...--3400', userDetails);
      try {
        const response = await axios.post( `${process.env.REACT_APP_API_URL}/pospInfo/download-documents`, { posId: userDetails.posId }, { responseType: 'blob' });
  
                console.log('Response Data Size:', response.data.size); // Debugging step
  
                if (response.data.size === 0) {
                  console.error('Received empty ZIP file!');
                  return;
                }
                
  
        // Create a URL for the blob response
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'documents.zip'); // Set download file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading documents:', error);
      }
    };


    useEffect(() => {
        // var Authorization = localStorage.getItem('userDetails');
        if (userDetails?.roles?.includes("pos")) {
            axiosGETCall1(`/pospInfo/getRMInfo`, {}, (callBack => {
                if (callBack.status === "Success") {
                    setRmDetails(callBack?.data[0])
                    // console.log("callback are bewfnlk. ,", callBack);
                }
            }));
        }
        console.log();
        if (userDetails && userDetails?.hasOwnProperty('fullName')) {
            setFullName(userDetails?.fullName)
            setPhone(userDetails?.phone)
            setEmail(userDetails?.email)
            setProfile(userDetails?.photo)
            setUserId(userDetails?._id || userDetails?.userId)
            setAadhar(userDetails.aadhar)
        }
    }, [userDetails]);

    useEffect(() => {
        if (userDetails?.roles?.includes("pos")) {
            axiosGETCall1(`/pospInfo/getPosInfo`, {}, (callBack => {
                if (callBack.status === "Success") {
                    setPosDetails(callBack?.data)

                }
            }));
        }
    }, [userDetails]);

    // console.log("name --->", fullName, phone, email);
    const uploadProfile = async (e) => {
        console.log(e.target.files[0]);
        if (e.target.files[0].size < 10485760) {
            const rl = await uploadFile(e.target.files[0])
            setProfile(rl.data[0].url)
            profileRef.current.style.display = "block"
            cameraRef.current.style.display = "none"
        }
        else {
            alert("Please, Reduce the size of image (below 10 MB) ")
        }
    }
    const updateProfileFun = () => {
    setError({}); // Reset errors

    if (!validation(fullName, 'name').status) {
        setError(prev => ({ ...prev, name: "Please Enter valid FullName" }));
    } else if (!validation(email, 'email').status) {
        setError(prev => ({ ...prev, email: "Please Enter valid Email" }));
    } else if (!validation(phone, 'mob').status) {
        setError(prev => ({ ...prev, phone: "Please Enter valid Number" }));
    } else if (aadhar && !validation(aadhar, 'aadhar').status) {
        setError(prev => ({ ...prev, aadhar: "Please Enter valid Aadhar Number" }));
    } else if (psswd.password && !validation(psswd.password, 'password').status) {
        setError(prev => ({ ...prev, password: "Invalid password. It must be at least 8 characters" }));

    } else {
         if (psswd.currentPassword || psswd.password || psswd.cPassword) {
                if (!psswd.currentPassword) {
                    return setError({ currentPassword: 'Current Password cannot be empty' })
                }
                if (!psswd.cPassword){ 
                    return setError({ cPassword: 'Confirm cannot be empty' })
                }
                if (psswd.password !== psswd.cPassword) {
                    return setError({ cPassword: "Confirm Password must be same" })
                }
                const obj = {
                    fullName: fullName,
                    email: email,
                    phone: phone,
                    photo: profile,
                    password: psswd.password,
                    currentPassword: psswd.currentPassword,
                    aadhar: aadhar,
                    userId: userDetails.userId ? userDetails.userId : userDetails._id
                }
                dispatch(updateProfile(obj)).then((res) => {
                if (res?.payload?.status === "Error") {
                    alert(res?.payload?.message);
                } else {
                    alert('Profile Updated Successfully.');
                        localStorage.clear();
                        navigate('/signin-signup?sourcePage=header')
                        window.location.reload();
                }
            });

            } else {
                const obj = {
                    fullName: fullName,
                    email: email,
                    phone: phone,
                    photo: profile,
                    aadhar: aadhar,
                    userId: userDetails.userId ? userDetails.userId : userDetails._id
                }
                dispatch(updateProfile(obj));
                alert('Profile Updated Successful.')
            }
        }
    }

    const ErrorPopup = errors => {
        console.log("eroor", error);
        return (
            <>
                <ErrorContainer>
                    <ErrorMsgContainer>{errors.error}</ErrorMsgContainer>
                    <ErrArrow />
                    <ErrorIcon src={ErrorIconImg} />
                </ErrorContainer>
            </>
        )
    }
    const removeError = (key) => {
        switch (key) {
            case 'password':
                return setError({ password: "" })
            case 'cPassword':
                return setError({ cPassword: "" })
            case 'aadhar':
                return setError({ aadhar: "" })
            case 'name':
                return setError({ name: "" })
            default:
                break
        }
    }
    return (
      <ProfileMainContainer>
        <UserImgContainer>
          {profile && (
            <img
              className="profile_photo"
              alt="profile_photo"
              src={profile}
              ref={profileRef}
            />
          )}
          {/* <img className="profile_photo" style={{ display: profile ? 'none' : 'block' }} alt="" src={UserImg} ref={cameraRef} /> */}
          {!profile && <RxAvatar className="profile_photo" />}
          <img className="edit-svg" src={EditUserSvg} alt="EditUserSvg" />
          <div
            style={{
              position: 'absolute',
              marginTop: '-30px',
              marginLeft: '25px',
              opacity: '0',
            }}
          >
            <input
              type="file"
              onChange={uploadProfile}
              onClick={() => setProfileToggler(false)}
            />
          </div>
        </UserImgContainer>
        <div className="flex apart mt-3">
          {userDetails?.roles?.includes('pos') ? (
            <InputComponentDash
              title={'Registration No.'}
              disable={userDetails?.roles?.includes('pos') ? true : false}
              onEdit={() => setEditTrigger(true)}
              value={posDetails?.posIncId || 'Not Assigned ⚠️'}
              maxLength={10}
            />
          ) : null}

          <InputComponentDash
            disable={userDetails?.roles?.includes('pos') ? true : false}
            error={error.name ? <ErrorPopup error={error.name} /> : null}
            title={'Full name'}
            type="text"
            value={fullName}
            onChange={(e) => {
              setFullName(e.target.value);
            }}
          />
          {/* {error.hasOwnProperty('firstName') ? (
                    <ErrorPopup errors={error.firstName}/>
                ) : null} */}
          {/* <InputComponentDash title={'Your id'} type='text' value={userId} disable={true} /> */}
          <InputComponentDash
            title={'Mobile number'}
            disable={userDetails?.roles?.includes('pos') ? true : false}
            // type='number'
            // editable={true}
            onEdit={() => setEditTrigger(true)}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            maxLength={10}
          />
        </div>

        <div className="flex apart mt-1">
          {/* <InputComponentDash
                    title={'Mobile number'}
                    // type='number' 
                    // editable={true} 
                    disable={true}
                    onEdit={() => setEditTrigger(true)}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    maxLength={10}
                /> */}
          <InputComponentDash
            title={'Email'}
            type="email"
            disable={userDetails?.roles?.includes('pos') ? true : false}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // editable={true}
            onEdit={() => setEditTrigger(true)}
          />
          {userDetails?.roles?.includes('pos') ? (
            <InputComponentDash
              title={'Pan Card'}
              disable={userDetails?.roles?.includes('pos') ? true : false}
              type="text"
              maxLength={12}
              value={posDetails?.panNo || ''}
            />
          ) : (
            <InputComponentDash
              title={'Aadhar card'}
              disable={userDetails?.roles?.includes('pos') ? true : false}
              type="text"
              onEdit={() => setEditTrigger(true)}
              maxLength={12}
              value={aadhar}
              onClick={() => removeError('aadhar')}
              onChange={(e) => setAadhar(e.target.value)}
              error={error.aadhar ? <ErrorPopup error={error.aadhar} /> : null}
            />
          )}

          {/* </div> */}
          {/* <div className="flex apart mt-1"> */}
          {/* <InputComponentDash
                    title={'New Password'}
                    onEdit={() => setEditTrigger(true)}
                    value={psswd.password}
                    onChange={(e) => setPsswd(prev => ({ ...prev, password: e.target.value }))}
                    error={error.password ? (
                        <ErrorPopup error={error.password} />
                    ) : null}
                /> */}
          {/* {psswd.password ?
                    <>
                        <InputComponentDash onClick={() => removeError('cPassword')}
                            style={{ border: psswd.cPassword === "" ? '1px solid #B7B7B7' : psswd.password === psswd.cPassword ? '1px solid green' : '1px solid red' }}
                            title={'Confirm Password'}
                            onEdit={() => setEditTrigger(true)}
                            value={psswd.cPassword}
                            onChange={(e) => setPsswd(prev => ({ ...prev, cPassword: e.target.value }))}
                            error={error.cPassword ? (
                                <ErrorPopup error={error.cPassword} />
                            ) : null}
                        />
                    </> :
                    null

                } */}
        </div>
        <div className="flex apart mt-1">
          {userDetails?.roles?.includes('pos') && (
            <>
              <InputComponentDash
                title={'RM name'}
                type="text"
                value={rmDetails?.rmDetails?.fullName}
                disable={true}
              />
              <InputComponentDash
                title={'RM email'}
                type="text"
                value={rmDetails?.rmDetails?.email}
                disable={true}
              />
              <InputComponentDash
                title={'RM phone'}
                type="text"
                value={rmDetails?.rmDetails?.phone}
                disable={true}
              />
            </>
          )}
        </div>
        <div className="flex apart mt-1">
          <InputComponentDash
            title="Current Password"
            type="password"
            name="currentPassword"
            value={psswd.currentPassword}
            onChange={handleChange}
            error={
              error.currentPassword ? (
                <ErrorPopup error={error.currentPassword} />
              ) : null
            }
          />
          <InputComponentDash
            title="New Password"
            type="password"
            name="password"
            value={psswd.password}
            onChange={handleChange}
            error={
              error.password ? <ErrorPopup error={error.password} /> : null
            }
          />
          <InputComponentDash
            title="Confirm New Password"
            type="password"
            name="cPassword"
            value={psswd.cPassword}
            onChange={handleChange}
            error={
              error.cPassword ? <ErrorPopup error={error.cPassword} /> : null
            }
          />
        </div>
        <SaveBtn onClick={updateProfileFun}>Save</SaveBtn>
        {
          userDetails?.roles?.includes('pos') && (
          <SaveBtn onClick={handleBulkDownload}>
          <FaFileDownload size="20px" /> all documents
        </SaveBtn>
        )
        }
      </ProfileMainContainer>
    );
}