import React, {useEffect, useState} from 'react';
import './main-brand.css';
import BrandPage from '../brandPage/brand';
import healthImage from '../../assets/brand-banner.png';
import Quick from '../../components/new_quick_links/quick';
import Hero from '../../components/Hero_Banner/hero';
import BlogCard from '../../components/blog-card/blog';
import FooterNew from '../../components/Footer/FooterNew';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router';
import ContactForm from './../new-contact-us/new-contact';
import { Helmet } from 'react-helmet';


const companies = [
  { id: 1, name: 'National Insurance', path: "national-insurance" },
  { id: 2, name: 'HDFC Life Insurance', path: "hdfc-life" },
  { id: 3, name: 'Bajaj Allianz General Insurance', path: "bajaj-allianz-general-insurance" }, 
  // { id: 4, name: 'Bharti AXA', path: "bhartiAXA" },
  // { id: 5, name: 'Canara HSBC', path: "canaraHSBC" },
  { id: 6, name: 'Care Health', path: "care-health-insurance" },
  { id: 7, name: 'Chola MS', path: "cholamandalam-ms-general-insurance" },
  { id: 8, name: 'Digit', path: "go-digit" },
  { id: 9, name: 'Edelweiss', path: "edelweiss-insurance" },
  { id: 10, name: 'Edelweiss Life Tokio', path: "edelweiss-life-tokio" },
  { id: 11, name: 'Exide Life Insurance', path: "exide-life-insurance" },
  { id: 12, name: 'Future Generali', path: "future-generali-india-insurance-company" },
  { id: 13, name: 'HDFC ERGO', path: "hdfc-ergo-general-insurance" },
  // { id: 14, name: 'Bajaj Allianz', path: "bajajAllianz" },
  { id: 15, name: 'ICICI Lombard', path: "icici-lombard" },
  { id: 16, name: 'ICICI Prudential', path: "icici-prudential" },
  { id: 17, name: 'IFFCO TOKIO', path: "iffco-tokio-general-insurance" },
  { id: 18, name: 'Kotak General Insurance', path: "kotak-mahindra-general-insurance" },
  { id: 19, name: 'Kotak Life Insurance', path: "kotak-life-insurance" },
  { id: 20, name: 'Liberty', path: "liberty-general-insurance" },
  { id: 21, name: 'LIC', path: "lic" },
  { id: 22, name: 'Magma HDI', path: "magma" },
  { id: 23, name: 'Manipal Cigna', path: "cigna-health-insurance" },
  { id: 24, name: 'Max Life', path: "max-life" },
  { id: 25, name: 'Aditya Birla', path: "aditya-birla-health-insurance-co-limited-abhicl" },
  { id: 26, name: 'Niva', path: "niva" },
  { id: 27, name: 'Oriental Insurance', path: "oriental-insurance" },
  { id: 28, name: 'PNB MetLife', path: "pnb-metlife" },
  { id: 29, name: 'Raheja QBE', path: "raheja-qbe" },
  { id: 30, name: 'Reliance General Insurance', path: "reliance-insurance" },
  { id: 31, name: 'Reliance Nippon Life Insurance', path: "reliance-nippon-life-insurance" },
  { id: 32, name: 'Royal Sundaram', path: "royal-sundaram" },
  { id: 33, name: 'SBI General', path: "sbi-general-insurance" },
  { id: 34, name: 'SBI Life', path: "sbi-life" },
  { id: 35, name: 'Shriram General Insurance', path: "shriram-general-insurance" },
  { id: 36, name: 'Shriram Life Insurance', path: "shriram-life-insurance" },
  { id: 37, name: 'Star Health Insurance', path: "star-health-insurance" },
  { id: 38, name: 'Tata AIG', path: "tata-aig-general-insurance" },
  { id: 39, name: 'The New India Assurance', path: "the-new-india-assurance" },
  { id: 40, name: 'United India', path: "united-india-insurance" },
  { id: 41, name: 'Universal Sompo General Insurance', path: "universal-sompo-insurance" },
  { id: 42, name: 'Tata Life Insurance', path: "tata-life" },
  { id: 43, name: 'Bajaj Life', path: "bajaj-life" }
];

const collectionMapping = {
  "national-insurance":  "nationalInsurance",
  "hdfc-life":  "hdfcLife",
  "bajaj-allianz-general-insurance":  "BajajAllianzGeneralInsurance",
  "bharti-axa":  "bhartiAXA",
  "canara-hsbc":  "canaraHSBC",
  "care-health-insurance":  "careHealth",
  "cholamandalam-ms-general-insurance":  "cholaMS",
  "go-digit":  "digit",
  "edelweiss-insurance":  "edelweiss",
  "edelweiss-life-tokio":  "edelweissLifeTokio",
  "exide-life-insurance":  "exideLifeInsurance",
  "future-generali-india-insurance-company":  "futureGenerali",
  "hdfc-ergo-general-insurance":  "hdfcERGO",
  "icici-lombard":  "iciciLombard",
  "icici-prudential":  "iciciPrudential",
  "iffco-tokio-general-insurance":  "iffcoTOKIO",
  "kotak-mahindra-general-insurance":  "kotakGeneralInsuance",
  "kotak-life-insurance":  "kotakLifeInsurance",
  "liberty-general-insurance":  "liberty",
  "lic":  "LIC",
  "magma":  "magmaHDI",
  "cigna-health-insurance":  "manipalCigna",
  "max-life":  "maxLife",
  "aditya-birla-health-insurance-co-limited-abhicl":  "adityaBirla",
  "niva":  "niva",
  "oriental-insurance":  "orientalInsurance",
  "pnb-metlife":  "pnbMetLife",
  "raheja-qbe":  "rahejaQBE",
  "reliance-insurance":  "relianceGeneralInsurance",
  "reliance-nippon-life-insurance":  "relianceNipponLifeInsurance",
  "royal-sundaram":  "royalSundaram",
  "sbi-general-insurance":  "sbiGeneral",
  "sbi-life":  "sbiLife",
  "shriram-general-insurance":  "shriramGeneralInsurance",
  "shriram-life-insurance":  "shriramLifeInsurance",
  "star-health-insurance":  "starHealthInsurance",
  "tata-aig-general-insurance":  "tataAIG",
  "the-new-india-assurance":  "theNewIndiaAssurance",
  "united-india-insurance":  "unitedIndia",
  "universal-sompo-insurance":  "universalSompoGeneralInsurance",
  "tata-life":  "tataLife",
  "bajaj-life":  "bajajLife"
}

const MainBrandPage = () => {
  const [brandData, setBrandData] = useState([]);
  const { name } = useParams();
  
  useEffect(() => {
    axios.get(`https://uat-backend.simpliinsure.in/cms/api/insurances/?filters[name][$eq]=${collectionMapping[name]}&populate=life,life.benefitsData,life.faqData,motor,motor.benefitsData,motor.faqData,health,health.benefitsData,health.faqData,bike,bike.benefitsData,bike.faqData,lifeInsuranceTableCategories,lifeInsuranceTableCategories.policies,businessInsuranceTableCategories,businessInsuranceTableCategories.policies,motorInsuranceTableCategories,motorInsuranceTableCategories.policies,healthInsuranceTableCategories,healthInsuranceTableCategories.policies,SEO,SEO.sharedImage,SEO.sharedImage.media`)
      .then(response => {
        setBrandData(response?.data?.data[0]);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [name]);

  
      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  return (
    <>
    {
      brandData?.attributes?.SEO && (
      <Helmet>
        <title> Brand | {brandData?.attributes?.SEO.metaTitle}</title>
        <meta name="title" content={brandData?.attributes?.SEO.metaTitle} />
        <meta name="description" content={brandData?.attributes?.SEO?.metaDescription} />
        <meta name="keywords" content={brandData?.attributes?.SEO?.keywords} />
        <meta property="og:title" content={brandData?.attributes?.SEO.metaTitle} />
        <meta property="og:description" content={brandData?.attributes?.SEO?.metaDescription} />
        <meta property="og:image" content={brandData?.attributes?.SEO?.sharedImage?.media?.data?.attributes?.url} />
      </Helmet>
      )
    }

    <Hero heading={brandData?.attributes?.title} image={healthImage} subHeading={ brandData?.attributes?.subTitle } btn={true}/>
    <Quick heading = {brandData?.attributes?.title} />
    <div className="page-container">
      <div className="left-section"> 
      <h2>Companies</h2>
      <ul>
        {companies.map((company) => (
          <li key={company.id} className="company-item">
            <Link to={`/brand/${company.path}`}>{company.name}</Link>
          </li>
        ))}
      </ul>
      </div>

      <div className="center-section">
      <BrandPage brandPageData = { brandData } />
      </div>

      <div className="right-section"> 
        <ContactForm title = {'APPLY NOW TO GET YOUR POLICY'} titleSize={'15px'}/>
      </div>
    </div>

        <div className='blog_section'>
    <h2 className='learnMore'>LEARN MORE</h2>
    <div className="blog_container">
    <BlogCard />
    </div>
    </div>
    <div style={{padding:"40px"}}>

    </div>
    <FooterNew />
    </>
  );
}

export default MainBrandPage;
