import React, { useRef } from 'react';
import { MainContainer } from "../../../../assets/common-styles/common-styles"
import Footer from "../../../../components/Footer/FooterNew"
import { CardContainer, Separator, ErrorContainer, ErrorMsgContainer, HDiv, ErrArrow, ContinueBtn, ErrorIcon, InputContainer, FloatingTitle, SelectDropdown } from "./styles"
import PrevArrowImg from '../../../../assets/commons/prev-arrow.svg'
import BrandImg from '../../../../assets/car-insurance/form-5/national.png'
import VideoImg from '../../../../assets/car-insurance/payments/video.svg'
import { CarDetailsComponent } from "../../../../components/car-details-component/CarDetailsComponent"
import { InputComponent } from "../../../../components/input-component/InputComponent"
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motorEnquiryPayment, motorEnquiryPaymentVerification, updateCombinedModel, calculateCheckSum, motorEnquiryAPI, updateSaveProposalKotak, updateSaveProposalIcici, updateSaveProposalHdfc, updateSaveProposalReliance, updateSaveProposalDigit, updateSaveProposal } from '../../../../Redux/CarsRedux';
import { useSelector, useDispatch } from "react-redux";
import ErrorIconImg from '../../../../../src/assets/brand-new-car/exclamation.svg';
import { Oval } from 'react-loader-spinner';
import { axiosPOSTCall, axiosGETCall, axiosPOSTCall1, checkSum } from "../../../../utilities/utils/apiPost"
import { calculateAge } from '../../../../utilities/calculateAge';
import { WarningPopup } from "./WarningPopup";
const moment = require('moment');

export const CarPayment4 = () => {
  const formRef = useRef(null);

  const combinedReduxInsure = useSelector((state) => state.carsRedux.combinedRedux);
  console.log("combinedReduxInsure is ", combinedReduxInsure);
  const carsRedux = useSelector((state) => state.carsRedux);
  const { razorPay } = useSelector((state) => state.carsRedux.paymentDetails);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [GST, setGST] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [nomineeName, setNomineeName] = useState('');
  const [relation, setRelation] = useState(-1);
  const [DOB, setDOB] = useState();
  const [userDetails, setUserDetails] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiManage, setApiManage] = useState(false);
  const [showAddOnsPopup, setShowAddOnsPopup] = useState(false);

  useEffect(() => {
    var cal = ((18 / 100) * state?.price)
    cal = Math.round(cal)
    setGST(cal)
    setTotalPrice(parseInt(state?.price) - parseInt(GST))
  })

  useEffect(() => {
    const queryParameter = new URLSearchParams(window.location.search)
    var enquiryIds = queryParameter.get("enquiryId");
    console.log("enquiryIds  is  ", enquiryIds);
    // if(combinedReduxInsure == null) {
    var obj = { type: "get", enquiryId: enquiryIds };
    dispatch(motorEnquiryAPI(obj))
    var userDetails = (localStorage.getItem('canInsuranceDetails'));
    console.log("userDetails are ", userDetails);
    if (userDetails) {
      userDetails = JSON.parse(userDetails);
      if (userDetails?.hasOwnProperty('nomineeDetails')) {
        setNomineeName(userDetails?.nomineeDetails?.fullName)
        setRelation(userDetails?.nomineeDetails?.relation)
        setDOB(moment(userDetails?.nomineeDetails?.dob).format('YYYY-MM-DD'))
        dispatch(updateCombinedModel(userDetails));
      }
    }
  }, [])


  const dispatch = useDispatch();


  const successFun = (response) => {

    var obj = {
      "paymentOrderId": response.razorpay_order_id,
      "paymentId": response.razorpay_payment_id,
      "razorpaySignature": response.razorpay_signature,
      "enquiryId": combinedReduxInsure.enquiryId,
      "slug": combinedReduxInsure.company
    }
    dispatch(motorEnquiryPaymentVerification(obj)).then(() => {
      navigate('/car-insurance/car-payment-success')
    })
  }

  const handlePaymentResponse = (response) => {
    var obj = {
      "paymentOrderId": response.txnid,
      "paymentId": response.mihpayid,
      "enquiryId": combinedReduxInsure.enquiryId,
      "slug": combinedReduxInsure.company
    };

    dispatch(motorEnquiryPaymentVerification({ ...obj, ...response })).then(() => {
      if (response?.status != "failure")
        navigate('/car-insurance/car-payment-success')
    })
  }

  useEffect(() => {
    var userDetails = JSON.parse(localStorage.getItem('userDetails'));
    setUserDetails(userDetails)
    window.scrollTo(0, 0)


  }, [])

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const submitFunction = async () => {
    const isCPA = combinedReduxInsure?.addOns.some(item => item.slug == "personalAccident")
    const isTP = combinedReduxInsure?.newPolicyData?.policyType == "privateCarTP" && !isCPA;
    if (!isTP && !nomineeName) {
      // alert('Please enter nominee name.');
      setError({ nomineeName: 'Please enter nominee name.' });
    }
    else if (!isTP && relation == -1) {
      setError({ relation: 'Please select a relation.' });
    }
    else if (!isTP && !DOB) {
      setError({ dob: 'Please enter date of birth.' });
    }

    else {
      var obj = {
        fullName: nomineeName,
        relation: relation == "-1" ? "" : relation,
        dob: DOB
      }
      let slug = ["motor-godigit", "motor-kotak", "motor-icici", "motor-hdfc", "motor-reliance", "motor-future", "motor-royalsundaram"];

      dispatch(motorEnquiryAPI({ 'nomineeDetails': obj, enquiryId: combinedReduxInsure?.enquiryId })).then(() => {
        setApiManage(true)
        setLoading(true);
        switch (combinedReduxInsure.company) {
          case slug[0]:
            const queryParameters = new URLSearchParams(window.location.search)
            const isSuccess = queryParameters.get("kycVerificationSuccess");
            console.log("queryParameters is ", queryParameters, isSuccess);
            if (isSuccess) {
              navigate(`/car-insurance/preview-after-payment?enquiryId=${combinedReduxInsure.enquiryId}`)
              break;
            }
            else {
              axiosPOSTCall('/vehicleInsurance/motor/saveProposal/motor-godigit', { enquiryId: combinedReduxInsure.enquiryId }, (callBack => {
                if (callBack.status == "Success") {
                  if (callBack.data?.policyStatus == "PENDING_DOCUMENTS") {
                    dispatch(motorEnquiryAPI({ 'inspection': "required", enquiryId: combinedReduxInsure?.enquiryId }));
                  }
                  setLoading(false);
                  setTimeout(() => {
                    axiosPOSTCall1('/vehicleInsurance/motor/verifyKyc/motor-godigit', { policyNumber: callBack.data.policyNumber, enquiryId: combinedReduxInsure.enquiryId }, (callBack => {
                      if (callBack.status == "Success") {
                        console.log("queryParameters are ", queryParameters);
                        if (callBack.data.isRedirect || callBack.data.link) {
                          console.log("data", callBack)
                          window.location.href = callBack?.data?.link;
                        }
                        else {
                          dispatch(updateSaveProposalDigit(callBack.data));
                          navigate(`/car-insurance/preview-after-payment?enquiryId=${combinedReduxInsure.enquiryId}`)
                        }
                      }
                    }

                    ))
                  }, 5000)
                  // console.log(callBack,'awerty');

                }

                else {
                  setLoading(false);
                  if (callBack.message) {
                    alert(callBack.message)
                  }
                  alert("Internal Server error, Please try to continue again!!")
                }
              }));
              break;
            }
            break;
          case slug[1]:
            axiosPOSTCall('/vehicleInsurance/motor/saveProposal/motor-kotak', { enquiryId: combinedReduxInsure.enquiryId }, (callBack => {
              if (callBack && callBack.status == "Success") {
                setLoading(false);
                console.log(callBack, 'awerty');
                // setLoading(true);
                dispatch(updateSaveProposalKotak(callBack.data))
                navigate(`/car-insurance/preview-after-payment?enquiryId=${combinedReduxInsure.enquiryId}`)
              }
              else {
                setLoading(false);
                alert(callBack ? callBack.message : `External Server Request Timeout!! Please Retry`)
              }
            }));
            break;
          case slug[2]:
            setTimeout(() => {
              axiosPOSTCall('/vehicleInsurance/motor/saveProposal/motor-icici', { enquiryId: combinedReduxInsure.enquiryId }, (callBack => {
                if (callBack.status == "Success") {
                  console.log(callBack, 'awerty');
                  setLoading(false);
                  dispatch(updateSaveProposalIcici(callBack));
                  navigate(`/car-insurance/preview-after-payment?enquiryId=${combinedReduxInsure.enquiryId}`)
                }
                else {
                  setLoading(false);
                  alert(callBack.message)
                }
              }));
            }, 1000)
            break;
          case slug[3]:
            axiosPOSTCall('/vehicleInsurance/motor/saveProposal/motor-hdfc', { enquiryId: combinedReduxInsure.enquiryId }, (callBack => {
              if (callBack && callBack.status == "Success") {
                setLoading(false);
                console.log(callBack, 'awerty');
                // setLoading(true);
                dispatch(updateSaveProposalHdfc(callBack))

                navigate(`/car-insurance/preview-after-payment?enquiryId=${combinedReduxInsure.enquiryId}`)

              }
              else {
                setLoading(false);

                alert(callBack ? callBack.message : `External Server Request Timeout!! Please Retry`)
              }
            }));
            break;
          case slug[4]:
            if (combinedReduxInsure?.inspection == "required") {
              navigate(`/car-insurance/preview-after-payment?enquiryId=${combinedReduxInsure.enquiryId}`);
              return;
            }
            axiosPOSTCall('/vehicleInsurance/motor/saveProposal/motor-reliance', { enquiryId: combinedReduxInsure.enquiryId }, (callBack => {
              // console.log("callback in proposalReliance",callBack,callBack.status)
              if (callBack.status == "Success") {
                console.log("hle===");
                console.log('awerty', callBack.data.MotorPolicy.ProposalNo, callBack.data.MotorPolicy.FinalPremium, callBack.data.ckycNo, callBack.data.pan);
                setLoading(false);
                dispatch(updateSaveProposalReliance(callBack))
                navigate(`/car-insurance/preview-after-payment?enquiryId=${combinedReduxInsure.enquiryId}`)
                // displayReliancePayment(callBack.data.MotorPolicy.ProposalNo, callBack.data.MotorPolicy.FinalPremium, callBack.data.ckycNo, callBack.data.pan)
              }
              else {
                setLoading(false);
                alert(callBack.message)
              }
            }))

            break;
          case slug[5]:
            if (combinedReduxInsure?.quickQuote.addons?.length > 0 && ["privateCarComprehensive", "privateCarOD"].includes(combinedReduxInsure?.newPolicyData?.policyType)) {
              setShowAddOnsPopup(true);
              break;
            }
            axiosPOSTCall('/vehicleInsurance/motor/saveProposal/motor-future', { enquiryId: combinedReduxInsure.enquiryId }, (callBack => {
              if (callBack && callBack.status == "Success") {
                setLoading(false);
                dispatch(updateSaveProposal(callBack))
                navigate(`/car-insurance/preview-after-payment?enquiryId=${combinedReduxInsure.enquiryId}`)
              }
              else {
                setLoading(false);
                alert(callBack ? callBack.message : `External Server Request Timeout!! Please Retry`)
              }
            }));
            break;
          case slug[6]:
            if (combinedReduxInsure?.inspection == "required") {
              navigate(`/car-insurance/preview-after-payment?enquiryId=${combinedReduxInsure.enquiryId}`);
              return;
            }
            axiosPOSTCall('/vehicleInsurance/motor/saveProposal/motor-royalsundaram', { enquiryId: combinedReduxInsure.enquiryId }, (callBack => {
              if (callBack.status == "Success") {
                setLoading(false);
                dispatch(updateSaveProposal(callBack))
                navigate(`/car-insurance/preview-after-payment?enquiryId=${combinedReduxInsure.enquiryId}`)
              }
              else {
                setLoading(false);
                alert(callBack.message)
              }
            }))
        }
      })

    }
  }

  const proposalwithAddOnsUpdate = addOns => {
    setShowAddOnsPopup(false);
    axiosPOSTCall('/vehicleInsurance/motor/saveProposal/motor-future', { enquiryId: combinedReduxInsure.enquiryId, prevAddOns: addOns }, (callBack => {
      if (callBack && callBack.status == "Success") {
        setLoading(false);
        dispatch(updateSaveProposal(callBack))
        navigate(`/car-insurance/preview-after-payment?enquiryId=${combinedReduxInsure.enquiryId}`)
      }
      else {
        setLoading(false);
        alert(callBack ? callBack.message : `External Server Request Timeout!! Please Retry`)
      }
    }));
  }

  // handler for age must be 18 years
  const handleDateChange = (selectedDate) => {
    setDOB(selectedDate);
    setError({});

    const age = calculateAge(selectedDate);

    if (age < 18) {
      setError({ dob: 'Age must be 18 or older.' });
      alert("Age must be 18 or older.")
      setDOB('')
    }
  };


  useEffect(() => {
    if (combinedReduxInsure?.nomineeDetails && apiManage) {
      paymentVerification();
      setApiManage(false)
    }
  }, [combinedReduxInsure])
  useEffect(() => {
    if (carsRedux.error == true) {
      setLoading(false);
    }
  }, [carsRedux])




  const displayPayu = (pd) => {
    console.log("pd.......", pd);
    let obj = { ...pd }
    window.bolt.launch(obj, {
      responseHandler: (response) => {
        console.log("initied", response)
        // Handle payment response
        handlePaymentResponse(response?.response);
      },
      catchException: (response) => {
        // Handle exception
        console.error('Exception:', response);
      },
    });
  };
  const paymentVerification = () => {
    // dispatch(motorEnquiryPayment({enquiryId:combinedReduxInsure?.enquiryId,company:state.slug}));
  };

  async function displayRazorpay(price) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    var options = {
      key: "rzp_live_mGHNLhyKkEBeRS",
      amount: razorPay.amount,
      currency: "INR",
      name: "Simpli Insure",
      //   description: "Pay & Checkout this Course, Upgrade your DSA Skill",
      image:
        "https://simpliinsure.netlify.app/static/media/logo.4e8b3073d03ce8c93c73.png",

      "order_id": razorPay.id,




      "handler": function (response) {
        successFun(response)
      },


      prefill: {
        //Here we are prefilling random contact
        contact: userDetails.phone,
        //name and email id, so while checkout
        name: userDetails.fullName,
        email: userDetails.email,
      },
      notes: {
        description: "Best Course for SDE placements",
        language:
          "Available in 4 major Languages JAVA,C/C++, Python, Javascript",
        access: "This course have Lifetime Access",
      },
      theme: {
        color: "#F5931D",
      },
      "modal": {
        "ondismiss": function () {
          //   setLoader(false)
        }
      }
    }
    // };
    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function (response) {
      //   setLoader(false)
    });
    paymentObject.open();
  }


  const ErrorPopup = ({ errors }) => {
    return (
      <>
        <ErrorContainer>
          <ErrorMsgContainer>{errors}</ErrorMsgContainer>
          <ErrArrow />
          <ErrorIcon src={ErrorIconImg} />
        </ErrorContainer>
      </>
    )
  }

  // const hdfcPayment = (checkSum, surl, furl, trnsno, appid, amount, subid) => {
  //     // Create a new FormData object
  //     var formData = new FormData();

  //     // Append values to the FormData object
  //     formData.append('Appid', appid);
  //     formData.append('Trnsno', trnsno);
  //     formData.append('Amt', amount);
  //     formData.append('Subid', subid);
  //     formData.append('surl', surl);
  //     formData.append('furl', furl);
  //     formData.append('src', 'POST');
  //     formData.append('Chksum', checkSum);
  //     formData.append('Additionalinfo1', '');

  //     // Create a new form element
  //     var form = document.createElement('form');
  //     form.action = 'https://heapp21.hdfcergo.com/UAT/OnlineProducts/CCPGISUBSCRIBER/MakePayment.aspx';
  //     form.name = 'PAYMENTFORM';
  //     form.method = 'post';
  //     form._lpchecked = '1';

  //     // Iterate through FormData entries and create hidden input elements
  //     formData.forEach(function (value, key) {
  //         var input = document.createElement('input');
  //         input.type = 'hidden';
  //         input.name = key;
  //         input.value = value;
  //         form.appendChild(input);
  //     });

  //     // Append the form to the document body
  //     document.body.appendChild(form);

  //     // Submit the form
  //     form.submit();

  // }

  // useEffect(()=>{
  //   if(sum)
  //   handleSubmit()
  // },[checkSumA])

  return (
    <>
      {combinedReduxInsure?.quickQuote?.addons?.length > 0 && showAddOnsPopup && <WarningPopup addOnsList={combinedReduxInsure?.quickQuote?.addons} closeAddOnsPopup={(option) => setShowAddOnsPopup(option)} updateaAddons={proposalwithAddOnsUpdate} handleLoading={(val) => setLoading(val)} />}
      <MainContainer>
        <CardContainer>
          <div className="flex">
            <img
              className="arrow"
              src={PrevArrowImg}
              onClick={() =>
                navigate(
                  `/car-insurance/main?enquiryId=${combinedReduxInsure.enquiryId}`
                )
              }
            />
            <p className="back-to-quotes">Back to Quotes</p>
          </div>

          <div className="flex apart cards-container">
            <div className="flex-1 mr-half">
              <CarDetailsComponent
                totalSteps={3}
                step={'three'}
                title="Nominee Details"
                yellowBorder={true}
                noSearch={true}
                widthAuto={true}
              />
              <br />

              <InputContainer>
                <FloatingTitle>{`Nominee's Full Name.`}</FloatingTitle>
                <HDiv className="m-0">
                  <input
                    placeholder="Please enter nominee's full name."
                    onChange={(e) => {
                      setNomineeName(e.target.value);
                      setError({});
                    }}
                    maxLength={30}
                    value={nomineeName}
                  />
                  {error.hasOwnProperty('nomineeName') ? (
                    <ErrorPopup errors={error.nomineeName} />
                  ) : null}
                </HDiv>
              </InputContainer>
              {/* <InputComponent
                                title='Relation'
                                onChange={(e)=>setRelation(e)}
                                dropdown={["DAUGHTER", "DAUGHTER_IN_LAW", "GRAND_CHILD", "MOTHER", "SON", "SPOUSE", "HUSBAND", "CHILD", "SISTER", "BROTHER", "FATHER"]}
                            /> */}
              <InputContainer>
                <FloatingTitle>{'Relation.'}</FloatingTitle>
                <SelectDropdown
                  onChange={(e) => {
                    setRelation(e.target.value);
                    setError({});
                  }}
                >
                  <option value={-1} disabled selected>
                    {'Please select a value'}
                  </option>
                  {[
                    'Aunt',
                    'Brother',
                    'Brother-In-law',

                    'Daughter',
                    'Daughter-In-law',

                    'Father',
                    'Father-In-law',

                    'Granddaughter',
                    'Grandfather',
                    'Grandmother',
                    'Grandson',
                    'Husband',

                    'Mother',
                    'Mother-In-law',
                    'Nephew',
                    'Niece',
                    'Sister',
                    'Sister-In-law',
                    'Son',
                    'Son-In-law',
                    'Spouse',
                    'Uncle',
                    'Wife',
                  ].map((item) => (
                    <>
                      {relation == item ? (
                        <option selected value={item}>
                          {item}
                        </option>
                      ) : (
                        <option value={item}>{item}</option>
                      )}
                    </>
                  ))}
                </SelectDropdown>
                {error.hasOwnProperty('relation') ? (
                  <ErrorPopup errors={error.relation} />
                ) : null}
              </InputContainer>

              {/* <InputComponent
                                title={'Date of Birth'}
                                type='date'
                                onChange={(e)=>setDOB(e)}
                                // value={'02/01/1981'}
                            /> */}

              <InputContainer>
                <FloatingTitle>{'Date of Birth.'}</FloatingTitle>
                <input
                  max={moment().subtract(18, 'years').format('YYYY-MM-DD')}
                  onChange={(e) => handleDateChange(e.target.value)}
                  value={DOB}
                  type={'date'}
                />
                {error.hasOwnProperty('dob') ? (
                  <ErrorPopup errors={error.dob} />
                ) : null}
              </InputContainer>

              <ContinueBtn
                onClick={() => {
                  submitFunction();
                }}
              >
                {loading ? (
                  <Oval
                    height={25}
                    width={70}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#fff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  'Continue'
                )}
              </ContinueBtn>
              {/* <button id='reg' onClick={() => 
                                // navigate('/car-insurance/car-payment-success')
                                // displayRazorpay(state?.price)
                                submitFunction()
                                } className="continue">
                                    <Oval
                                                height={25}
                                                width={70}
                                                color="#fff"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                                ariaLabel='oval-loading'
                                                secondaryColor="#fff"
                                                strokeWidth={2}
                                                strokeWidthSecondary={2}
                                            />
                                </button> */}
            </div>

            <div className="flex-1 ml-half mt-1">
              <div className="cards mb-1">
                <div className="flex column align-center">
                  <img
                    src={combinedReduxInsure?.quickQuote?.sluginfo?.logo}
                    alt="brand-img"
                    className="brand-img"
                  />
                  <p className="brand-name">
                    {combinedReduxInsure?.quickQuote?.sluginfo?.name}
                  </p>
                  <p className="basics w-90">
                    {combinedReduxInsure?.quickQuote?.sluginfo?.description}
                  </p>
                  <Separator />

                  <div className="flex apart w-100">
                    <p className="total">Total Payable Amount :</p>
                    <p className="amount">
                      + ₹ {combinedReduxInsure?.quickQuote?.price}
                    </p>
                  </div>
                </div>
              </div>

              <div className="cards mb-1">
                <div className="flex apart res-apart res-center">
                  <div className="res-margin">
                    <p className="head">Vehicle Number</p>
                    <p className="data" style={{ width: 250 }}>
                      {combinedReduxInsure?.number
                        ? combinedReduxInsure?.number
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="res-margin">
                    <p className="head">Location</p>
                    <p className="data">
                      {combinedReduxInsure?.city
                        ? combinedReduxInsure?.city
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="res-margin">
                    <p className="head">Policy Expiry</p>
                    <p className="data">
                      {combinedReduxInsure?.insurance
                        ?.insurancePolicyExpiryDate
                        ? moment(
                          combinedReduxInsure?.insurance
                            ?.insurancePolicyExpiryDate
                        ).format('YYYY-MM-DD')
                        : null}
                    </p>
                  </div>
                  <div className="res-margin">
                    <p className="head">Year</p>
                    <p className="data">
                      {combinedReduxInsure?.insurance
                        ?.insurancePolicyExpiryDate
                        ? moment(
                          combinedReduxInsure?.insurance
                            ?.insurancePolicyExpiryDate
                        ).format('YYYY')
                        : null}
                    </p>
                  </div>
                </div>
              </div>

              <div className="cards mb-1">
                <div className="flex apart">
                  <div className="video-icon-container">
                    <img src={VideoImg} className="video-img" />
                  </div>

                  <div>
                    <p className="vid-inspection">
                      For the OD and Comprehensive Policy Self / Video
                      Inspection May Be Required, which can be followed after
                      payment
                    </p>
                    <div className="flex apart mt-half res-apart">
                      {/* <div className="flex align-center">
                                                <div className="orange-dot" />
                                                <p className="vid-tips">It is a long established fact</p>
                                            </div>
                                            <div className="flex align-center">
                                                <div className="orange-dot" />
                                                <p className="vid-tips">It is a long establ</p>
                                            </div>
                                            <div className="flex align-center">
                                                <div className="orange-dot" />
                                                <p className="vid-tips">It is a long established fact thi</p>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContainer>
      </MainContainer>
      <Footer />
    </>
  );
}