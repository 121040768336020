import { MainContainer } from "../../../../assets/common-styles/common-styles"
import fieldArr from "../../../../utilities/helper"
import Footer from "../../../../components/Footer/FooterNew"
import { CardContainer, Separator, ErrorContainer, ErrorMsgContainer, HDiv, ErrArrow, ErrorIcon, InputContainer, FloatingTitle, SelectDropdown } from "./styles"
import PrevArrowImg from '../../../../assets/commons/prev-arrow.svg'
import BrandImg from '../../../../assets/car-insurance/form-5/national.png'
import VideoImg from '../../../../assets/car-insurance/payments/video.svg'
import { CarDetailsComponent } from "../../../../components/car-details-component/CarDetailsComponent"
import { InputComponent } from "../../../../components/input-component/InputComponent"
import { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateCombinedModel, motorEnquiryAPI, FilterVaraintName, RELIANCEMotorUpdateQuote, HDFCMotorUpdateQuote, ICICIMotorUpdateQuote, GODIGITMotorUpdateQuote, KOTAKMotorUpdateQuote, FutureGeneralMotorUpdateQuote } from "../../../../Redux/CarsRedux";
import { findUser, updateProfile } from "../../../../Redux/AuthRedux"
import { axiosPOSTCall, axiosPOSTCall1, axiosPUTCall, axiosGETCall2, pincodeDataHandler } from "../../../../utilities/utils/apiPost"
import ErrorIconImg from '../../../../../src/assets/brand-new-car/exclamation.svg';
import axios from "axios"
import Select from 'react-select';
import { calculateAge } from "../../../../utilities/calculateAge";
const moment = require('moment');

export const CarPayment3 = () => {

    const location = useLocation();
    var combinedReduxInsure = useSelector((state) => state.carsRedux.combinedRedux);
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux)
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [form, setForm] = useState('reg')
    const navigate = useNavigate();
    const [addressState, setAddressState] = useState(combinedReduxInsure?.ownerDetails?.address || combinedReduxInsure?.cAddress ? combinedReduxInsure?.cAddress : '');
    const [fullNameState, setFullName] = useState(combinedReduxInsure?.ownerDetails?.fullName || combinedReduxInsure?.ownerName ? combinedReduxInsure?.ownerName : '');
    const [registrationAddressSame, setRegistrationAddressSame] = useState(-1);
    const [gender, setgender] = useState(-1);
    const [maritalStatus, setMaritalStatus] = useState(-1);
    const [isFinanced, setIsFinanced] = useState(-1);
    const [dob, setDob] = useState();
    const [states, setState] = useState('');
    const [city, setCity] = useState('');
    const [cityDropDown, setCityDropDown] = useState([]);
    const [pinCode, setPinCode] = useState();
    const [aadharNumber, setAadharNumber] = useState();
    const [panNumber, setPanNumber] = useState();
    const [GST, setGST] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [differentAddress, setDifferentAddress] = useState('')
    const [error, setError] = useState({});
    const [additionalUserRole, setAdditionalUserRole] = useState(false);
    // const [isKycVerified, setIsKycVerified] = useState(false);
    const [additionalEmail, setAdditionalEmail] = useState('');
    const [additionalPhoneNumber, setAdditionalPhoneNumber] = useState('');
    const [additionalFullName, setAdditionalFullName] = useState('')
    const [additionalUser, setAdditionalUser] = useState()
    const [aadharImage, setAadharImage] = useState("")
    const [posDetails, setPosDetails] = useState({})

    // added New State ==>
    const [district, setDistrict] = useState("")
    const [flatNumber, setFlatNumber] = useState("")
    const [street, setStreet] = useState("")
    const [streetNumber, setStreetNumber] = useState("")
    const [variantValue, setVariantValue] = useState()
    const [variantName, setVariantName] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [hdfcKyc, setHdfcKyc] = useState(false);
    const [financierList, setFinancierList] = useState([]);
    const [financierValue, setFinancierValue] = useState({})


    useEffect(() => {
        const queryParameter = new URLSearchParams(window.location.search)
        var enquiryIds = queryParameter.get("enquiryId");
        if (enquiryIds.includes("?kycStatus=success"))
            enquiryIds.replace("enquiryIds", "")
        var obj = { type: "get", enquiryId: enquiryIds };
        dispatch(motorEnquiryAPI(obj)).then((res) => {
        });
        let userData = JSON.parse(localStorage.getItem('userDetails'))
        userData?.roles?.includes("pos") || userData?.roles?.includes("admin") ? setAdditionalUserRole(true) : setAdditionalUserRole(false);
        if (userData?.roles?.includes("pos")) {
            setPosDetails(userData)
        }
        if (userData?.roles?.includes("user")) {
            setAdditionalEmail(userData?.email);
            setAdditionalPhoneNumber(userData?.phone)
        }

        if (combinedReduxInsure && combinedReduxInsure?.hasOwnProperty('enquiryId') && state) {
            dispatch(updateCombinedModel({ price: state.price, quickQuote: state }));
            dispatch(motorEnquiryAPI({ price: state.price ? state.price : combinedReduxInsure.price, startDate: state.policyStartDate, endDate: state.policyEndDate, quickQuote: state ? state : combinedReduxInsure.quickQuote, enquiryId: combinedReduxInsure.enquiryId, company: state.slug ? state.slug : combinedReduxInsure.company, status: "lead", inspection: state?.inspection, role: userData?.roles[0] }));
        }

        else if (state) {
            var canInsuranceDetails = (localStorage.getItem('canInsuranceDetails'));
            if (canInsuranceDetails) {
                canInsuranceDetails = JSON.parse(canInsuranceDetails)
                dispatch(motorEnquiryAPI({ price: canInsuranceDetails?.price, startDate: state.policyStartDate, endDate: state.policyEndDate, quickQuote: canInsuranceDetails.quickQuote, enquiryId: canInsuranceDetails.enquiryId, company: state.slug, status: "lead", inspection: canInsuranceDetails.quickQuote?.inspection, role: userData?.roles[0] }));
            }
        }

    }, [])

    useEffect(() => {

        window.scrollTo(0, 0)
        const myArray = location.search.split("?enquiryId=", 2);
    }, [])

    useEffect(() => {
        // alert(combinedReduxInsure.company)
        // if (combinedReduxInsure.company == "motor-icici") {
        //     additionalUser?.mappings?.[combinedReduxInsure.company]?.data?.refNo ? setIsKycVerified(true) : setIsKycVerified(false);
        // }

        // if (combinedReduxInsure.company == "motor-kotak") {
        //     // alert(additionalUser?.mappings?.[combinedReduxInsure.company]?.data?.quoteId)
        //     additionalUser?.mappings?.[combinedReduxInsure.company]?.data?.quoteId ? setIsKycVerified(true) : setIsKycVerified(false);
        // }
    }, [additionalUser])

    useEffect(() => {
        console.log("called0")
        if ((isFinanced == "yes" || isFinanced == "Yes")) {
            console.log("called1")
            axiosGETCall2(`/vehicleInsurance/financierMaster/list/${combinedReduxInsure?.company}`, { slug: combinedReduxInsure?.company }, (callBack => {
                if (callBack.data) {
                    setFinancierList(callBack.data)

                }
            }));
        }
    }, [isFinanced]);

    const handleFinancier = (e) => {
        const slug = combinedReduxInsure?.company
        console.log("change", e);
        if (e.trim()) {
            axiosGETCall2(`/vehicleInsurance/financierMaster/list/${slug}?name=${e}`, { slug }, (callBack => {
                if (callBack.data) {
                    setFinancierList(callBack.data)

                }
            }));
        }
    }

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userDetails'))
        var userDetails = (localStorage.getItem('userDetails'));
        if (!userData?.roles?.includes("pos") || !userData?.roles?.includes("admin")) {
            // alert(userData?.mappings?.[combinedReduxInsure.company]?.data?.refNo)
            // userData?.mappings?.[combinedReduxInsure.company]?.data?.refNo ? setIsKycVerified(true) : setIsKycVerified(false)
            //  if (combinedReduxInsure.company == "motor-icici") {
            //     userData?.mappings?.[combinedReduxInsure.company]?.data?.refNo ? setIsKycVerified(true) : setIsKycVerified(false);
            // }
            // if (combinedReduxInsure.company == "motor-kotak") {
            //     userData?.mappings?.[combinedReduxInsure.company]?.data?.quoteId ? setIsKycVerified(true) : setIsKycVerified(false);
            // }
        }
        if (!combinedReduxInsure?.ownerName) {
            if (userDetails) {
                userDetails = JSON.parse(userDetails);
                setFullName(userDetails.fullName)
            }
        }
        else {
            setFullName(combinedReduxInsure?.ownerDetails?.fullName || combinedReduxInsure?.ownerName)
        }
        setAddressState(combinedReduxInsure?.ownerDetails?.address || combinedReduxInsure?.cAddress)
        if (combinedReduxInsure?.hasOwnProperty('ownerDetails')) {
            setAdditionalPhoneNumber(combinedReduxInsure?.ownerDetails?.mobileNo);
            setAdditionalEmail(combinedReduxInsure?.ownerDetails?.email);
            setRegistrationAddressSame(combinedReduxInsure?.ownerDetails?.registrationAddressSame == true ? 'Yes' : combinedReduxInsure?.ownerDetails?.registrationAddressSame == 'no' ? 'No' : -1)
            setgender(combinedReduxInsure?.ownerDetails?.gender == 'male' ? 'male' : combinedReduxInsure?.ownerDetails?.gender == 'female' ? 'female' : -1)
            setMaritalStatus(combinedReduxInsure?.ownerDetails?.maritalStatus == 'Single' || "single" ? 'Single' : combinedReduxInsure?.ownerDetails?.maritalStatus == 'Married' ? 'Married' : -1)
            setDob(combinedReduxInsure?.ownerDetails?.dob && moment(combinedReduxInsure?.ownerDetails?.dob).format('YYYY-MM-DD'))
            setState(combinedReduxInsure?.ownerDetails?.state)
            setCity(combinedReduxInsure?.ownerDetails?.city)
            setPinCode(combinedReduxInsure?.ownerDetails?.pincode)
            setAadharNumber(combinedReduxInsure?.ownerDetails?.aadharNumber)
            setPanNumber(combinedReduxInsure?.ownerDetails?.panNumber)
            setIsFinanced(combinedReduxInsure?.ownerDetails?.isVehicleFinanced == true ? 'Yes' : combinedReduxInsure?.ownerDetails?.isVehicleFinanced == false ? 'No' : -1)
        }
    }, [combinedReduxInsure])

    const handlePhoneNumber = async (e) => {
        setAdditionalPhoneNumber(e.target.value);
        if (e.target.value.length == "10") {
            axiosPOSTCall('/user/findUser', { phone: e.target.value }, (callBack => {
                if (callBack.data != "User not found") {
                    console.log("callBack", callBack);
                    dispatch(findUser(callBack.data))
                    setAdditionalEmail(callBack?.data?.email)
                    setAdditionalFullName(callBack?.data?.fullName)
                    setAdditionalUser(callBack?.data)
                }
            }));

        }
        handleError("additionalPhoneNumber");
    }

    const handleUserFullName = async (e) => {
        setAdditionalFullName(e.target.value)
    }
    const handleEmail = async (e) => {
        setAdditionalEmail(e.target.value);
        handleError("additionalEmail");
    }

    const verifyKyc = () => {
        let errObj = {};
        if (!dob) {
            errObj = { ...errObj, dob: 'Please Enter Date of Birth.' }
            console.log("error details7", error, dob)
        }
        if (fieldArr[combinedReduxInsure?.company]?.includes("panNumber") && !panNumber) {
            errObj = { ...errObj, panNumber: 'Please Enter Pan Number.' }
        }
        if (Object.keys(errObj).length == 0) {
            console.log("length..1", Object.keys(error).length)
            let obj1 = {
                enquiryId: combinedReduxInsure.enquiryId,
                pan: panNumber,
                dob: dob
            };
            console.log("kycdata",)
            triggerKycCallHdfc(obj1, { ...obj1, type: "PAN" })
        }
        else setError(errObj);


    }
    const submitFun = () => {
        let errObj = {}
        console.log("error first", errObj);
        if (fieldArr[combinedReduxInsure?.company]?.includes("ownerFullName") && !fullNameState) {
            errObj = { ...errObj, firstName: 'Please enter full name.' }
            console.log("error details0", errObj)
        }
        if (fieldArr[combinedReduxInsure?.company]?.includes("phoneNumber") && !additionalPhoneNumber) {
            errObj = { ...errObj, additionalPhoneNumber: 'Please enter mobile number.' }
            console.log("error details 0.5", errObj)
        }
        if (fieldArr[combinedReduxInsure?.company]?.includes("email") && !additionalEmail) {
            errObj = { ...errObj, additionalEmail: 'Please enter email.' }
            console.log("error details1", errObj)
        }
        if (fieldArr[combinedReduxInsure?.company]?.includes("address") && !addressState) {
            errObj = { ...errObj, address: 'Please enter address.' }
            console.log("error details2", error)
        }
        if (fieldArr[combinedReduxInsure?.company]?.includes("address") && registrationAddressSame == -1) {
            errObj = { ...errObj, addressOption: 'Please select address option.' }
            console.log("error details3", error)
        }
        if (gender == -1) {
            errObj = { ...errObj, gender: 'Please select gender.' }
            console.log("error details4", error, gender)
        }
        if (maritalStatus == -1) {
            errObj = { ...errObj, maritalStatus: 'Please select martial status.' }
            console.log("error details5", error, maritalStatus)
        }
        if (!dob) {
            errObj = { ...errObj, dob: 'Please enter date of birth.' }
            console.log("error details7", error, dob)
        }
        if (!states) {
            errObj = { ...errObj, states: 'Please enter state.' }
        }
        if (!city) {
            errObj = { ...errObj, city: 'Please enter city.' }
            console.log("error details8", error)
        }
        if (!pinCode) {
            errObj = { ...errObj, pinCode: 'Please enter PIN Code.' }
        }
        if (fieldArr[combinedReduxInsure?.company]?.includes("isFinanced") && !financierValue?.value && isFinanced == 'yes') {
            errObj = { ...errObj, isFinanced: 'Please select financier details' }
        }
        if (fieldArr[combinedReduxInsure?.company]?.includes("panNumber") && !panNumber) {
            errObj = { ...errObj, panNumber: 'Please enter pan number.' }
            console.log("error inside pan", financierValue, isFinanced)
        }
        if (additionalUserRole) {
            if (!additionalEmail) {
                errObj = { ...errObj, additionalEmail: 'Please enter your email.' }
            }
            else if (!additionalPhoneNumber) {
                errObj = { ...errObj, additionalPhoneNumber: 'Please enter your email.' }
            }
            else {
                if (Object.keys(errObj).length == 0)
                    goToPage()
            }
        }
        else {
            console.log("length..", Object.keys(error).length)
            if (registrationAddressSame == 'No') {
                if (!differentAddress) {
                    errObj = { ...errObj, differentAddress: 'Please enter secondary address.' }
                }
                else {
                    if (Object.keys(errObj).length == 0) {
                        console.log("length..1", Object.keys(error).length)
                        goToPage()
                    }
                }
            }
            else {
                if (Object.keys(errObj).length == 0) {
                    console.log("length..2", Object.keys(error).length)
                    goToPage()
                }
            }
        }
        setError(errObj)
        console.log("error details", error, errObj)
    }

    const goToPage = () => {
        console.log("price of state is ---->", gender);
        // var numb = state?.price.match(/\d/g)
        // console.log("numb is", numb);
        // numb = numb.join("");

        const queryParameters = new URLSearchParams(window.location.search)
        var tokenCode = queryParameters.get("vTokenID");
        console.log("token code is ", tokenCode);
        let userData = JSON.parse(localStorage.getItem('userDetails'))
        // console.log("splitted data is",tokenCode);
        if (tokenCode != null) {
            let userObj = {
                "mappings.motor-kotak.data.tokenId": tokenCode,
                userId: additionalUserRole ? additionalUser._id : userData.userId ? userData.userId : userData._id
            }
            dispatch(updateProfile(userObj));
        }
        var obj = {
            fullName: fullNameState,
            address: addressState,
            registrationAddressSame: registrationAddressSame == 'Yes' ? true : false,
            gender: (gender == 'Male' || gender == 'male') ? 'male' : 'female',
            dob: dob,
            maritalStatus: maritalStatus,
            state: states,
            city: city,
            pincode: pinCode,
            registrationAddress: differentAddress,
            aadharNumber: aadharNumber,
            panNumber: panNumber,
            email: additionalEmail ? additionalEmail : null,
            mobileNo: additionalPhoneNumber ? additionalPhoneNumber : null,
            isVehicleFinanced: isFinanced == 'yes' ? true : false,
            financier: financierValue?.value ? financierValue?.value : "",
            tokenCodeKotak: tokenCode ? tokenCode : null,
            aadharImage: aadharImage.replace("data:image/jpeg;base64,", ""),
            district: district ? district : null,
            flatNumber: flatNumber ? flatNumber : null,
            street: street ? street : null,
            streetNumber: streetNumber ? streetNumber : null
        }
        var obj1 = {
            enquiryId: combinedReduxInsure.enquiryId,
            fullName: additionalFullName ? additionalFullName : fullNameState,
            aadhaarNumber: aadharNumber,
            pan: panNumber,
            dob: dob,
            gender: gender
        }
        console.log("obj 2 are --->", obj1)
        if (combinedReduxInsure?.company == "motor-icici") {
            triggerKycCall(obj, obj1)
        }
        if (combinedReduxInsure?.company == "motor-kotak" && tokenCode == null) {
            triggerKycCallKotak(obj, { ...obj1, type: "PAN" })
        }
        if (combinedReduxInsure?.company == "motor-hdfc" && tokenCode == null) {
            console.log("suresh.................", combinedReduxInsure?.company);
            if (!hdfcKyc) {
                alert("Please verify KYC to proceed");
                return
            }
            updateFun(obj)
        }
        if (combinedReduxInsure?.company == "motor-reliance") {
            console.log("reliance.................", combinedReduxInsure?.company)
            triggerKycCallReliance(obj, { ...obj1, type: "PAN" })
        }
        if (combinedReduxInsure?.company == "motor-godigit") {
            dispatch(updateCombinedModel({ 'ownerDetails': obj }))
            updateFun(obj)
            console.log("obj are------------->", obj);
        }
        if (combinedReduxInsure?.company == "motor-future") {
          triggerFGKycCall(obj, obj1)
      }
      if (combinedReduxInsure?.company == "motor-royalsundaram") {
        triggerKycCallRS(obj, { ...obj1, type: "PAN" })
    }
    }

    const triggerKycCall = async (obj, obj1) => {
        console.log("CALLED", obj)
        axiosPOSTCall1('/vehicleInsurance/motor/verifyKyc/motor-icici', obj1, (callBack => {
            console.log("CALLED", callBack)
            if (callBack && callBack.status == "Success") {
                dispatch(updateCombinedModel({ 'ownerDetails': obj }))
                updateFun(obj)
            }
            else {

                dispatch(updateCombinedModel({ 'ownerDetails': obj }));
                updateFun1(obj)
            }
        }))
    }

    //handle future general kyc api
    const FGKYCRedirection = (url, proposalId, returnUrl) => {
        const form = document.createElement("form");
        form.action = url;
        form.method = "post";
        form.style.display = "none";
    
        const formData = {
          Aggregator_KYC_Req_No: proposalId,
          IC_KYC_No: proposalId,
          Aggregator_Return_URL: returnUrl
        };
    
        Object.entries(formData).forEach(([key, value]) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = value;
          form.appendChild(input);
        });
    
        document.body.appendChild(form);
        form.submit();
      };
    const triggerFGKycCall = async (obj, obj1) => {
      axiosPOSTCall1('/vehicleInsurance/motor/verifyKyc/motor-future', obj1, (callBack => {
        if (callBack && callBack.message == "Success" && callBack.data.verified) {
            dispatch(updateCombinedModel({ 'ownerDetails': obj }));
            updateFun(obj)
        }
        else if (callBack && callBack.message == "Success" && !callBack.data.verified) {
            dispatch(updateCombinedModel({ 'ownerDetails': obj }))
            updateFun1(obj)
            FGKYCRedirection(callBack.data.kycUrl, callBack.data.proposalId, callBack.data.redirectURL)
        }
    }))
  }

    // to convert image into base64 string

    const convertToBase64POI = (e) => {
        const reader = new FileReader()

        reader.readAsDataURL(e.target.files[0]);
        var base64 = ""
        reader.onload = () => {
            console.log('called: ', reader)
            setAadharImage(reader.result)
        }
        return base64
    }


    const triggerKycCallKotak = async (obj, obj1) => {
        axiosPOSTCall1('/vehicleInsurance/motor/verifyKyc/motor-kotak', obj1, (callBack => {
            if (callBack && callBack.message == "Success" && callBack.data.verified) {
                dispatch(updateCombinedModel({ 'ownerDetails': obj }));
                updateFun(obj)
            }
            else if (callBack && callBack.message == "Success" && !callBack.data.verified) {
                dispatch(updateCombinedModel({ 'ownerDetails': obj }))
                updateFuncKotak(obj)
                window.location.href = callBack?.data?.url;
            }
        }))
    };

    // RS KYC handle
    const triggerKycCallRS = async (obj, obj1) => {
      axiosPOSTCall1('/vehicleInsurance/motor/verifyKyc/motor-royalsundaram', obj1, (callBack => {
          if (callBack && callBack.message == "Success" && callBack.data.verified) {
              dispatch(updateCombinedModel({ 'ownerDetails': obj }));
              updateFun(obj)
          }
          else if (callBack && callBack.message == "Success" && !callBack.data.verified) {
              dispatch(updateCombinedModel({ 'ownerDetails': obj }))
              updateFuncKotak(obj)
              window.location.href = callBack?.data?.url;
          }
      }))
  }

    const triggerKycCallHdfc = async (obj, obj1) => {
        axiosPOSTCall1('/vehicleInsurance/motor/verifyKyc/motor-hdfc', obj1, (callBack => {
            // console.log("callback", callBack)
            if (callBack && callBack.message == "Success" && callBack.data.iskycVerified == 1) {
                // dispatch(updateCombinedModel({ 'kycRes1': callBack.data }));
                // console.log("updated model", combinedReduxInsure, "cmbine redux", combinedRedux)
                setHdfcKyc(true);
                setFullName(callBack?.data?.name);
                setDob(moment(callBack?.data?.dob, "DD/MM/YYYY").format('YYYY-MM-DD'))
                setPanNumber(callBack?.data?.pan);
                setAddressState(callBack.data?.permanentAddress);
                setPinCode(callBack.data.correspondencePincode);
                setCity(callBack?.data?.permanentCity);
                setAdditionalEmail(callBack?.data?.email)
                console.log("name", fullNameState, addressState, pinCode, dob)
                // updateFun(obj)
            }
            else if (callBack && callBack.message == "Success" && callBack.data.iskycVerified == 0) {
                dispatch(updateCombinedModel({ 'ownerDetails': obj }))
                updateFuncKotak(obj)
                if (!callBack.data.redirect_link)
                    alert("kyc has not been approved yet. Please try after some time.")
                else {
                    window.location.href = callBack?.data?.redirect_link;
                }
            }
        }))
    };
    const triggerKycCallReliance = async (obj, obj1) => {
        axiosPOSTCall1('/vehicleInsurance/motor/verifyKyc/motor-reliance', obj1, (callBack => {
            console.log("reliance kyc", callBack);

            if (callBack && callBack.message == "Success" && callBack.data.KYC_Verified == "true") {
                dispatch(updateCombinedModel({ 'ownerDetails': obj }));
                updateFun(obj)
            }
            else if (callBack && callBack.message == "Success" && !callBack.data.verified) {
                dispatch(updateCombinedModel({ 'ownerDetails': obj }))
                window.location.href = callBack?.data?.url;
            }
        }))
    }
    const updateFun1 = (obj) => {

        var obj3 = {
            ...combinedReduxInsure,
            ownerDetails: obj,
        };
        dispatch(motorEnquiryAPI(obj3));
        if (combinedReduxInsure?.company == "motor-icici") {
            navigate('/car-insurance/payment/5', { state: state })
        }
        return
    }

    const updateFuncKotak = (obj) => {
        var obj2 = {
            ...combinedReduxInsure, ...{
                ownerDetails: obj,
            }
        };
        dispatch(motorEnquiryAPI(obj2))
        return;
    }

    const updateFun = (obj) => {

        var obj2 = {
            ...combinedReduxInsure, ...{
                ownerDetails: obj,
                status: "lead",
            },
        };
        dispatch(motorEnquiryAPI(obj2)).then(() => {
            navigate(`/car-insurance/payment/4?enquiryId=${combinedReduxInsure.enquiryId}`, { state: state })
        })
        return
    }

    console.log("is financed", isFinanced)
    const ErrorPopup = ({ errors }) => {
        return (
            <>
                <ErrorContainer>
                    <ErrorMsgContainer>{errors}</ErrorMsgContainer>
                    <ErrArrow />
                    <ErrorIcon src={ErrorIconImg} />
                </ErrorContainer>
            </>
        )
    }


    var statesList = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry"
    ];

    // handler for age must be 18 years
    const handleDateChange = (selectedDate) => {
        setDob(selectedDate);
        setError({});

        const age = calculateAge(selectedDate);

        if (age < 18) {
            setError({ dob: 'Age must be 18 or older.' });
            alert("Age must be 18 or older.")
            setDob('')
        }
    };

    const handlePinCode = e => {
        // console.log("pincode",e.target.value,e.target.value.length)
        setPinCode(e.target.value);
        // if(e.target.value?.length==6){
        //     console.log("pincode1",e.target.value,e.target.value.length)
        //     setPinCode(e.target.value);
        //     // pincodeDataHandler(e.target.value, setState, setCityDropDown, setCity);
        // }
    }
    useEffect(() => {
        if (pinCode?.length == 6) {
            pincodeDataHandler(pinCode, setState, setCityDropDown, setCity);
        }
    }, [pinCode])
    console.log("🚀 ~ file: payment3.js:467 ~ CarPayment3 ~ combinedReduxInsure:", combinedReduxInsure)
    useEffect(() => {
        console.log("🚀 ~ file: payment3.js:462 ~ useEffect ~ state:", state)
        const fetchData = async () => {
            try {
                // const query = new URLSearchParams({ ...combinedRedux });
                // const response = await axios.get(`http://localhost:3001/api/v1/vehicleInsurance/filterVariants/${state.slug}?${query}`)
                const response = await dispatch(FilterVaraintName({ slug: state.slug, query: combinedReduxInsure }))
                console.log("🚀 ~ file: payment3.js:466 ~ fetchData ~ response845:", response)

                if (response?.payload.status === "Success") {
                    setVariantValue(response.payload.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
        if (combinedReduxInsure?.changedVariantName) setVariantName(combinedReduxInsure.changedVariantName);
    }, [])
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const kycId = queryParameters.get("kyc_id");
        const enquiryId = queryParameters.get("enquiryId").replace("?kycStatus=success", "");
        console.log("url..", queryParameters.get("kyc_id"))
        if (kycId) {
            setHdfcKyc(true);
            let obj = {
                enquiryId: enquiryId,
                kycId,
                type: "KYC ID"
            };
            triggerKycCallHdfc({}, obj)
        }
    }, [])
    const onChangeVariant = async (e) => {
        setIsLoading(true)
        setError({})
        dispatch(updateCombinedModel({ changedVariantName: e.target.value }));
        if (combinedRedux.company == "motor-hdfc") {
            const data = await dispatch(HDFCMotorUpdateQuote({ ...combinedRedux, changedVariantName: e.target.value }));
            dispatch(updateCombinedModel({ price: data.payload.price, quickQuote: data.payload.data }));
            setIsLoading(false)
        }
        else if (combinedRedux.company == "motor-icici") {
            const data = await dispatch(ICICIMotorUpdateQuote({ ...combinedRedux, changedVariantName: e.target.value }));
            dispatch(updateCombinedModel({ price: data.payload.price, quickQuote: data.payload.data }));
            setIsLoading(false)
        }
        else if (combinedRedux.company == "motor-kotak") {
            const data = await dispatch(KOTAKMotorUpdateQuote({ ...combinedRedux, changedVariantName: e.target.value }));
            dispatch(updateCombinedModel({ price: data.payload.price, quickQuote: data.payload.data }));
            setIsLoading(false)
        }
        else if (combinedRedux.company == "motor-reliance") {
            const data = await dispatch(RELIANCEMotorUpdateQuote({ ...combinedRedux, changedVariantName: e.target.value }));
            dispatch(updateCombinedModel({ price: data.payload.price, quickQuote: data.payload.data }));
            setIsLoading(false)
        }
        else if (combinedRedux.company == "motor-godigit") {
            const data = await dispatch(GODIGITMotorUpdateQuote({ ...combinedRedux, changedVariantName: e.target.value }));
            dispatch(updateCombinedModel({ price: data.payload.price, quickQuote: data.payload.data }));
            setIsLoading(false)
        }
        else if (combinedRedux.company == "motor-future") {
          const data = await dispatch(FutureGeneralMotorUpdateQuote({ ...combinedRedux, changedVariantName: e.target.value }));
          dispatch(updateCombinedModel({ price: data.payload.price, quickQuote: data.payload.data }));
          setIsLoading(false)
      }
        // setTimeout(() => {
        //     console.log("🚀 ~ file: payment3.js:482 ~ onChangeVariant ~ data:", combinedReduxInsure.quickQuote.price)
        //     navigate(`/car-insurance/payment/3?enquiryId=${combinedReduxInsure.enquiryId}`, { state: state })
        //     setIsLoading(false)
        // }, 1);
    }

    const handleError = (errorName) => {
        // if (errorName) {
        const { [errorName]: deletedProperty, ...updatedError } = error
        setError(updatedError);
        console.log("updated error", error)
        // }
    }

    return (
      <>
        <MainContainer>
          <CardContainer>
            <div className="flex">
              <img
                className="arrow"
                src={PrevArrowImg}
                onClick={() =>
                  navigate(
                    `/car-insurance/main?enquiryId=${combinedReduxInsure.enquiryId}`
                  )
                }
              />
              <p className="back-to-quotes">Back to Quotes</p>
            </div>

            <div className="flex apart cards-container">
              <div className="flex-1 mr-half">
                <CarDetailsComponent
                  totalSteps={3}
                  step={2}
                  title="Car Owner Details"
                  yellowBorder={true}
                  noSearch={true}
                  widthAuto={true}
                />
                <br />
                <InputContainer>
                  <FloatingTitle>{'Variants'}</FloatingTitle>
                  <SelectDropdown onChange={(e) => onChangeVariant(e)}>
                    <option
                      value={combinedRedux?.quickQuote?.variant}
                      disabled
                      selected
                    >
                      {combinedRedux?.quickQuote?.variant}
                    </option>
                    {
                      // "hoierhoefjeo"
                      variantValue?.map((item) => (
                        <>
                          {variantName == item ? (
                            <option selected value={item}>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          )}
                          {/* <option value={item}>{item}</option> */}
                        </>
                      ))
                    }
                  </SelectDropdown>
                  {/* {error.hasOwnProperty('fuelType') ? (
                        <ErrorPopup errors={error.fuelType} />
                    )  */}
                  {/* {
                                (combinedReduxInsure?.company === "motor-reliance" && combinedReduxInsure?.newPolicyData?.policyType=="privateCarOD") ?
                                    <InputContainer>
                                        <FloatingTitle>{'TP Start Date'}</FloatingTitle>
                                        <input
                                            value={moment(combinedReduxInsure?.quickQuote?.reqBody?.PolicyDetails?.Policy?.TPPolicyStartDate, "DD/MM/YYYY").format('YYYY-MM-DD')}
                                            type={'date'}
                                           disabled
                                        />
                                    </InputContainer> : null
                            }
                            {
                                (combinedReduxInsure?.company === "motor-reliance" && combinedReduxInsure?.newPolicyData?.policyType=="privateCarOD") ?
                                    <InputContainer>
                                        <FloatingTitle>{'TP End Date'}</FloatingTitle>
                                        <input
                                            value={moment(combinedReduxInsure?.quickQuote?.reqBody?.PolicyDetails?.Policy?.TPPolicyEndDate, "DD/MM/YYYY").format('YYYY-MM-DD')}
                                            type={'date'}
                                            disabled
                                        />
                                    </InputContainer> : null
                            } */}
                </InputContainer>
                {combinedReduxInsure?.company === 'motor-hdfc' &&
                fieldArr[combinedReduxInsure?.company]?.includes('dob') ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'Date of Birth'}
                    </FloatingTitle>
                    <input
                      max={moment().subtract(18, 'years').format('YYYY-MM-DD')}
                      onChange={(e) => {
                        setDob(e.target.value);
                        handleError('dob');
                      }}
                      value={dob}
                      type={'date'}
                      disabled={hdfcKyc}
                    />
                    {error.hasOwnProperty('dob') ? (
                      <ErrorPopup errors={error.dob} />
                    ) : null}
                  </InputContainer>
                ) : null}
                {combinedReduxInsure?.company === 'motor-hdfc' &&
                fieldArr[combinedReduxInsure?.company]?.includes(
                  'panNumber'
                ) ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'PAN Number'}
                    </FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder={'Please Enter Your PAN Card Number'}
                        onChange={(e) => {
                          setPanNumber(e.target.value.toUpperCase());
                          handleError('panNumber');
                        }}
                        maxLength={10}
                        value={panNumber}
                        disabled={hdfcKyc}
                      />
                      {error.hasOwnProperty('panNumber') ? (
                        <ErrorPopup errors={error.panNumber} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}
                {combinedReduxInsure?.company === 'motor-hdfc' && (
                  <button
                    onClick={verifyKyc}
                    className="kyc"
                    disabled={hdfcKyc}
                  >
                    {hdfcKyc ? 'Verified' : 'Verify KYC'}
                  </button>
                )}

                {fieldArr[combinedReduxInsure?.company]?.includes(
                  'ownerFullName'
                ) ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'Owner Full Name'}
                    </FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder="Please enter your full name"
                        onChange={(e) => {
                          setFullName(e.target.value);
                          handleError('firstName');
                        }}
                        maxLength={30}
                        value={fullNameState}
                        disabled={
                          combinedReduxInsure?.company === 'motor-hdfc' &&
                          hdfcKyc
                        }
                      />
                      {error.hasOwnProperty('firstName') ? (
                        <ErrorPopup errors={error.firstName} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}

                {/* {
                                additionalUserRole &&
                                <>

                                    {
                                        fieldArr[combinedReduxInsure?.company]?.includes("userFullName") ?
                                            <InputContainer>
                                                <FloatingTitle><span style={{ color: "red" }}>*</span>{'User Full Name.'}</FloatingTitle>
                                                <HDiv className="m-0">

                                                    <input
                                                        placeholder='Please Enter User Full Name'
                                                        onChange={handleUserFullName}
                                                        value={additionalFullName}
                                                    />
                                                    {error.hasOwnProperty('additionalPhoneNumber') ? (
                                                        <ErrorPopup errors={error.additionalFullName} />
                                                    ) : null}
                                                </HDiv>
                                            </InputContainer> : null
                                    }


                                </>
                            } */}
                {fieldArr[combinedReduxInsure?.company]?.includes(
                  'phoneNumber'
                ) ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'Owner Phone Number'}
                    </FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder="Please Enter Your Phone Number"
                        maxLength={'10'}
                        onChange={handlePhoneNumber}
                        value={additionalPhoneNumber}
                      />
                      {error.hasOwnProperty('additionalPhoneNumber') ? (
                        <ErrorPopup errors={error.additionalPhoneNumber} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}
                {fieldArr[combinedReduxInsure?.company]?.includes('email') ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'Owner Email'}
                    </FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder="Please Enter Your Email"
                        onChange={handleEmail}
                        value={additionalEmail}
                      />
                      {error.hasOwnProperty('additionalEmail') ? (
                        <ErrorPopup errors={error.additionalEmail} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}
                {fieldArr[combinedReduxInsure?.company]?.includes('address') ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'Address'}
                    </FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder="Please enter your address"
                        onChange={(e) => {
                          setAddressState(e.target.value);
                          handleError('address');
                        }}
                        value={addressState}
                        disabled={
                          combinedReduxInsure?.company === 'motor-hdfc' &&
                          hdfcKyc
                        }
                      />
                      {error.hasOwnProperty('address') ? (
                        <ErrorPopup errors={error.address} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}

                {fieldArr[combinedReduxInsure?.company]?.includes('isSame') ? (
                  <InputContainer>
                    <FloatingTitle>
                      {'Registration Address Same as Above Address?'}
                    </FloatingTitle>
                    <SelectDropdown
                      onChange={(e) => {
                        setRegistrationAddressSame(e.target.value);
                        handleError('addressOption');
                      }}
                    >
                      <option value={-1} disabled selected>
                        {'Registration Address Same as Above Address?'}
                      </option>
                      {['Yes', 'No'].map((item) => (
                        <>
                          {registrationAddressSame == item ? (
                            <option selected value={item}>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          )}
                        </>
                      ))}
                    </SelectDropdown>
                    {error.hasOwnProperty('addressOption') ? (
                      <ErrorPopup errors={error.addressOption} />
                    ) : null}
                  </InputContainer>
                ) : null}
                {registrationAddressSame == 'No' && (
                  <>
                    {fieldArr[combinedReduxInsure?.company]?.includes(
                      'diffrentAddress'
                    ) ? (
                      <InputContainer>
                        <FloatingTitle>{'Diffrent Address.'}</FloatingTitle>
                        <HDiv className="m-0">
                          <input
                            placeholder="Please enter different address"
                            onChange={(e) => {
                              setDifferentAddress(e.target.value);
                              handleError('differentAddress');
                            }}
                            value={differentAddress}
                          />
                          {error.hasOwnProperty('differentAddress') ? (
                            <ErrorPopup errors={error.differentAddress} />
                          ) : null}
                        </HDiv>
                      </InputContainer>
                    ) : null}
                  </>
                )}

                {fieldArr[combinedReduxInsure?.company]?.includes('gender') ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'Gender'}
                    </FloatingTitle>
                    <SelectDropdown
                      onChange={(e) => {
                        setgender(
                          e.target.value == 'Male' || e.target.value == 'male'
                            ? 'male'
                            : 'female'
                        );
                        handleError('gender');
                      }}
                    >
                      <option value={-1} disabled selected>
                        {'Please Select Your  Gender'}
                      </option>
                      {['Male', 'Female'].map((item) => (
                        <>
                          {gender == item ? (
                            <option selected value={item}>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          )}
                        </>
                      ))}
                    </SelectDropdown>
                    {error.hasOwnProperty('gender') ? (
                      <ErrorPopup errors={error.gender} />
                    ) : null}
                  </InputContainer>
                ) : null}

                {fieldArr[combinedReduxInsure?.company]?.includes(
                  'martialStatus'
                ) ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'Martial Status'}
                    </FloatingTitle>
                    <SelectDropdown
                      onChange={(e) => {
                        setMaritalStatus(
                          e.target.value == 'Single' ||
                            e.target.value == 'single'
                            ? 'single'
                            : 'married'
                        );
                        handleError('maritalStatus');
                        // setError(delete (error.maritalStatus));
                      }}
                    >
                      <option value={-1} disabled selected>
                        {'Please Select Your Martial Status'}
                      </option>
                      {['Single', 'Married'].map((item) => (
                        <>
                          {maritalStatus == item ? (
                            <option selected value={item}>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          )}
                        </>
                      ))}
                    </SelectDropdown>
                    {error.hasOwnProperty('maritalStatus') ? (
                      <ErrorPopup errors={error.maritalStatus} />
                    ) : null}
                  </InputContainer>
                ) : null}

                {/* <InputComponent
                                title={'Date of Birth'}
                                type='date'
                                value={dob}
                                onChange={(e)=>setDob(e)}
                                max={moment().format('YYYY-MM-DD')}
                            /> */}

                {combinedReduxInsure?.company != 'motor-hdfc' &&
                fieldArr[combinedReduxInsure?.company]?.includes('dob') ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'Date of Birth'}
                    </FloatingTitle>
                    <input
                      max={moment().subtract(18, 'years').format('YYYY-MM-DD')}
                      onChange={(e) => {
                        handleDateChange(e.target.value);
                        handleError('dob');
                      }}
                      value={dob}
                      type={'date'}
                    />
                    {error.hasOwnProperty('dob') ? (
                      <ErrorPopup errors={error.dob} />
                    ) : null}
                  </InputContainer>
                ) : null}

                {/* <InputContainer>
                                <FloatingTitle>{'State.'}</FloatingTitle>
                                <HDiv className="m-0">

                                    <input
                                        placeholder='Please enter your state.'
                                        onChange={(e)=>{
                                            setState(e.target.value);
                                            setError({});
                                        }}
                                        value={states}
                                    />
                                    {error.hasOwnProperty('states') ? (
                                        <ErrorPopup errors={error.states}/>
                                    ) : null}
                                </HDiv>
                            </InputContainer> */}
                {fieldArr[combinedReduxInsure?.company]?.includes('pinCode') ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'Pincode'}
                    </FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder={'Please Enter Your Pincode'}
                        // onChange={(e) => {
                        //     setPinCode(e.target.value);
                        //     handleError("pinCode");
                        // }}
                        onChange={handlePinCode}
                        maxLength={6}
                        value={pinCode}
                      />
                      {error.hasOwnProperty('pinCode') ? (
                        <ErrorPopup errors={error.pinCode} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}

                {fieldArr[combinedReduxInsure?.company]?.includes('city') ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'City'}
                    </FloatingTitle>
                    <HDiv className="m-0">
                      <SelectDropdown
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                          handleError('city');
                        }}
                      >
                        <option value={-1} disabled selected>
                          {'Please Select Your City'}
                        </option>
                        {cityDropDown.map((item, index) => (
                          <>
                            {/* <option value={item}>{item}</option> */}
                            {states == item ? (
                              <option selected value={item}>
                                {item}
                              </option>
                            ) : (
                              <option value={item}>{item}</option>
                            )}
                          </>
                        ))}
                      </SelectDropdown>
                      {/* <input
                                                placeholder='Please Enter Your City'
                                                onChange={(e) => {
                                                    setCity(e.target.value);
                                                    handleError("city");
                                                }}
                                                maxLength={30}
                                                value={city}
                                                disabled={combinedReduxInsure?.company === "motor-hdfc" && hdfcKyc}
                                               
                                            /> */}
                      {error.hasOwnProperty('city') ? (
                        <ErrorPopup errors={error.city} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}
                {fieldArr[combinedReduxInsure?.company]?.includes('state') ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'State'}
                    </FloatingTitle>
                    <SelectDropdown
                      onChange={(e) => {
                        setState(e.target.value);
                        handleError('states');
                      }}
                    >
                      <option value={-1} disabled selected>
                        {'Please Select Your State'}
                      </option>
                      {statesList.map((item, index) => (
                        <>
                          {/* <option value={item}>{item}</option> */}
                          {states == item ? (
                            <option selected value={item}>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          )}
                        </>
                      ))}
                    </SelectDropdown>
                    {error.hasOwnProperty('states') ? (
                      <ErrorPopup errors={error.states} />
                    ) : null}
                  </InputContainer>
                ) : null}

                {fieldArr[combinedReduxInsure?.company]?.includes(
                  'isFinanced'
                ) ? (
                  <InputContainer>
                    <FloatingTitle>{'Is Vehicle Financed?'}</FloatingTitle>
                    <SelectDropdown
                      onChange={(e) => {
                        // alert(e.target.value)
                        setIsFinanced(e.target.value == 'Yes' ? 'yes' : 'no');
                        setError('isFinanced');
                      }}
                    >
                      {['No', 'Yes'].map((item) => (
                        <>
                          {isFinanced == item ? (
                            <option selected value={item}>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          )}
                        </>
                      ))}
                    </SelectDropdown>
                    {/* {error.hasOwnProperty('isFinanced') ? (
                                            <ErrorPopup errors={error.isFinanced} />
                                        ) : null} */}
                  </InputContainer>
                ) : null}
                {fieldArr[combinedReduxInsure?.company]?.includes(
                  'isFinanced'
                ) &&
                (isFinanced == 'yes' || isFinanced == 'Yes')  ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'Choose Financier'}
                    </FloatingTitle>
                    <Select
                      id="countrySelect"
                      name="countrySelect"
                      value={financierValue}
                      onChange={(e) => {
                        setFinancierValue(e);
                      }}
                      placeholder="Search financier"
                      onInputChange={handleFinancier}
                      options={financierList.map((data) => ({
                        value: data.name,
                        label: data.name,
                      }))}
                    />

                    {/* <input type="text" placeholder="search here" /> */}
                    {/* <SelectDropdown onChange={(e) => {

                                        }}>
                                            <option value="" disabled hidden>
                                                Search for a country...
                                            </option>
                                            <option value={"sureesgh"} disabled>
                                                <input
                                                    type="text"
                                                    placeholder="Type a country name..."
                                                    value={"abdgsf"}
                                                />
                                            </option>
                                            {
                                                financierList.map(item => (
                                                    <>
                                                        {

                                                            <option value={item}>{item}</option>
                                                        }
                                                    </>
                                                ))
                                            }
                                        </SelectDropdown> */}
                    {error.hasOwnProperty('isFinanced') ? (
                      <ErrorPopup
                        className="financier"
                        errors={error.isFinanced}
                      />
                    ) : null}
                  </InputContainer>
                ) : null}

                {fieldArr[combinedReduxInsure?.company]?.includes(
                  'aadharNumber'
                ) ? (
                  <InputContainer>
                    <FloatingTitle>{'Aadhar Number.'}</FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder={'Please Enter Your Aadhar Card Number'}
                        onChange={(e) => {
                          setAadharNumber(e.target.value);
                          handleError('aadharNumber');
                        }}
                        maxLength={12}
                        value={aadharNumber}
                      />
                      {error.hasOwnProperty('aadharNumber') ? (
                        <ErrorPopup errors={error.aadharNumber} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}

                {combinedReduxInsure?.company != 'motor-hdfc' &&
                fieldArr[combinedReduxInsure?.company]?.includes(
                  'panNumber'
                ) ? (
                  <InputContainer>
                    <FloatingTitle>
                      <span style={{ color: 'red' }}>*</span>
                      {'PAN Number'}
                    </FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder={'Please Enter Your PAN Card Number'}
                        onChange={(e) => {
                          setPanNumber(e.target.value.toUpperCase());
                          handleError('panNumber');
                        }}
                        maxLength={10}
                        value={panNumber}
                      />
                      {error.hasOwnProperty('panNumber') ? (
                        <ErrorPopup errors={error.panNumber} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}
                {fieldArr[combinedReduxInsure?.company]?.includes(
                  'district'
                ) ? (
                  <InputContainer>
                    <FloatingTitle>{'District.'}</FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder={'Please Enter Your District'}
                        onChange={(e) => {
                          setDistrict(e.target.value);
                          handleError('district');
                        }}
                        value={district}
                      />
                      {error.hasOwnProperty('district') ? (
                        <ErrorPopup errors={error.district} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}

                {fieldArr[combinedReduxInsure?.company]?.includes(
                  'flatNumber'
                ) ? (
                  <InputContainer>
                    <FloatingTitle>{'Flat Number'}</FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder={'Please Enter Your FlatNumber'}
                        onChange={(e) => {
                          setFlatNumber(e.target.value);
                          handleError('flatNumber');
                        }}
                        value={flatNumber}
                      />
                      {error.hasOwnProperty('flatNumber') ? (
                        <ErrorPopup errors={error.flatNumber} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}

                {fieldArr[combinedReduxInsure?.company]?.includes('street') ? (
                  <InputContainer>
                    <FloatingTitle>{'Street'}</FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder={'Please Enter Your Street'}
                        onChange={(e) => {
                          setStreet(e.target.value);
                          handleError('street');
                        }}
                        value={street}
                      />
                      {error.hasOwnProperty('street') ? (
                        <ErrorPopup errors={error.street} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}
                {fieldArr[combinedReduxInsure?.company]?.includes(
                  'streetNumber'
                ) ? (
                  <InputContainer>
                    <FloatingTitle>{'Street Number.'}</FloatingTitle>
                    <HDiv className="m-0">
                      <input
                        placeholder={'Please Enter Your StreetNumber'}
                        onChange={(e) => {
                          setStreetNumber(e.target.value);
                          handleError('streetNumber');
                        }}
                        value={streetNumber}
                      />
                      {error.hasOwnProperty('streetNumber') ? (
                        <ErrorPopup errors={error.streetNumber} />
                      ) : null}
                    </HDiv>
                  </InputContainer>
                ) : null}
                {fieldArr[combinedReduxInsure?.company]?.includes(
                  'uploadAadhar'
                ) ? (
                  <InputContainer>
                    {' '}
                    {/* combinedReduxInsure.company === 'motor-godigit' ? */}
                    <FloatingTitle>{'Upload Aadhar.'}</FloatingTitle>
                    <input
                      type="file"
                      className="inputBox"
                      onChange={convertToBase64POI}
                    />
                  </InputContainer>
                ) : null}

                <button
                  onClick={() => {
                    // navigate('/car-insurance/payment/4',{ state:state})
                    submitFun();
                  }}
                  id="reg"
                  className="continue"
                >
                  Continue
                </button>
              </div>

              <div className="flex-1 ml-half mt-1">
                <div className="cards mb-1">
                  <div className="flex column align-center">
                    <img
                      src={
                        state?.sluginfo?.logo
                          ? state?.sluginfo?.logo
                          : combinedReduxInsure?.quickQuote?.sluginfo?.logo
                      }
                      alt="brand-img"
                      className="brand-img"
                    />
                    <p className="brand-name">
                      {state?.sluginfo?.name
                        ? state?.sluginfo?.name
                        : combinedReduxInsure?.quickQuote?.sluginfo?.name}
                    </p>
                    <p className="basics w-90">
                      {state?.sluginfo?.description
                        ? state?.sluginfo?.description
                        : combinedReduxInsure?.quickQuote?.sluginfo
                            ?.description}
                    </p>
                    <Separator />

                    <div className="flex apart w-100">
                      <p className="total">Total Payable Amount :</p>
                      {isLoading ? (
                        <p className="amount">loading...</p>
                      ) : (
                        <p className="amount">
                          &#8377;{' '}
                          {combinedReduxInsure?.quickQuote?.price
                            ? combinedReduxInsure?.quickQuote?.price
                            : state?.price}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="cards mb-1">
                  <div className="flex apart res-apart res-center">
                    <div className="res-margin">
                      <p className="head">Vehicle Number</p>
                      <p className="data" style={{ width: 250 }}>
                        {combinedReduxInsure?.number
                          ? combinedReduxInsure?.number
                          : 'NEW'}
                      </p>
                    </div>
                    <div className="res-margin">
                      <p className="head">Location</p>
                      <p className="data">
                        {combinedReduxInsure?.city
                          ? combinedReduxInsure?.city
                          : 'N/A'}
                      </p>
                    </div>
                    <div className="res-margin">
                      <p className="head">Policy Expiry</p>
                      <p className="data">
                        {combinedReduxInsure?.insurance
                          ?.insurancePolicyExpiryDate
                          ? moment(
                              combinedReduxInsure?.insurance
                                ?.insurancePolicyExpiryDate
                            ).format('YYYY-MM-DD')
                          : null}
                      </p>
                    </div>
                    <div className="res-margin">
                      <p className="head">Year</p>
                      <p className="data">
                        {combinedReduxInsure?.insurance
                          ?.insurancePolicyExpiryDate
                          ? moment(
                              combinedReduxInsure?.insurance
                                ?.insurancePolicyExpiryDate
                            ).format('YYYY')
                          : null}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="cards mb-1">
                  <div className="flex apart">
                    <div className="video-icon-container">
                      <img src={VideoImg} className="video-img" />
                    </div>

                    <div>
                      <p className="vid-inspection">
                        For the OD and Comprehensive Policy Self / Video
                        Inspection May Be Required, which can be followed after
                        payment
                      </p>
                      {/* <div className="flex apart mt-half res-apart">
                                            <div className="flex align-center">
                                                <div className="orange-dot" />
                                                <p className="vid-tips">It is a long established fact</p>
                                            </div>
                                            <div className="flex align-center">
                                                <div className="orange-dot" />
                                                <p className="vid-tips">It is a long establ</p>
                                            </div>
                                            <div className="flex align-center">
                                                <div className="orange-dot" />
                                                <p className="vid-tips">It is a long established fact thi</p>
                                            </div>
                                        </div> */}
                    </div>
                  </div>
                </div>
                {posDetails?.roles?.includes('pos') && (
                  <div className="cards mb-1">
                    <div className="flex apart res-apart res-center">
                      <div className="res-margin">
                        <p className="head">Pos Name</p>
                        <p className="data" style={{ width: 250 }}>
                          {posDetails?.fullName?.toUpperCase()}
                        </p>
                      </div>
                      <div className="res-margin">
                        <p className="head">Pos Email</p>
                        <p className="data">{posDetails?.email}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </CardContainer>
        </MainContainer>
        <Footer />
      </>
    );
}