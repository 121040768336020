import axios from "axios";
// import simpleAlertCall from "./alerts";
import config from "../Config";
const axiosAPI = axios.create({
  baseURL: config.apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
    'Strict-Transport-Security': 'max-age=31536000',
    'X-XSS-Protection': '1; mode=block',
    'X-Frame-Options': 'SAMEORIGIN',
    'X-Content-Type-Options': 'nosniff',
    'Cache-Control': 'max-age=2628000, public',
    'Referrer-Policy': 'no-referrer-when-downgrade',
    'Content-Security-Policy': 'upgrade-insecure-requests',
    'Permissions-Policy': 'geolocation=(); midi=(); notifications=();'
  },
});


export function axiosPOSTCall(url, data, callback) {

  axiosAPI
    .post(url, data)
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      console.log("🚀 ~ file: axios.js ~ line 15 ~ axiosPOSTCall ~ err", err);
      if (err.response) {
        console.log(
          "🚀 ~ file: axios.js ~ line 21 ~ axiosPOSTCall ~ err.response",
          err.response.data
        );
        callback(err?.response?.data);
        // alert(err?.response?.data?.message)
        // simpleAlertCall(err?.response?.data?.message, () => {});
      } else {
        alert(
          "Network error please check your internet connection.");
        callback(err);
      }
    });
}

export  function checkSum(url, data, callback){
  axiosAPI.post(url,data).then(res=>{callback(res.data)})
  .catch(err=>alert('failed'))
}

export function axiosPOSTCall2(url, data, callback) {
  axiosAPI
    .post(url, data, { headers: {'Authorization': `Bearer ${(localStorage.getItem('Authorization'))}`} })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      console.log("🚀 ~ file: axios.js ~ line 15 ~ axiosPOSTCall ~ err", err);
      if (err.response) {
        callback(err?.response?.data);
        // simpleAlertCall(err?.response?.data?.message, () => {});
      } else {
        alert(
          "Network error please check your internet connection.", err);
        callback(err);
      }
    });
}
export function axiosPOSTCall1(url, data, callback) {
  console.log("Request Data:", data);
  const token = localStorage.getItem('Authorization');
  if (!token) {
    // Handle case where token is missing
    console.error("Authorization token is missing.");
    return;
  }

  axiosAPI
    .post(url, data, { headers: {'Authorization': `Bearer ${token}`} })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      console.error("Request Error:", err);
      if (err.response) {
        // Server responded with an error
        console.error("Server Error:", err.response.data);
        // alert(err.response.data.message || "An error occurred.");
        callback(err.response.data);
      } else if (err.request) {
        // The request was made but no response was received
        console.error("Network Error:", err.request);
        alert("Network error. Please check your internet connection.");
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error:", err.message);
        alert("An error occurred. Please try again later.");
      }
    });
}


export function axiosPUTCall1(url, data, callback) {
 axiosAPI
    .put(url, data, { headers: {'Authorization': `Bearer ${(localStorage.getItem('Authorization'))}`} })
    .then((res) => {
      console.log("**************");
      callback(res.data);
    })

    .catch((err) => {
      console.log("🚀 ~ file: axios.js ~ line 15 ~ axiosPOSTCall ~ err", err);
      if (err.response) {
        callback(err?.response?.data);
        alert(err?.response?.data?.message)
        // simpleAlertCall(err?.response?.data?.message, () => {});
      } else {
        // alert(
        //   "Network error please check your internet connection.");
        callback(err);
      }
    });
}

export function axiosPUTCall(url, data, callback) {

  axiosAPI
    .put(url, data)
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      console.log("🚀 ~ file: axios.js ~ line 15 ~ axiosPOSTCall ~ err", err);
      if (err.response) {
        callback(err?.response?.data);
        alert(err?.response?.data?.message)
        // simpleAlertCall(err?.response?.data?.message, () => {});
      } else {
        alert(
          "Network error please check your internet connection.");
        callback(err);
      }
    });
}

export function axiosGETCall(url, callback) {

  axiosAPI
    .get(url, { headers: {} })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      console.log("🚀 ~ file: axios.js ~ line 15 ~ axiosPOSTCall ~ err", err);
      // alert("Network error please try again later.");
      callback(err);
    });
}

export function axiosGETCall2(url, data, callback) {
  console.log(
    "**************************************************************************************************************************************************\n\n\n\n\n\n\n"
  );
  JSON.stringify(data)
  console.log("data..",data)
  console.log(url);
  console.log(
    "**************************************************************************************************************************************************\n\n\n\n\n\n\n"
  );
  axiosAPI
    .get(url, data, { headers: {} })
    .then((res) => {
      console.log(
        "**************************************************************************************************************************************************\n\n\n\n\n\n\n"
      );
      console.log(
        "🚀 ~ file: axios.js ~ line 6 ~ axiosPOSTCall ~ res",
        JSON.stringify(res.data)
      );
      console.log(
        "**************************************************************************************************************************************************\n\n\n\n\n\n\n"
      );
      callback(res.data);
    })
    .catch((err) => {
      console.log("🚀 ~ file: axios.js ~ line 15 ~ axiosPOSTCall ~ err", err);
      alert("Network error please try again later.");
      callback(err);
    });
}

export function axiosGETCall1(url,params, callback) {

  const searchParams = new URLSearchParams();
  Object.keys(params).forEach(key => searchParams.append(key, params[key]));
  let newUrl = `${url}?`+searchParams.toString();

  axiosAPI
    .get(newUrl, { headers: {'Authorization': `Bearer ${(localStorage.getItem('Authorization'))}`} })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      console.log("🚀 ~ file: axios.js ~ line 15 ~ axiosPOSTCall ~ err", err);
      // alert("Network error please try again later.");
      callback(err);
    });
}

export async function axiosGETCall12(url,params) {
try {
  
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => searchParams.append(key, params[key]));
    let newUrl = `${url}?`+searchParams.toString();
  
    const res = await axiosAPI
      .get(newUrl, { headers: {'Authorization': `Bearer ${(localStorage.getItem('Authorization'))}`} })
      console.log("res.data,",res.data);
     return res.data
      
} catch (error) {
  console.log(error)
}
}

export const pincodeDataHandler = (e, setState, setCityDropdown, setCity) => {
    // setPincode(e);
    if (e.length === 6) {
        axios.get(`https://api.postalpincode.in/pincode/${e}`).then((res) => {
            if (res?.data[0]?.Status === 'Success') {
                const stateData = res?.data[0]?.PostOffice[0]?.State;
                const uniqueCities = res?.data[0]?.PostOffice?.map((item) => item?.Block);
                const city = [...new Set(uniqueCities)].filter((item) => item != 'NA');
                setState(stateData);
                setCity(city[0]);
                setCityDropdown(city);
            }
        });
    }
};