import { MainContainer } from "../../../../assets/common-styles/common-styles"
import { CardContainer } from "./styles"
import SuccessImg from '../../../../assets/commons/submitted.gif'
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { motorEnquiryAPI, saveProposalWrapUp, motorEnquiryPaymentVerification } from "../../../../Redux/CarsRedux";
import PdfDownloadImg from "../../../../assets/car-insurance/dashboard-icons/pdfDownload.jpg"
import { axiosGETCall1 } from "../../../../utilities/utils/apiPost";
import {apiPostCall1} from "../../../../utilities/site-apis"

export const CarPaymentSuccess = () => {
    const [pdf, setPdf] = useState("")
    const [policyNumber, setPolicyNumber] = useState("");
    var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
    const dispatch = useDispatch();
    // const carData = JSON.parse(localStorage.getItem("canInsuranceDetails"));

    const pdfData = useSelector((state) => state.carsRedux.pdfData);
    const combinedReduxInsure = useSelector((state) => state.carsRedux.combinedRedux);
    const queryParameter = new URLSearchParams(window.location.search)
        // let enquiryId = queryParameter.get("enquiryId");
    console.log("combinedReduxInsure is1 ", combinedReduxInsure);

    // hdfc payment varifatcion
    const successFun = (orderId, paymentId, enquiryid, slug) => {

        var obj = {
            "paymentOrderId": orderId,
            "paymentId": paymentId,
            "enquiryId": enquiryid,
            "slug": slug
        }
        apiPostCall1(`/motor/enquiry/payment/verify`, obj);
    }
    const successRelFun = (policyNumber, paymentStatus,trnsNo, enquiryid, slug) => {

        var obj = {
            policyNumber:policyNumber,
            paymentStatus: paymentStatus,
            transactionNo:trnsNo,
            enquiryId: enquiryid,
            slug: slug
        }
        apiPostCall1(`/motor/enquiry/payment/verify`, obj);
    }

    const verifyPayment = (obj) => {
        apiPostCall1(`/motor/enquiry/payment/verify`, obj);
    }
    useEffect(() => {
        axiosGETCall1(`/motor/enquiry/` + enquiryId, {}, (callBack => {
            if (callBack.status == "Success") {
                const slug = callBack?.data?.company
                const res = callBack?.data
                switch (slug) {
                    case "motor-icici":
                        successFun(res.paymentOrderId, enquiryId, enquiryId, slug);
                        break;
                        case "motor-godigit":
                          successFun("", enquiryId, enquiryId, slug);
                          break;
                    case "motor-hdfc":
                        successFun("10731902000001", "", enquiryId, slug);
                        break;
                    case "motor-reliance":
                        const queryParameter = new URLSearchParams(window.location.search)
                        let relianceParam = queryParameter?.get("Output");
                        if (relianceParam) {
                        let outputData = relianceParam.split("|");
                        successRelFun(outputData[1], outputData[0],outputData[2], enquiryId, slug);
                        }
                        break;
                    case "motor-future":
                        const queryParams = new URLSearchParams(window.location.search)
                        const queryParamsObject = {
                          wspid: queryParams.get("WS_P_ID"),
                          tid: queryParams.get("TID"),
                          pgid: queryParams.get("PGID"),
                          premium: queryParams.get("Premium"),
                          response: queryParams.get("Response"),
                          enquiryId: enquiryId,
                          slug: slug
                        };
                        verifyPayment(queryParamsObject);
                        break;
                       case "motor-royalsundaram":
                        
                        verifyPayment({enquiryId: enquiryId,slug: slug});
                        break;
                }
            }
        }));

    }, [])
    useEffect(() => {
       
        var obj = { type: "get", enquiryId: enquiryId };
        dispatch(motorEnquiryAPI(obj)).then((res) => {
            // dispatch(motorEnquiryAPI(res?.payload?.data));
        });
        setPolicyNumber(pdfData?.policyNumber)
        if (!pdfData.base64Pdf) {
            const interval = setInterval(() => {
                // console.log("url",window.location.search)
                axiosGETCall1(`/motor/enquiry/` + enquiryId, {}, (callBack => {
                    if (callBack.status == "Success") {
                        dispatch(saveProposalWrapUp(callBack.data));
                        if(!policyNumber && callBack.data.policyNumber){
                            setPolicyNumber(callBack.data.policyNumber);
                        }
                        //   console.log("PDFurl",callBack.data.quotationURL)
                        if(callBack.data.quotationURL)
                        setPdf(callBack.data.quotationURL);
                    }
                }));
            }, 20000);
            return () => clearInterval(interval);
        }
        // }
    }, [pdfData]);

    return (
      <>
        <MainContainer>
          <CardContainer>
            <div className="flex align-center j-center column">
              <img src={SuccessImg} alt="SuccessImg" className="success" />
              <p className="success-msg">
                Thank you. Your payment has been processed successfully.
              </p>
              <p>
                Your Policy Number is :{' '}
                <strong>{policyNumber ? policyNumber : ' Loading...'}</strong>
              </p>
              <p className="info">
                {!pdf
                  ? 'Policy will be issued shortly. You will receive the policy by email on your registered email ID. You can also download the policy here.'
                  : 'Policy Pdf generated. You will receive the policy by email on your registered email ID.'}
              </p>
            </div>
            {pdf ? (
              <a
                href={pdf}
                target="_blank"
                rel="noreferrer"
                download="file.pdf"
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {' '}
                  <img
                    src={PdfDownloadImg}
                    style={{ width: '50px', heigth: '50px' }}
                    // className='prev'
                  />
                </div>
              </a>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p>Your link will generate shortly</p>
              </div>
            )}
          </CardContainer>
        </MainContainer>
      </>
    );
}