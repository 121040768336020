import React, {useEffect} from 'react'
import './Front.css'
import { FaFemale, FaMale } from 'react-icons/fa'
import Form1Bg from '../../assets/form-1/form-1-bg.png'
import { useNavigate, Link } from "react-router-dom"
import { useState } from 'react'
import { updateFirstPageData } from '../../Redux/LifeRedux';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { DatePicker, Select } from 'antd'
import 'antd/dist/antd.css';
import handleAlert from './../hanleAlert';
import { getAgeOptions } from '../../utilities/dobToCoverTillAge'
import { InputContainer, FloatingTitle, SelectDropdown, ErrorContainer, ErrorMsgContainer, ErrArrow, ErrorIcon, SelectSumAssured, SelectInput } from './styles'
import ErrorIconImg from '../../assets/brand-new-car/exclamation.svg'
import options from './sumAssured-dropdown'
import { calculateAge } from '../../utilities/calculateAge'


const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen ']
const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']

const regex = /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/

const getLT20 = (n) => a[Number(n)]
const getGT20 = (n) => b[n[0]] + ' ' + a[n[1]]

const ErrorPopups = error => {
    return (
        <div className='error-container'>
            <div className="error-msg-container">{error.error}</div>
            <div className="errArrow" />
            <img src={ErrorIconImg} className="error-icon" alt='error-icon' />
        </div>
    )
}

const Front = () => {
    const [selectedRadio, setSelectedRadio] = useState(0)
    const [selectGender, setSelectGender] = useState(0)
    const [coverTillAge, setCoverTillAge] = useState('')
    const [error, setError] = useState('')
    const [dob, setDob] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [formdata, setFormdata] = useState({
        gender: 'male',
        dob: '',
        education: '',
        planType: 'term',
        sumAssured: '',
        coverTillAge: '',
    });


    function toTitleCase(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }
    //  conver Number to word
    function numWords(input) {
        const num = Number(input)
        if (isNaN(num)) return ''
        if (num <= 0) return null

        const numStr = num.toString()
        if (numStr.length > 9) {
            throw new Error('overflow') // Does not support converting more than 9 digits yet
        }

        const [, n1, n2, n3, n4, n5] = ('000000000' + numStr).substr(-9).match(regex) // left pad zeros

        let str = ''
        str += n1 != 0 ? (getLT20(n1) || getGT20(n1)) + 'crore ' : ''
        str += n2 != 0 ? (getLT20(n2) || getGT20(n2)) + 'lakh ' : ''
        str += n3 != 0 ? (getLT20(n3) || getGT20(n3)) + 'thousand ' : ''
        str += n4 != 0 ? getLT20(n4) + 'hundred ' : ''
        str += n5 != 0 && str != '' ? 'and ' : ''
        str += n5 != 0 ? (getLT20(n5) || getGT20(n5)) : ''
        const titleCase = toTitleCase(str.trim() + ' rupees')
        return titleCase
    }

    const handleContinue = (e) => {
        e.preventDefault();
        
        // console.log("choose", formdata.dob)
        const today = new Date();
        const chooseDate = new Date(formdata.dob)
       
        // const selectedDate = new Date(e.target.value)
        const age = calculateAge(formdata.dob)
        // console.log("choose2", today)
        if (!formdata.education || formdata.education == 'Education') {
            setError({ education: 'Please enter your Education.' })
            return
            // handleAlert("Error", "Please enter your Education");
        }
        else if (!formdata.dob || chooseDate >= today) {
            console.log("hello", formdata.dob)
            setError({ birthDate: "Please enter Your Date of Birth" });
            // return
            // handleAlert("Error", "Please enter our Date of Birth");
        }
        else if (age < 18) {
            setError({birthDate:"You Must be 18 Years Old or Above to proceed"});
            // setDob(null)
        }
        else if (age > 65) {
            setError({birthDate:"Your age can't be greater than 65 Years to proceed"});
            // setDob(null)
        }
        else if (!formdata.sumAssured) {
            setError({ sumAssured: "Please enter sum Assured" });
            // handleAlert("Error", "Please enter sum Assured");
        }
        else if (formdata.sumAssured < 500000) {
            setError({ sumAssured: "Minimum sum Assured should be 5 Lakh" });
            // alert("Minimum sum Assured should be 500000 Lakh");
            // handleAlert("Error", "Please enter sum Assured");
        }
        else if (!formdata.coverTillAge) {
            setError({ coverTillAge: "Please enter Cover Till Age" });
            // handleAlert("Error", "Please enter Cover Till Age");
        }


        else {
            const obj = {
                enquiryId: uuid(),
                planType: formdata.planType,
                sumAssured: formdata.sumAssured,
                coverTillAge: formdata.coverTillAge,
                userDetail: {
                    gender: formdata.gender,
                    dob: moment(formdata.dob).format(),
                    education: formdata.education,
                }
            }
            dispatch(updateFirstPageData(obj))
            navigate(`/life-intro-screen?enquiryId=` + obj.enquiryId)
        }

    }

    // for screen always start with 0, 0
      useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
        }, []);

    const handleGender = (e) => {
        setSelectGender(0);
        setFormdata((prev) => ({ ...prev, gender: "male" }));
    };

    const handleGender1 = (e) => {
        setSelectGender(1);
        setFormdata((prev) => ({ ...prev, gender: "female" }));
    };
    const handlePlanType1 = (e) => {
        setSelectedRadio(0)
        setFormdata((prev) => ({ ...prev, planType: "term" }));
    };
    const handlePlanType2 = (e) => {
        setSelectedRadio(1)
        setFormdata((prev) => ({ ...prev, planType: "endowment" }));
    };

    // function for dob validation (user do not select future date)
    const handleDob = (e) => {
        console.log(e.target)
        setDob(e.target.value)
        const currentDate = new Date()
        const selectedDate = new Date(e.target.value)
        const age = currentDate.getFullYear() - selectedDate.getFullYear()
        const selected = moment(e.target.value).format('DD-MM-YYYY')
        const options = getAgeOptions(selectedDate);
        // setDob(e.target.value)
        setCoverTillAge(options);

        // Compare the selected date with the current date
        if (selectedDate > currentDate) {
            // Display an error message or take any necessary action

            setError({ birthDate: "Please select a date that is not in the future" });
            // setDob('')
            // alert("Please select a date that is not in the future.");

        }
        // else if (age < 18) {
        //     setError({birthDate:"You Must be 18 Years Old or Above to proceed"});
        //     setDob(null)
        // }
        else {
            // Update the form data with the valid date
            setFormdata((prev) => ({ ...prev, dob: e.target.value }))
        }

    };

    const handleSunAssured = e => {
         if(e.target.value<1){
            setFormdata((prev) => ({ ...prev, sumAssured: "" }))
            return;
         }
        if (e.target.value > 999999999) {
            alert("Number out of range")
            return;
        }
        if (e.target.value <= 999999999)
            setFormdata((prev) => ({ ...prev, sumAssured: e.target.value }));
    }

    // remove error icon
    const removeError = (err) => {
        console.log("called", error,err)
        if (err = 'education' && error.education)
            setError(error.education = "")
        else if (error.birthDate){
            console.log("called1", error,err)
            setError(error.birthDate = "")
        }
        else if (err = 'sumAssured' && error.sumAssured)
            setError(error.sumAssured = "")
            else if (err = 'coverTillAge' && error.coverTillAge)
            setError(error.coverTillAge = "")
    }
    return (
        <div className='HomeContainer'>

            <div className="leftwrapper">
                <form className="content">
                    <div className='heading1'>
                        <h1>Get term insurance in just 5 mins !</h1>
                        <p>Protection against all forms of death, disease & disablity</p>
                    </div>
                    <div className="checkbox">
                        <input onClick={handlePlanType1} type="radio" name="" id="ac" checked={selectedRadio === 0 ? true : false} />
                        <p>Term</p>
                        {/* <input onClick={handlePlanType2} type="radio" className='radio2' checked={selectedRadio === 1 ? true : false} id="acb" />
                        <p>Endowment</p> */}
                    </div>

                    <div className="gender">
                        <div onClick={handleGender} className={selectGender === 0 ? 'selected' : 'unselected'}  >
                            <FaMale className={selectGender === 0 ? 'selectedGender' : 'unselectedGender'} />
                            Male
                        </div>
                        <div onClick={handleGender1} className={selectGender === 1 ? 'selected' : 'unselected'}  >
                            <FaFemale className={selectGender === 1 ? 'selectedGender' : 'unselectedGender'} />
                            Female
                        </div>
                    </div>
                    <div className="inp">
                        <InputContainer onClick={() => removeError("education")}>
                            <FloatingTitle>Education</FloatingTitle>
                            <SelectDropdown contentEditable
                                value={formdata.education}
                                onChange={(e) => {
                                    setFormdata((prev) => ({ ...prev, education: e.target.value }));
                                }}
                            >
                                <option value="" disabled>
                                    Select Education
                                </option>
                                <option value="matriculate">Matriculate</option>
                                <option value="intermediate">Intermediate</option>
                                <option value="graduate">Graduate</option>
                                <option value="post-graduate">Post-graduate</option>
                            </SelectDropdown>
                            {error.hasOwnProperty('education') ? (
                                <ErrorPopups error={error.education} />
                            ) : null}
                        </InputContainer>

                        <InputContainer onClick={() => removeError('dob')}>
                            <FloatingTitle>{'Select Date of Birth'}</FloatingTitle>
                            <input
                                max={moment().format('DD-MM-YYYY')}
                                format={moment().format('DD-MM-YYYY')}

                                onChange={handleDob}
                                value={formdata.dob}
                                type={'date'}
                            />
                            {error.hasOwnProperty('birthDate') ? (
                                <ErrorPopups error={error.birthDate} />
                            ) : null}
                        </InputContainer>


                        <div className='left-side'>
                            <InputContainer className='sumAss' onClick={() => removeError("sumAssured")}>
                                <FloatingTitle>Sum Assured</FloatingTitle>
                                <input list='sum' type='number' className='no-spinner' max='100'
                                    onChange={handleSunAssured}
                                    placeholder='Sum Assured'
                                    value={formdata.sumAssured}
                                />
                                <datalist
                                    value={formdata.sumAssured}
                                    id='sum'
                                    onChange={(e) => {
                                        setFormdata((prev) => ({ ...prev, sumAssured: e.target.value }));
                                    }}>

                                    <option value={''} disabled>Sum Assured</option>
                                       {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                        {option.label}
                                        </option>
                                    ))}
                                </datalist>
                                {error.hasOwnProperty('sumAssured') ? (
                                    <ErrorPopups error={error.sumAssured} />
                                ) : null}
                                
                            </InputContainer>
                            <p className='left-sum-text'>{numWords(formdata.sumAssured)}</p>
                        </div>
                        <InputContainer onClick={() => removeError("coverTillAge")}>
                            <FloatingTitle>Cover Till Age</FloatingTitle>
                            <SelectDropdown
                                value={formdata.coverTillAge}
                                onChange={(e) => {
                                    setFormdata((prev) => ({ ...prev, coverTillAge: e.target.value }));
                                }}
                            >
                                <option value="" disabled>
                                    Select Cover Till Age
                                </option>
                                {Array.isArray(coverTillAge) &&
                                    coverTillAge.map((age) => (
                                        <option key={age} value={age}>
                                            {age} Years
                                        </option>
                                    ))}
                            </SelectDropdown>
                            {error.hasOwnProperty('coverTillAge') ? (
                                <ErrorPopups error={error.coverTillAge} />
                            ) : null}
                        </InputContainer>

                    </div>

                    <div className="btn">
                        {/* <Link to='/get-quotes' > */}
                        <button className='btn-link' onClick={handleContinue}> Get Quotes </button>
                        {/* </Link> */}
                    </div>
                </form>
            </div>

            <div className="rightwrapper">
                <img className='form-bg-img' src={Form1Bg} alt="form-bga" />
                <div>
                    <p className='from-text'>Embrace the Uncertain Future Fearlessly: <br /> Our Life Insurance is Your Shield Against Life's Surprises.</p>

                </div>

            </div>
        </div>
    )
}

export default Front

