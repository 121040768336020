// export const validationHandler = ({
//   firstName,
//   middleName,
//   lastName,
//   email,
//   mobile,
//   state,
//   city,
//   pincode,
//   isExistingCustomer, // Y/N
//   isFirstTimeBuyer, // if isExistingCustomer is N only then this field will be shown
//   premiumHolidays, // only 0/1/2/3/4
//   installmentYears, //5/10/20/30/40
//   slugData,
//   setError,
// }) => {
//   // regex for validation
//   const nameRegex = /^[A-Za-z]*$/;
//   const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
//   const pincodeRegex = /^[0-9]{6}$/;
//   const mobileRegex = /^[0-9]{10}$/;
//   const cityRegex = /^[A-Za-z\s]+$/;
//   const isExistingCustomerRegex = /^[Y|N]$/;
//   const isFirstTimeBuyerRegex = /^[Y|N]$/;
//   const premiumHolidaysRegex = /^(0|1|2|3|4)$/;
//   const installmentYearsRegex = /^(5|10|20|30|40)$/;

//   if (!firstName || !nameRegex.test(firstName)) {
//     setError({ error: 'Please enter a valid first name' });
//     return 'Please enter a valid first name';
//   }

//   if (middleName && !nameRegex.test(middleName)) {
//     setError({ error: 'Please enter a valid middle name' });
//     return 'Please enter a valid middle name';
//   }

//   if (!lastName || !nameRegex.test(lastName)) {
//     setError({ error: 'Please enter a valid last name' });
//     return 'Please enter a valid last name';
//   }

//   if (!email || !emailRegex.test(email)) {
//     setError({ error: 'Please enter a valid email' });
//     return 'Please enter a valid email';
//   }

//   if (!mobile || !mobileRegex.test(mobile)) {
//     setError({ error: 'Please enter a valid mobile number' });
//     return 'Please enter a valid mobile number';
//   }

//   if (!state) {
//     setError({ error: 'Please enter a valid state' });
//     return 'Please enter a valid state';
//   }

//   if (!city || !cityRegex.test(city)) {
//     setError({ error: 'Please enter a valid city' });
//     return 'Please enter a valid city';
//   }

//   if (!pincode || !pincodeRegex.test(pincode)) {
//     setError({ error: 'Please enter a valid pincode' });
//     return 'Please enter a valid pincode';
//   }

//   if (
//     !isExistingCustomer ||
//     !isExistingCustomerRegex.test(isExistingCustomer)
//   ) {
//     setError({ error: 'Please enter a valid isExistingCustomer' });
//     return 'Please enter a valid isExistingCustomer';
//   }

//   if (
//     isExistingCustomer === 'N' &&
//     (!isFirstTimeBuyer || !isFirstTimeBuyerRegex.test(isFirstTimeBuyer))
//   ) {
//     setError({ error: 'Please enter a valid isFirstTimeBuyer' });
//     return 'Please enter a valid isFirstTimeBuyer';
//   }
//   // Ensure mutual exclusivity between isFirstTimeBuyer and isExistingCustomer
//   if (isFirstTimeBuyer === 'Y' && isExistingCustomer !== 'N') {
//     setError({
//       error: 'If First Time Buyer is Y, Existing Customer must be N',
//     });
//     return 'If First Time Buyer is Y, Existing Customer must be N';
//   }

//   if (isFirstTimeBuyer === 'N' && isExistingCustomer !== 'Y') {
//     setError({
//       error: 'If First Time Buyer is N, Existing Customer must be Y',
//     });
//     return 'If First Time Buyer is N, Existing Customer must be Y';
//   }

//   if (!premiumHolidays || !premiumHolidaysRegex.test(premiumHolidays)) {
//     setError({ error: 'Please enter a valid premiumHolidays' });
//     return 'Please enter a valid premiumHolidays';
//   }

//   if (!installmentYears || !installmentYearsRegex.test(installmentYears)) {
//     console.log('🚀 ~ installmentYears:', typeof installmentYears);
//     setError({ error: 'Please enter a valid installmentYears' });
//     return 'Please enter a valid installmentYears';
//   }

//   if (
//     !firstName ||
//     !lastName ||
//     !email ||
//     !mobile ||
//     !state ||
//     !city ||
//     !pincode ||
//     !isExistingCustomer ||
//     !premiumHolidays ||
//     !installmentYears ||
//     (isExistingCustomer === 'N' && !isFirstTimeBuyer) ||
//     (isExistingCustomer === 'Y' &&
//       isFirstTimeBuyer &&
//       !isFirstTimeBuyerRegex.test(isFirstTimeBuyer)) // added validation for Y case
//   ) {
//     setError({ error: 'Please fill all the fields' });
//     return 'Please fill all the fields';
//   }

//   return null;
// };


export const validationHandler = ({
  firstName,
  middleName,
  lastName,
  email,
  mobile,
  state,
  city,
  pincode,
  isExistingCustomer,
  isFirstTimeBuyer,
  premiumHolidays,
  installmentYears,
  slugData,
  deathBenefitOption,
  setError,
}) => {
  const nameRegex = /^[A-Za-z]*$/;
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const pincodeRegex = /^[0-9]{6}$/;
  const mobileRegex = /^[0-9]{10}$/;
  const cityRegex = /^[A-Za-z\s]+$/;
  const isExistingCustomerRegex = /^[Y|N]$/;
  const isFirstTimeBuyerRegex = /^[Y|N]$/;
  const premiumHolidaysRegex = /^(0|1|2|3|4)$/;
  const installmentYearsRegex = /^(5|10|20|30|40)$/;

  if (!firstName || !nameRegex.test(firstName)) {
    setError({ error: 'Please enter a valid first name' });
    return 'Please enter a valid first name';
  }
  if (middleName && !nameRegex.test(middleName)) {
    setError({ error: 'Please enter a valid middle name' });
    return 'Please enter a valid middle name';
  }
  if (!lastName || !nameRegex.test(lastName)) {
    setError({ error: 'Please enter a valid last name' });
    return 'Please enter a valid last name';
  }
  if (!email || !emailRegex.test(email)) {
    setError({ error: 'Please enter a valid email' });
    return 'Please enter a valid email';
  }
  if (!mobile || !mobileRegex.test(mobile)) {
    setError({ error: 'Please enter a valid mobile number' });
    return 'Please enter a valid mobile number';
  }
  if (!state) {
    setError({ error: 'Please enter a valid state' });
    return 'Please enter a valid state';
  }
  if (!city || !cityRegex.test(city)) {
    setError({ error: 'Please enter a valid city' });
    return 'Please enter a valid city';
  }
  if (!pincode || !pincodeRegex.test(pincode)) {
    setError({ error: 'Please enter a valid pincode' });
    return 'Please enter a valid pincode';
  }

  if (['bajaj-life', 'bajaj-life-plus', 'bajaj-life-rop'].includes(slugData)) {
    if (
      !isExistingCustomer ||
      !isExistingCustomerRegex.test(isExistingCustomer)
    ) {
      setError({ error: 'Please enter a valid isExistingCustomer' });
      return 'Please enter a valid isExistingCustomer';
    }
    if (
      isExistingCustomer === 'N' &&
      (!isFirstTimeBuyer || !isFirstTimeBuyerRegex.test(isFirstTimeBuyer))
    ) {
      setError({ error: 'Please enter a valid isFirstTimeBuyer' });
      return 'Please enter a valid isFirstTimeBuyer';
    }
    if (isFirstTimeBuyer === 'Y' && isExistingCustomer !== 'N') {
      setError({
        error: 'If First Time Buyer is Y, Existing Customer must be N',
      });
      return 'If First Time Buyer is Y, Existing Customer must be N';
    }
    if (isFirstTimeBuyer === 'N' && isExistingCustomer !== 'Y') {
      setError({
        error: 'If First Time Buyer is N, Existing Customer must be Y',
      });
      return 'If First Time Buyer is N, Existing Customer must be Y';
    }
    if (!premiumHolidays || !premiumHolidaysRegex.test(premiumHolidays)) {
      setError({ error: 'Please enter a valid premiumHolidays' });
      return 'Please enter a valid premiumHolidays';
    }
    if (deathBenefitOption === 'Lump-Sum-Income' || deathBenefitOption === 'Income') {	
      if (!installmentYears || !installmentYearsRegex.test(installmentYears)) {
        setError({ error: 'Please enter a valid installmentYears' });
        return 'Please enter a valid installmentYears';
      }
    }
  }

 if (
   !firstName ||
   !lastName ||
   !email ||
   !mobile ||
   !state ||
   !city ||
   !pincode ||
   (['bajaj-life', 'bajaj-life-plus', 'bajaj-life-rop'].includes(slugData) &&
     (!isExistingCustomer ||
       !premiumHolidays ||
       ((deathBenefitOption === 'Lump-Sum-Income' ||
         deathBenefitOption === 'Income') &&
         !installmentYears) ||
       (isExistingCustomer === 'N' && !isFirstTimeBuyer) ||
       (isExistingCustomer === 'Y' &&
         isFirstTimeBuyer &&
         !isFirstTimeBuyerRegex.test(isFirstTimeBuyer))))
 ) {
   setError({ error: 'Please fill all the fields' });
   return 'Please fill all the fields';
 }

  return null;
};

