import React, {useEffect} from 'react';
import './sitemap.css';
import Footer from '../../../components/Footer/FooterNew';
import banner from '../../../assets/banner/1.png'
import Quick from '../../../components/new_quick_links/quick';
import BlogCard from '../../../components/blog-card/blog';
import { useNavigate } from 'react-router-dom'
const Sitemap = () => {
  const navigate = useNavigate()
      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  return (
    <div>
    <img src={banner} className='image'/>
    {/* Quick Links */}
    <Quick heading = "Sitemap" />
    <div className="sitemap-container">
    <div className='partOne'>
      <div className="section">
        <h2 className='sitemap_heading'>COMPANY</h2>
        <ul>
          <li onClick={() => navigate('/about-simpliinsure')}>About Simpliinsure.com</li>
          <li onClick={() => navigate('/our-partners')}>Our Partners</li>
          <li onClick={() => navigate('/products')}>Products</li>
          {/* <li>Media Corner</li> */}
          <li onClick={() => navigate('/work-with-us')}>Work With Us</li>
          <li onClick={() => navigate('/contact-us')}>Contact Us</li>
        </ul>
      </div>
      <div className="section">
        <h2 className='sitemap_heading'>KNOWLEDGE CENTER</h2>
        <ul>
          <li onClick={() => navigate('/blog')}>Learn</li>
          <li onClick={() => navigate('/newsletter')}>Newsletter</li>
          <li>Top Insurance Policies</li>
          <li onClick={() => navigate('/careers')}>Careers</li>
          <li onClick={() => navigate('/faq')}>FAQs</li>
        </ul>
      </div>
      <div className="section">
        <h2 className='sitemap_heading'>QUICK LINKS</h2>
        <ul>
          <li>Testimonials</li>
          <li onClick={() => navigate('/customer-feedback')}>Customer Feedback</li>
          <li>Grievances</li>
          <li onClick={() => navigate('/privacy-policy')}>Privacy Policy</li>
          <li onClick={() => navigate('/terms-and-conditions')}>Terms & Conditions</li>
          <li onClick={() => navigate('/sitemap')}>Sitemap</li>
        </ul>
      </div>

      </div>

    <div className='partTwo'>
          <div className="section">
        <h2 className='sitemap_heading'>PRODUCTS</h2>
        <div className="product-category">
          <h3 onClick={() => navigate('/products/individual-insurance')}>INDIVIDUAL</h3>
          <ul>
            <li onClick={() => navigate('/products/individual-insurance/health-insurance')}>Health Insurance</li>
            <li onClick={() => navigate('/products/individual-insurance/health-insurance/individual-health-insurance')}>Individual Health Insurance</li>
            <li onClick={() => navigate('/products/individual-insurance/health-insurance/family-floater-health-insurance')}>Family Floater Health Insurance</li>
            <li onClick={() => navigate('/products/individual-insurance/life-insurance')}>Life Insurance</li>
            <li onClick={() => navigate('/products/individual-insurance/life-insurance/term-life-insurance')}>Term Life Insurance</li>
            <li onClick={() => navigate('/products/individual-insurance/life-insurance/life-investment-related-cover')}>Life Investment-Related Cover</li>
            <li onClick={() => navigate('/products/individual-insurance/motor-insurance')}>Motor Insurance</li>
            <li onClick={() => navigate('/products/individual-insurance/motor-insurance/bike-insurance-policy')}>Bike Insurance Policy</li>
            <li onClick={() => navigate('/products/individual-insurance/motor-insurance/car-insurance-policy')}>Car Insurance Policy</li>
            <li onClick={() => navigate('/products/individual-insurance/property-insurance')}>Property Insurance</li>
            <li onClick={() => navigate('/products/individual-insurance/property-insurance/home-insurance')}>Home Insurance</li>
          </ul>
        </div>
          <div className="product-category">
          <h3 onClick={() => navigate('/products/corporate-insurance')}>CORPORATE</h3>
            <h3 onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare-insurance')}>Employee and Group Welfare</h3>
          <ul>
            <li onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare/group-term-insurance')}>Group Term Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare/mediclaim-insurance-policy')}>Mediclaim Policy</li>
            <li onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare/mediclaim-insurance-policy')}>Group Mediclaim Policy (GMC)</li>
            <li onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare/group-personal-accident-insurance-gpa')}>Group Personal Accident Insurance (GPA)</li>
            <li onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare/workers-compensation-insurance')}>Workers Compensation Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare/directors-and-officers-liability-insurance')}>Directors and Officers Liability Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare/product-liability-insurance')}>Product Liability Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare/commercial-general-liability-insurance')}>Commercial General Liability Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare/professional-indemnity-insurance')}>Professional Indemnity Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare/public-liability-insurance')}>Public Liability Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/employee-and-group-welfare/small-industries-insurance')}>Small Industries Insurance</li>
          </ul>
        </div>
    </div>

        <div className="product-category">
          <h3 onClick={() => navigate('/products/corporate-insurance/data-and-financial-security-insurance')}>Data and Financial Security Insurance</h3>
          <ul>
            <li onClick={() => navigate('/products/corporate-insurance/data-and-financial-security-insurance/cyber-security-insurance')}>Cyber Security Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/data-and-financial-security-insurance/bankers-indemnity-insurance')}>Bankers Indemnity Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/data-and-financial-security-insurance/fidelity-guarantee-insurance-fgi')}>Fidelity Guarantee Insurance (F.G.I)</li>
            <li onClick={() => navigate('/products/corporate-insurance/data-and-financial-security-insurance/money-insurance-policy')}>Money Insurance Policy</li>
          </ul>
        </div>

        <div className="product-category">
          <h3 onClick={() => navigate('/products/corporate-insurance/asset-insurance')}>Asset Insurance</h3>
          <ul>
            <li onClick={() => navigate('products/corporate-insurance/asset-insurance/burglary-insurance')}>Burglary Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/asset-insurance/standard-fire-and-special-perils-insurance')}>Standard Fire and Special Perils Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/asset-insurance/marine-cargo-insurance')}>Marine Cargo Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/asset-insurance/contractors-all-risks-insurance-car')}>Contractors' All Risks Insurance (C.A.R)</li>
            <li onClick={() => navigate('/products/corporate-insurance/asset-insurance/engineering-all-risk-insurance-ear')}>Engineering All-Risk Insurance (E.A.R)</li>
            <li onClick={() => navigate('/products/corporate-insurance/asset-insurance/machinery-breakdown-insurance')}>Machinery Breakdown Insurance</li>
            <li onClick={() => navigate('/products/corporate-insurance/asset-insurance/electronic-equipment-insurance')}>Electronic Equipment Insurance</li>
            <li>Contractor's Plant & Machinery Insurance</li>
          </ul>
        </div>
        </div>

        <div className='partThree'>
          <div className="section">
        <h2 className='sitemap_heading'>INSURANCE COMPANIES - PARTNERS</h2>
        <ul>
          <li onClick={() => navigate('/brand/royal-sundaram')}>Royal Sundaram</li>
          <li onClick={() => navigate('/brand/sbi-general-insurance')}>SBI General Insurance</li>
          <li onClick={() => navigate('/brand/cholamandalam-ms-general-insurance')}>Cholamandalam MS General Insurance</li>
          <li onClick={() => navigate('/brand/future-generali-india-insurance-company')}>Future Generali India Insurance Company</li>
          <li onClick={() => navigate('/brand/raheja-qbe')}>Raheja QBE</li>
          <li onClick={() => navigate('/brand/shriram-general-insurance')}>Shriram General Insurance</li>
          <li onClick={() => navigate('/brand/liberty-general-insurance')}>Liberty General Insurance</li>
          <li onClick={() => navigate('/brand/edelweiss-insurance')}>Edelweiss Insurance</li>
          <li onClick={() => navigate('/brand/universal-sompo-insurance')}>Universal Sompo Insurance</li>
          <li onClick={() => navigate('/brand/magma')}>Magma</li>
          <li onClick={() => navigate('/brand/niva')}>Max Bupa Health Insurance</li>
          <li onClick={() => navigate('/brand/hdfc-ergo-general-insurance')}>HDFC ERGO General Insurance</li>
          <li onClick={() => navigate('/brand/the-new-india-assurance')}>The New India Assurance</li>
          <li onClick={() => navigate('/brand/united-india-insurance')}>United India Insurance</li>
          <li onClick={() => navigate('/brand/care-health-insurance')}>Care Health Insurance</li>
          <li onClick={() => navigate('/brand/tata-aig-general-insurance')}>Tata AIG General Insurance</li>
          <li onClick={() => navigate('/brand/go-digit')}>Go Digit</li>
          <li onClick={() => navigate('/brand/kotak-mahindra-general-insurance')}>Kotak Mahindra General Insurance</li>
          <li onClick={() => navigate('/brand/icici-lombard')}>ICICI Lombard</li>
          <li onClick={() => navigate('/brand/bajaj-allianz-general-insurance')}>Bajaj Allianz General Insurance</li>
          <li onClick={() => navigate('/brand/cigna-health-insurance')}>Cigna Health Insurance</li>
          <li onClick={() => navigate('/brand/iffco-tokio-general-insurance')}>IFFCO Tokio General Insurance</li>
          <li onClick={() => navigate('/brand/oriental-insurance')}>Oriental Insurance</li>
          <li onClick={() => navigate('/brand/star-health-insurance')}>Star Health</li>
          <li onClick={() => navigate('/brand/reliance-insurance')}>Reliance Insurance</li>
          <li onClick={() => navigate('/brand/aditya-birla-health-insurance-co-limited-abhicl')}>Aditya Birla Health Insurance Co. Limited (ABHICL)</li>
        </ul>
      </div>

      <div className="section">
        <h2 className='sitemap_heading'>BEST BUY</h2>
        <ul>
          <li>Insurance Buying Guide</li>
          <li>Best Individual Health Insurance Policies</li>
          <li>Best Individual Life Insurance Policies</li>
          <li>Best Individual Bike Insurance Policies</li>
          <li>Best Individual Car Insurance Policies</li>
          <li>Best Corporate Insurance Policies</li>
        </ul>
      </div>
      </div>
    </div>
    {/* for blog section */}
    <div className='blog_section'>
    <h2 className='learnMore'>LEARN MORE</h2>
    <div className="blog_container">
    <BlogCard />
    </div>
    </div>
    <div style={{padding:"40px"}}>

    </div>
    <Footer />
    </div>
  );
};

export default Sitemap;

       
