import OurPartners  from '../../pages/linkPages/OurPartners/OurPartners';
import {Products} from '../../pages/products/Products';
import {Products as IndividualInsurance} from '../../pages/products/Products';
import PropertyInsurance from '../../pages/linkPages/property-insurance/PropertyInsurance';
import AssetProtectionInsurance from '../../pages/linkPages/corporate-insurance/asset-insurance/asset';
import DataAndFincinalInsurance from '../../pages/linkPages/corporate-insurance/DFS/dfs'
import EmployeeWelfare from '../../pages/linkPages/corporate-insurance/EGW/EGW';
import MediaCorner from '../../pages/linkPages/media-corner/MediaCorner';
import WorkWithUs from '../../pages/linkPages/workWithUs/workwithus';
import ContactUs from '../../pages/contact_v2/contactPage'
import { AboutUs } from '../../pages/footer-pages/about-us/new-about-us'
import BlogData from '../../pages/brand-main-page/main-brand-page'
import { Corporate } from '../../pages/corporates/Corporate'





export const menuItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'About Us',
    path: '/about-simpliinsure',
    component: AboutUs,
  },
  {
    title: 'Our Partners',
    path: '/our-partners',
    component: OurPartners,
  },
  {
    title: 'Products',
    path: '/products',
    component: Products,
    submenu: [
      {
        title: 'Individual Insurance',
        path: '/products/individual-insurance',
        component: IndividualInsurance,
        submenu: [
          {
            title: 'Health Insurance',
            path: '/products/individual-insurance/health-insurance',
            // /products/individual/health
            submenu: [
              {
                title: 'Self Health Insurance Plan',
                path: '/products/individual-insurance/health-insurance/individual-health-insurance',
              },
              {
                title: 'Family Floater Health Insurance Plan',
                path: '/products/individual-insurance/health-insurance/family-floater-health-insurance',
              },
            ],
          },
          {
            title: 'Life Insurance',
            path: '/products/individual-insurance/life-insurance',
            submenu: [
              {
                title: 'Term Life Insurance',
                path: '/products/individual-insurance/life-insurance/term-life-insurance',
              },
              {
                title: 'Life Investment Related Cover',
                path: '/products/individual-insurance/life-insurance/life-investment-related-cover',
              },
            ],
          },
          {
            title: 'Motor Insurance',
            path: '/products/individual-insurance/motor-insurance',

            submenu: [
              {
                title: 'Private Car Insurance Plan',
                path: '/products/individual-insurance/motor-insurance/car-insurance-policy',
              },
              {
                title: 'Two Wheeler Insurance Plan',
                path: '/products/individual-insurance/motor-insurance/bike-insurance-policy',
              },
            ],
          },
          {
            title: 'Property Insurance',
            path: '/products/individual-insurance/property-insurance',
            // component: PropertyInsurance,
            submenu: [
              {
                title: 'Home Insurance',
                path: '/products/individual-insurance/property-insurance/home-insurance',
              },
            ],
          },
        ],
      },
      {
        title: 'Corporate Insurance',
        path: '/products/corporate-insurance',
        component: Corporate,
        submenu: [
          {
            title: 'Employee & Group Welfare',
            path: '/products/corporate-insurance/employee-and-group-welfare-insurance',
            // component: EmployeeWelfare,
            submenu: [
              {
                title: 'Group Term Insurance',
                path: '/products/corporate-insurance/employee-and-group-welfare/group-term-insurance',
              },
              {
                title: 'Mediclaim',
                path: '/products/corporate-insurance/employee-and-group-welfare/mediclaim-insurance-policy',
              },
              {
                title: 'Director’s and Officers Liability Insurance',
                path: '/products/corporate-insurance/employee-and-group-welfare/directors-and-officers-liability-insurance',
              },
              {
                title: 'Group Personal Accident(GPA)',
                path: '/products/corporate-insurance/employee-and-group-welfare/group-personal-accident-insurance-gpa',
              },
              {
                title: 'Workers Compensation Insurance',
                path: '/products/corporate-insurance/employee-and-group-welfare/workers-compensation-insurance',
              },
              {
                title: 'Public Liability Insurance',
                path: '/products/corporate-insurance/employee-and-group-welfare/public-liability-insurance',
              },
              {
                title: 'Professional Indemnity Insurance',
                path: '/products/corporate-insurance/employee-and-group-welfare/professional-indemnity-insurance',
              },
              {
                title: 'Product Liability Insurance',
                path: '/products/corporate-insurance/employee-and-group-welfare/product-liability-insurance',
              },
              {
                title: 'Commercial General Liability Insurance',
                path: '/products/corporate-insurance/employee-and-group-welfare/commercial-general-liability-insurance',
              },
              {
                title: 'Small Industrial Insurance',
                path: '/products/corporate-insurance/employee-and-group-welfare/small-industries-insurance',
              },
            ],
          },
          {
            title: 'Asset Protection Insurance',
            path: '/products/corporate-insurance/asset-insurance',
            // component: AssetProtectionInsurance,
            submenu: [
              {
                title: 'Burglary Insurance',
                path: '/products/corporate-insurance/asset-insurance/burglary-insurance',
              },
              {
                title: 'Standard Fire and Special Perils Insurance',
                path: '/products/corporate-insurance/asset-insurance/standard-fire-and-special-perils-insurance',
              },
              {
                title: 'Marine Cargo Insurance',
                path: '/products/corporate-insurance/asset-insurance/marine-cargo-insurance',
              },
              {
                title: 'Contractor’s All Risk Insurance',
                path: '/products/corporate-insurance/asset-insurance/contractors-all-risks-insurance-car',
              },
              {
                title: 'Engineers All Risk Insurance',
                path: '/products/corporate-insurance/asset-insurance/engineering-all-risk-insurance-ear',
              },
              {
                title: 'Machinery Breakdown Insurance',
                path: '/products/corporate-insurance/asset-insurance/machinery-breakdown-insurance',
              },
              {
                title: 'Electronic Equipment Insurance',
                path: '/products/corporate-insurance/asset-insurance/electronic-equipment-insurance',
              },
              //   { title: 'Contractor Plant and Machinery Insurance', path: '/Corporate-Insurance-Products/Asset-Insurance/Contractor-Plant-and-Machinery-Insurance'
              //  },
            ],
          },
          {
            title: 'Data & Financial Security Insurance',
            path: '/products/corporate-insurance/data-and-financial-security-insurance',
            // component: DataAndFincinalInsurance,
            submenu: [
              {
                title: 'Bankers Indemnity Insurance',
                path: '/products/corporate-insurance/data-and-financial-security-insurance/bankers-indemnity-insurance',
              },
              {
                title: 'Fidelity Guarantee Insurance',
                path: '/products/corporate-insurance/data-and-financial-security-insurance/fidelity-guarantee-insurance-fgi',
              },
              {
                title: 'Money Insurance',
                path: '/products/corporate-insurance/data-and-financial-security-insurance/money-insurance-policy',
              },
              {
                title: 'Cyber Attack Insurance',
                path: '/products/corporate-insurance/data-and-financial-security-insurance/cyber-security-insurance',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Learn',
    path: '/blog',
    component: BlogData,
  },
  {
    title: 'Work With Us',
    path: '/work-with-us',
    component: WorkWithUs,
  },
  {
    title: 'Contact Us',
    path: '/contact-us',
    component: ContactUs,
  },
];


// seo with short urls
//   {
//     title: 'Products',
//     path: '/products',
//     component: Products,
//     submenu: [
//       {
//         title: 'Individual Insurance',
//         path: '/products/individual', //Removed "individual-insurance-products".
//         component: IndividualInsurance,
//         submenu: [
//           {
//             title: 'Health Insurance',
//             path: '/products/individual/health', //Removed "insurance" from the sub-path.
//             submenu: [
//               {
//                 title: 'Self Health Plan',
//                 path: '/products/individual/health/self-plan',
//               }, //"self-health-plan" for brevity.
//               {
//                 title: 'Family Floater Plan',
//                 path: '/products/individual/health/family-plan',
//               }, 
//             ],
//           },
//           {
//             title: 'Life Insurance',
//             path: '/products/individual/life', //Removed "insurance" to simplify the path.
//             submenu: [
//               { title: 'Term Life', path: '/products/individual/life/term' }, //"term-life" reduced to "term".
//               {
//                 title: 'Investment Cover',
//                 path: '/products/individual/life/investment',
//               }, //"investment-related-cover" to "investment".
//             ],
//           },
//           {
//             title: 'Motor Insurance',
//             path: '/products/individual/motor', //Removed "insurance" for clarity.
//             submenu: [
//               { title: 'Private Car', path: '/products/individual/motor/car' }, //"private-car-insurance" to "car".
//               {
//                 title: 'Two-Wheeler',
//                 path: '/products/individual/motor/two-wheeler',
//               }, //Retains key context for "two-wheeler".
//             ],
//           },
//           {
//             title: 'Property Insurance',
//             path: '/products/individual/property', //Simplified "property-insurance" to "property".
//             submenu: [
//               {
//                 title: 'Home Insurance',
//                 path: '/products/individual/property/home',
//               }, //"home-insurance" to "home".
//             ],
//           },
//         ],
//       },
//       {
//         title: 'Corporate Insurance',
//         path: '/products/corporate', //"corporate-insurance-products" to "corporate".
//         component: Corporate,
//         submenu: [
//           {
//             title: 'Employee & Group Welfare',
//             path: '/products/corporate/group', //"group-and-employee-welfare" to "group".
//             submenu: [
//               { title: 'Group Term', path: '/products/corporate/group/term' }, //Removed redundant "insurance".
//               {
//                 title: 'Mediclaim',
//                 path: '/products/corporate/group/mediclaim',
//               },
//               {
//                 title: 'D&O Liability',
//                 path: '/products/corporate/group/d&o-liability',
//               }, //"directors-officers-liability" to "d&o-liability".
//               {
//                 title: 'Personal Accident',
//                 path: '/products/corporate/group/accident',
//               }, //Removed "group-personal-accident".
//               {
//                 title: 'Workers Compensation',
//                 path: '/products/corporate/group/workers-comp',
//               }, //"compensation-insurance" to "comp".
//               {
//                 title: 'Public Liability',
//                 path: '/products/corporate/group/public-liability',
//               },
//               {
//                 title: 'Professional Indemnity',
//                 path: '/products/corporate/group/professional-indemnity',
//               }, 
//               {
//                 title: 'Product Liability',
//                 path: '/products/corporate/group/product-liability',
//               }, //"product-liability-insurance" to "product-liability".
//               {
//                 title: 'Commercial Liability',
//                 path: '/products/corporate/group/commercial-liability',
//               },
//               {
//                 title: 'Small Industrial',
//                 path: '/products/corporate/group/small-industrial',
//               }, //Removed "insurance".
//             ],
//           },
//           {
//             title: 'Asset Protection Insurance',
//             path: '/products/corporate/asset', //"asset-protection-insurance" to "asset".
//             submenu: [
//               { title: 'Burglary', path: '/products/corporate/asset' },
//               {
//                 title: 'Fire & Perils',
//                 path: '/products/corporate/asset/fire-perils',
//               }, //Removed "standard-fire-and-special".
//               {
//                 title: 'Marine Cargo',
//                 path: '/products/corporate/asset/marine',
//               }, //"marine-cargo-insurance" to "marine".
//               {
//                 title: 'Contractor’s Risk',
//                 path: '/products/corporate/asset/contractors-risk',
//               }, //Simplified "contractors-all-risk".
//               {
//                 title: 'Engineers Risk',
//                 path: '/products/corporate/asset/engineers-risk',
//               }, //Simplified "engineers-all-risk".
//               {
//                 title: 'Machinery Breakdown',
//                 path: '/products/corporate/asset/machinery',
//               },
//               {
//                 title: 'Electronic Equipment',
//                 path: '/products/corporate/asset/electronics',
//               }, //Simplified "electronic-equipment-insurance".
//             ],
//           },
//           {
//             title: 'Data & Financial Security',
//             path: '/products/corporate/data-security', //Removed "cyber-and-data-security".
//             submenu: [
//               {
//                 title: 'Bankers Indemnity',
//                 path: '/products/corporate/data-security/bankers',
//               }, //Simplified "bankers-indemnity-insurance".
//               {
//                 title: 'Fidelity Guarantee',
//                 path: '/products/corporate/data-security/fidelity',
//               }, //Removed "insurance".
//               {
//                 title: 'Money Insurance',
//                 path: '/products/corporate/data-security/money',
//               },
//               {
//                 title: 'Cyber Attack',
//                 path: '/products/corporate/data-security/cyber-attack',
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Learn',
//     path: '/blog',
//     component: BlogData,
//   },
//   {
//     title: 'Work With Us',
//     path: '/work-with-us', // Removed "careers" or extra words.
//     component: WorkWithUs,
//   },
//   {
//     title: 'Contact Us',
//     path: '/contact-us',
//     component: ContactUs,
//   },
// ];

