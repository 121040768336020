import { useState } from "react";
import { PopupHeader, PopupMessage, PopupButton, PopupContainer, ButtonContainer, CloseIcon } from "./styles"
import CloseImg from '../../../../assets/brand-new-car//close-btn.svg';
export const WarningPopup = ({ addOnsList, closeAddOnsPopup, updateaAddons, handleLoading }) => {
    const [addOnsData, setAddOnsData] = useState(addOnsList)
    const closePopup = () => {
        closeAddOnsPopup(false);
        handleLoading(false)
    };
    const handleProposal = () => {
        updateaAddons(addOnsData);
        closeAddOnsPopup(false)
    }

    const updateActiveAddons = (e, item, index) => {
        console.log("item are", item, index);
        const newArr = [...addOnsData];
        const newItem = {...item, status: !item.status}
        newArr[index] = newItem;
        setAddOnsData(newArr);
    }

    return (
        <PopupContainer>
            <CloseIcon src={CloseImg} onClick={closePopup}/>
            <PopupHeader>Previous Add Ons Confirmation!</PopupHeader>
            <PopupMessage>
                <div className="grid mt-1">
                    {
                        addOnsData.length > 0 && addOnsData.map((item, index) => (
                            <div>
                                <div className="flex">
                                    <input
                                        type='checkbox'
                                        id={item._id}
                                        checked={item?.status ? true : false}
                                    onChange={(e) => updateActiveAddons(e, item, index)}
                                    />
                                    <label for='engine'>{item.name}</label>
                                </div>

                            </div>
                        ))
                    }
                </div>
            </PopupMessage>
            <ButtonContainer>
                <PopupButton onClick={handleProposal}>CONTINUE</PopupButton>
            </ButtonContainer>

        </PopupContainer>
    );
};