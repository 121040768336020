  import React, { useState, useEffect } from 'react';
  import CustomerInfoSection from '../../components/saibaSection/CustomerInfoSection/CustomerInfoSection';
  import PolicyDetailsSection from '../../components/saibaSection/PolicyDetailsSection/PolicyDetailsSection';
  import './PolicyForm.css';
  import PolicyParticularDetails from '../../components/saibaSection/PolicyParticularDetails/PolicyParticularDetails';
  import PaymentModeSection from '../../components/saibaSection/paymentModeSection/PaymentModeSection';
  import ServicingDetailsSection from '../../components/saibaSection/ServicingDetails/servicingDetails';
  import BrokerageDetailsSection from '../../components/saibaSection/BrokerDetailsSection/brokrageDetailsSection';
  import PartnerBrokerSection from '../../components/saibaSection/PartnerBrokerSection/PartnerBrokerSection';
  import CoBrokerSection from '../../components/saibaSection/CoBrokerSection/CoBrockerSection';
  import CampaignPolicyStatusSection from '../../components/saibaSection/CampaignPolicyStatusSection/CampaignPolicyStatusSection';
  import MotorPolicyDetails from '../../components/saibaSection/motorPolicyDetails/MotorPolicyDetails';
  import axios from 'axios';
  import { useNavigate } from 'react-router-dom';
  import { v4 as uuid } from 'uuid';
  import { v4 as uuidv4 } from 'uuid';
  import {
    apiGetCall,
    apiPostCall,
  } from '../../utilities/site-apis';

  const PolicyForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      controlNo: '',
      enquiryCode: '',
      customerID: '',
      bizType: 'new',
      customerName: '',
      email: '',
      phone: '',
      policyHolderName: '',
      insuredName: '',
      nomineeDetailsFullName: '',
      nomineeDetailsDob: '',
      nomineeDetailsRelation: '',
      insurerCity: '',
      insuredDOB: '',
      insurerBranch: '',
      ownerDetails: {
        fullName: '',
        email: '',
        mobileNo: '',
        address: '',
        registrationAddressSame: true,
        registrationAddress: '',
        maritalStatus: '',
        gender: '',
        dob: '',
        state: '',
        city: '',
        pincode: '',
        aadharNumber: '',
        isVehicleFinanced: 'No',
        financier: '',
        panNumber: '',
        tokenIdKotak: '',
        aadharImage: '',
        district: '',
        street: '',
        streetNumber: '',
      },

      insurance: {
        insurancePolicyName: '',
        insurancePolicyNumber: '',
        insurancePolicyExpiryDate: '',
        insurancePolicyType: '',
        isClaimMadeinLastYear: false,
        isPolicyExpired: false,
        tpStartDate: '',
        tpEndDate: '',
        tpInsurerName: '',
        tpPolicyNumber: '',
      },

      // insurer name same as customer name
      // part II
      // insurerCity: '',
      // insurerBranch: '',
      busBookDate: '',
      startDate: '',
      department: '',
      days: '',
      endDate: '',
      policyType: '',
      isRenewable: 'Yes',
      policyNo: '',
      hypothecation: 'No',
      projectName: '',
      policyExcess: '',
      insuredLocation: '',
      policyRemarks: '',

      //  part III
      sNo: 1,
      coverage: '',
      extensions: '',
      sumInsured: 0,
      premRate: 0,
      netOdPrem: 0,
      locationRemarks: '',
      gstTax: 0,
      multiInstPolicy: 'No',
      tpSumInsured: 0,
      tpPremium: 0,
      rsaPremium: 0,
      totalSumInsured: 0,
      brokPremium: 0,
      ownerDriverPremium: 0,
      netPremium: 0,
      gstSTax: 0,
      stampDuty: 0,
      grossPremium: 0,
      oddDtd: 0,

      // part IV
      // Payment details
      paymentMode: '',
      amount: '',
      bank: '',
      chequeNumber: '',
      dated: '',
      bankBranch: '',
      payModeRemark: '',

      // part V
      posMispRef: 'Ref',
      posMispRefCode: '',
      posMispRefName: '',
      posId: '',
      cscPeCode: '',
      cscPeName: '',
      rmCe1Code: '',
      rmCe1Name: '',
      solicitorCe2Code: '',
      solicitorCe2Name: '',
      tcCode: '',
      tcName: '',

      // part VI
      brokerageType: 'Brokerage',
      brokerageRemarks: '',
      gstSTax: '',
      brokerageRate: '',
      brokerageAmount: 0,
      tpBrokerageRate: 0,
      tpBrokerageAmount: 0,
      rewardOn: 'Brokerage',
      rewardAmount: 0,
      rewardPercent: 0,
      tpRewardPercent: 0,
      tpAmount: 0,
      exp: 0,
      expTP: 0,
      refODPrem: 0,
      refTP: 0,
      csc: 0,
      refPosAt: 'NP',

      // part VII
      partnerBrokerName: '',
      partnerBrokerCode: '',
      network: '',
      crossReferral: '',

      // part VIII
      coBrokerName: '',
      coBrokerBranchCode: '',
      coBrokerShare: '',

      // part IX
      campaignName: '',
      status: 'Logged in',
      policyStatusRemarks: '',

      // Motor Policy Details
      cvrNoteDate: '',
      mtrPolicyStatus: '',
      regNo: '',
      typeOfVehicle: '',
      category: '',
      gvw: '',
      noOfPassenger: '',
      engineNo: '',
      vehicleClass: '',
      makeId: '',
      modelId: '',
      model: '',
      make: '',
      chasisNo: '',
      cc: '',
      yearOfManufacturing: '',
      dateOfReg: '',
      invoiceDate: '',
      seats: '',
      fuel: '',
      rtoState: [],
      rto: '',
      zone: '',
      ncb: '',
      odd: '',
      refNo: '',
      wheels: '',
      color: '',
      vehUser: '',
      address: '',
      inspectionAgency: '',
      roadType: '',
      transmissionType: '',
      withoutNilDep: '',
      selectedMake: '',
      selectedModel: '',
    });

    const [addedParticularDetails, setAddedParticularDetails] = useState([]);
    const [addedParticularDetailsFiltered, setAddedParticularDetailsFiltered] =
      useState(null);

    const [coBrokers, setCoBrokers] = useState([]);
    const [isCoBrokerChecked, setIsCoBrokerChecked] = useState(false);
    const [isCheckedPartnerBroker, setIsCheckedPartnerBroker] = useState(false);

    const handleAddParticularDetail = (newData, finalPolicyData) => {
      setAddedParticularDetails([...addedParticularDetails, newData]);
      setAddedParticularDetailsFiltered(finalPolicyData);
    };

    const handleDeleteParticularDetail = (indexToDelete) => {
      const filteredData = addedParticularDetails.filter(
        (_, index) => index !== indexToDelete
      );
      setAddedParticularDetails(filteredData);
    };

    // Helper function to generate a 4-digit unique number
    function generateUniqueFourDigitNumber() {
      const uuidData = uuidv4();
      const hash = parseInt(uuidData.replace(/-/g, '').slice(0, 8), 16);
      return (hash % 9000) + 1000;
    }

    // Initialize enquiryCode on first load
    useEffect(() => {
      const uniqueNumber = generateUniqueFourDigitNumber();

      const savedControlNo = localStorage.getItem('controlNo');
      const newControlNo = savedControlNo || uniqueNumber;

      const savedEnquiryCode = localStorage.getItem('enquiryCode');
      const newCode = savedEnquiryCode || uuid();
      setFormData((prevState) => ({
        ...prevState,
        enquiryCode: newCode,
        controlNo: newControlNo,
      }));
      if (!savedEnquiryCode) {
        localStorage.setItem('enquiryCode', newCode);
        localStorage.setItem('controlNo', newControlNo);
      }
    }, []);

    const handleChange = (e) => {
      const { name, value } = e.target || e;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      // console.log('Submitting form datatatat...', formData);

      // Create a fullFormData object matching the schema
      const fullFormData = {
        controlNo: formData.controlNo,
        enquiryId: formData.enquiryCode,
        userId: formData.posId,
        type: formData.bizType,
        customerID: formData.customerID,
        source : "simpliDashboard",
        status: 'quotationGenerated',
        custCode: formData.custCode,
        customerName: formData.customerName,
        email: formData.email,
        phone: formData.phone,
        insuredName: formData.insuredName,
        insuredDOB: formData.insuredDOB,
        city: formData.insurerCity,

        price: addedParticularDetailsFiltered.netPremium,
        gst: addedParticularDetailsFiltered.gstTax,
        total: addedParticularDetailsFiltered.grossPremium,

        quickQuote: {
          price: addedParticularDetailsFiltered?.grossPremium,
          policyStartDate: formData?.startDate,
          policyEndDate: formData?.endDate,
        },
        policyNumber: formData.policyNo,

        // policy type
        newPolicyData: {
          policyType: formData.policyType,
        },

        nomineeDetails: {
          fullName: formData.nomineeDetailsFullName,
          dob: formData.nomineeDetailsDob,
          relation: formData.nomineeDetailsRelation,
        },
        ownerDetails: {
          fullName: formData.customerName,
          email: formData.email,
          mobileNo: formData.phone,
          address: formData.ownerDetails.address,
          registrationAddressSame:
            formData.ownerDetails.registrationAddressSame,
          registrationAddress: formData.ownerDetails.registrationAddress,
          maritalStatus: formData.ownerDetails.maritalStatus,
        },
        insurerName: formData.insurerName,
        insurerCity: formData.insurerCity,
        insurerBranch: formData.insurerBranch,
        policyHolderName: formData.customerName,
        insuranceType: formData.department,
        policyDetails: {
          busBookDate: formData.busBookDate,
          startDate: formData.startDate,
          department: formData.department,
          days: formData.days,
          endDate: formData.endDate,
          policyType: formData.policyType,
          isRenewable: formData.isRenewable,
          policyNo: formData.policyNo,
          hypothecation: formData.hypothecation,
          projectName: formData.projectName,
          policyExcess: formData.policyExcess,
          insuredLocation: formData.insuredLocation,
          policyRemarks: formData.policyRemarks,
        },

        insurance: {
          insurancePolicyName: formData.policyType,
          insurancePolicyNumber: formData.policyNo,
          insurancePolicyExpiryDate: formData.endDate,
          insurancePolicyType: '',
          isClaimMadeinLastYear: formData.isClaimMadeinLastYear,
          isPolicyExpired: formData.isPolicyExpired,
          tpStartDate: '',
          tpEndDate: '',
          tpInsurerName: '',
          tpPolicyNumber: '',
        },

        savePayment: {
          paymentMode: formData.paymentMode,
          amount:
            formData.amount || addedParticularDetailsFiltered.grossPremium,
          bank: formData.bank,
          chequeNumber: formData.chequeNumber,
          dated: formData.dated,
          bankBranch: formData.bankBranch,
          payModeRemark: formData.payModeRemark,
        },
        paymentMode: formData.paymentMode,

        // motorPolicyDetails:
        cvrNoteDate: formData.cvrNoteDate,
        mtrPolicyStatus: formData.mtrPolicyStatus,
        regNo: formData.regNo,
        regDate: formData.regDate,
        typeOfVehicle: formData.typeOfVehicle,
        category: formData.category,
        noOfSeats: formData.noOfPassenger,
        engineNo: formData.engineNo,
        vehicleClass: formData.vehicleClass,
        makeId: formData.makeId,
        modelId: formData.modelId,
        make: formData.make,
        model: formData.model,
        modelVariant: formData.variant,
        variant: formData.variant,
        chasisNo: formData.chasisNo,
        cc: formData.cc,
        yearOfManufacturing: formData.yearOfManufacturing,
        dateOfReg: formData.dateOfReg,
        invoiceDate: formData.invoiceDate,
        seats: formData.seats,
        fuelType: formData.fuel,
        rtoState: formData.rtoState,
        rto: formData.rto,
        zone: formData.zone,
        ncb: formData.ncb,
        odd: formData.odd,
        wheels: formData.wheels,
        color: formData.color,
        inspectionAgency: formData.inspectionAgency,
        roadType: formData.roadType,
        transmissionType: formData.transmissionType,
        withoutNilDep: formData.withoutNilDep,
        // },
        raw: {
          //  from  motor
          refNo: formData.refNo,
          vehUser: formData.vehUser,
          address: formData.address,
          gvw: formData.gvw,
          noOfPassenger: formData.noOfPassenger,
          posDetails: {
            posRef: formData.posMispRef,
            posCode: formData.posMispRefCode,
            posName: formData.posMispRefName,
            posId: formData.posId,
          },
          addedParticularDetailsFiltered: addedParticularDetailsFiltered,
          addedParticularDetails: addedParticularDetails.map((detail) => ({
            sNo: detail.sNo + 1,
            coverage: detail.coverage,
            extensions: detail.extensions,
            sumInsured: detail.sumInsured,
            premRate: detail.premRate,
            netOdPrem: detail.netOdPrem,
            locationRemarks: detail.locationRemarks,
            gstTax: detail.gstTax,
            multiInstPolicy: detail.multiInstPolicy,
            tpSumInsured: detail.tpSumInsured,
            tpPremium: detail.tpPremium,
            rsaPremium: detail.rsaPremium,
            totalSumInsured: detail.totalSumInsured,
            brokPremium: detail.brokPremium,
            ownerDriverPremium: detail.ownerDriverPremium,
            netPremium: detail.netPremium,
            gstSTax: detail.gstSTax,
            stampDuty: detail.stampDuty,
            grossPremium: detail.grossPremium,
            oddDtd: detail.oddDtd,
          })),

          quickQuote: {
            price: addedParticularDetailsFiltered?.grossPremium,
            policyStartDate: formData?.startDate,
            policyEndDate: formData?.endDate,
          },

          brokerageDetails: {
            brokerageType: formData.brokerageType,
            brokerageRemarks: formData.brokerageRemarks,
            gstSTax: formData.gstSTax,
            brokerageRate: formData.brokerageRate,
            brokerageAmount: formData.brokerageAmount,
            tpBrokerageRate: formData.tpBrokerageRate,
            tpBrokerageAmount: formData.tpBrokerageAmount,
            rewardOn: formData.rewardOn,
            rewardAmount: formData.rewardAmount,
            rewardPercent: formData.rewardPercent,
            tpRewardPercent: formData.tpRewardPercent,
            tpAmount: formData.tpAmount,
          },

          partnerBrokerDetails: {
            partnerBrokerName: formData?.partnerBrokerName,
            partnerBrokerCode: formData?.partnerBrokerCode,
            network: formData?.network,
            crossReferral: formData?.crossReferral,
          },

          coBrokers: coBrokers?.map((broker) => ({
            coBrokerName: broker?.coBrokerName,
            coBrokerBranchCode: broker.coBrokerName === 'self' ? 'NA' : 'NA',
            coBrokerShare: broker?.coBrokerShare,
          })),

          campaigningDetails: {
            campaignName: formData.campaignName,
            policyStatus: formData.policyStatus,
            policyStatusRemarks: formData.policyStatusRemarks,
          },
        },
      };

      try {
        const response = await apiPostCall('new-policy', fullFormData);

        const enquiryIdData = response?.data?.data?.motorForm?.enquiryId ? response?.data?.data?.motorForm?.enquiryId : response?.data?.data?.bikeForm?.enquiryId;

        if (enquiryIdData) {
          console.log('Form successfully submitted');

          // Remove items from localStorage
          try {
            localStorage.removeItem('enquiryCode');
            localStorage.removeItem('controlNo');
            console.log('LocalStorage cleared');
          } catch (removeError) {
            console.error('Error clearing localStorage:', removeError);
            alert(`I - Error While Submitting Form: ${removeError.message}`);
          }

          // Navigate to the next page
          navigate(`/policyupload/${enquiryIdData}`);
        } else {
          console.error('Error submitting form', response);
          alert(`${response.data.data.name}: ${response.data.data.message}`);
        }
      } catch (error) {
        console.error('Error submitting form', error.data.data.message);
        alert(`Error While Submitting Form: ${error}`);
      }
    };

    // for Partner Broker Section
    const handleCheckboxChangePartnerBroker = () => {
      setIsCheckedPartnerBroker(!isCheckedPartnerBroker);
    };

    // for co Workers
    const handleCheckboxChange = () => {
      setIsCoBrokerChecked(!isCoBrokerChecked);
    };

    const handleAddCoBroker = () => {
      setCoBrokers([
        ...coBrokers,
        {
          coBrokerName: formData.coBrokerName,
          coBrokerBranchCode: formData.coBrokerBranchCode,
          coBrokerShare: formData.coBrokerShare,
          sno: coBrokers.length + 1,
        },
      ]);

      // Reset the Co-Broker fields in the form
      setFormData({
        ...formData,
        coBrokerName: '',
        coBrokerBranchCode: '',
        coBrokerShare: '',
      });
    };

    const handleRemoveCoBroker = (indexToDelete) => {
      setCoBrokers(coBrokers.filter((_, index) => index !== indexToDelete));
    };

    const handleExit = () => {
      setFormData({}); // Clears the form
      console.log('Exiting form...');
    };

    return (
      <div>
        <form onSubmit={handleSubmit} className="policy-form">
          <p className="brokerPolicyHeading">Non-Life Standard Policy Entry </p>
          <CustomerInfoSection
            formData={formData}
            handleChange={handleChange}
          />
          <PolicyDetailsSection
            formData={formData}
            handleChange={handleChange}
          />

          {formData.department === 'motor' || formData.department === 'bike' ? (
            <MotorPolicyDetails
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
            />
          ) : null}
          {/* Policy Particular Details Section */}
          <PolicyParticularDetails
            formData={formData}
            handleAddData={handleAddParticularDetail}
            addedData={addedParticularDetails}
            handleDelete={handleDeleteParticularDetail}
          />

          <PaymentModeSection formData={formData} handleChange={handleChange} />
          <ServicingDetailsSection
            formData={formData}
            handleChange={handleChange}
          />
          <BrokerageDetailsSection
            formData={formData}
            handleChange={handleChange}
          />
          <PartnerBrokerSection
            formData={formData}
            handleChange={handleChange}
            handleCheckboxChange={handleCheckboxChangePartnerBroker}
            isChecked={isCheckedPartnerBroker}
          />
          <CoBrokerSection
            formData={formData}
            handleChange={handleChange}
            handleAddCoBroker={handleAddCoBroker}
            handleRemoveCoBroker={handleRemoveCoBroker}
            coBrokers={coBrokers}
            handleCheckboxChange={handleCheckboxChange}
            isChecked={isCoBrokerChecked}
          />
          <CampaignPolicyStatusSection
            formData={formData}
            handleChange={handleChange}
          />

          <div className="saveExitBtnGrp">
            <button type="submit" className="submit-btn-policy-broker">
              Save
            </button>
            <button
              type="button"
              onClick={handleExit}
              className="exit-btn-policy-broker"
            >
              Exit
            </button>
          </div>
        </form>
      </div>
    );
  };

  export default PolicyForm;
