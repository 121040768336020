import React, {useState, useEffect} from 'react';
import './MotorPolicyDetails.css';
import { apiGETCall1, apiPostCall } from '../../../utilities/site-apis';
import axios from 'axios';

const MotorPolicyDetails = ({ formData, setFormData,  handleChange }) => {
  const [rtoStateData, setRtoStateData] = useState([]);
  const [rtoCodeData, setRtoCodeData] = useState('');

  const [makeData, setMakeData] = useState([]);
  const [modelData, setModelData] = useState([]);

  // Fetch makes on component mount
  useEffect(() => {
    if (formData.department === 'bike') {
      fetchBikeMakes();
    } else {
      fetchMakes();
    }
  }, [formData.department]);


  // for Manufacturing Year
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 30 }, (_, index) => currentYear - index);



  const fetchRtoState = async () => {
    try {
      const response = await axios.get( `${process.env.REACT_APP_API_URL}/vehicleInsurance/rtoCity/list`);
      if (response && response.data) {
          const rtoFinalData = Array.from(
            new Set(response?.data?.data.map((item) => item.stateName))
          );
          setRtoStateData(rtoFinalData);
      } else {
        console.error('Unexpected response structure for rtoState:', response);

      }
    } catch (error) {
      console.error('Error fetching rtoState:', error);
    }
  }

  // const fetchRtoCode = async () => {
  //   try {
  //       console.log('formData.rtoStatessstyyyyy', formData.rtoState);
  //     const response = await axios.get( `${process.env.REACT_APP_API_URL}/vehicleInsurance/rtoCity/list?stateName=${formData?.rtoState}`);
  //     if (response && response.data) {
  //        setRtoCodeData(response?.data?.data);
  //     } else {
  //       console.error('Unexpected response structure for rtoState:', response);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching rtoState:', error);
  //   }
  // }
  const fetchRtoCode = async () => {
    try {
      console.log('Selected State:', formData.rtoState);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/vehicleInsurance/rtoCity/list?stateName=${formData?.rtoState}`
      );
      if (response && response.data) {
        // Ensure only codes for the selected state are set
        const filteredData = response.data.data.filter(
          (item) => item.stateName === formData.rtoState
        );
        setRtoCodeData(filteredData);
      } else {
        console.error('Unexpected response structure for rtoCode:', response);
      }
    } catch (error) {
      console.error('Error fetching rtoCode:', error);
    }
  };


  

  useEffect (() => {
    fetchRtoCode();
  }, [formData.rtoState]);


  useEffect(() => {
    fetchRtoState();
  }, []);

  // fetch bike make and model
    const fetchBikeMakes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/master/bike/list`
        );

        // Assuming response.data contains an array of makes
        if (response && response.data) {
          setMakeData(response?.data?.data?.data);
          // setFormData((prevData) => ({
          //   ...prevData,
          //   make: response.data.data, // 'makes' contains the list of vehicle makes
          // }));
        } else {
          console.error('Unexpected response structure for makes:', response);
        }
      } catch (error) {
        console.error('Error fetching makes:', error);
      }
    };

    // Fetch models based on selected makeId
    const fetchBikeModels = async (model) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/master/bike/list?make=${model}`
        );

        // Assuming response.data contains an array of models
        if (response && response.data) {
          setModelData(response.data.data?.data);
          // setFormData((prevData) => ({
          //   ...prevData,
          //   model: response.data.data,
          // }));
        } else {
          console.error('Unexpected response structure for models:', response);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };


  // Fetch makes from API
  const fetchMakes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/vehicleInsurance/make/list`,
      );

      // Assuming response.data contains an array of makes
      if (response && response.data) {
        setMakeData(response.data.data);
        // setFormData((prevData) => ({
        //   ...prevData,
        //   make: response.data.data, // 'makes' contains the list of vehicle makes
        // }));
      } else {
        console.error('Unexpected response structure for makes:', response);
      }
    } catch (error) {
      console.error('Error fetching makes:', error);
    }
  };

  // Fetch models based on selected makeId
  const fetchModels = async (makeId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/vehicleInsurance/makeModel/list?makeId=${makeId}`,
      );

      // Assuming response.data contains an array of models
      if (response && response.data) {
        setModelData(response.data.data);
        // setFormData((prevData) => ({
        //   ...prevData,
        //   model: response.data.data,
        // }));
      } else {
        console.error('Unexpected response structure for models:', response);
      }
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  // Handle make selection change
  // const handleMakeChange = (e) => {
  //   const makeId = e.target.value;
  //   console.log('Selected Make ID:', makeId);

  //   handleChange(e); // Update the form data for "make"

  //   if (makeId) {
  //     fetchModels(makeId); // Fetch models for the selected make
  //   }
  // };

  return (
    <div className="motor-policy-details">
      <h2>Motor Policy Details</h2>
      <div className="motor-policy-grid">
        <div className="form-group required">
          <label htmlFor="cvrNoteDate">
            Cvr Note Rcv. Date<span className="required-strick">*</span>
          </label>
          <input
            type="date"
            id="cvrNoteDate"
            name="cvrNoteDate"
            value={formData.cvrNoteDate || ''}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group required">
          <label htmlFor="mtrPolicyStatus">
            Policy Status<span className="required-strick">*</span>
          </label>
          <select
            id="mtrPolicyStatus"
            name="mtrPolicyStatus"
            value={formData.mtrPolicyStatus || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="N">N</option>
            <option value="R">R</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="regNo">
            Reg. No.<span className="required-strick">*</span>
          </label>
          <input
            type="text"
            id="regNo"
            name="regNo"
            value={formData.regNo || ''}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group required">
          <label htmlFor="typeOfVehicle">
            Type of Vehicle<span className="required-strick">*</span>
          </label>
          <select
            id="typeOfVehicle"
            name="typeOfVehicle"
            value={formData.typeOfVehicle || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Private">Private</option>
            <option value="Commercial">Commercial</option>
          </select>
        </div>

        {formData.typeOfVehicle === 'Commercial' && (
          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              name="category"
              value={formData.category || ''}
              onChange={handleChange}
            >
              <option value="">--Select--</option>
              <option value="PCV">PCV</option>
              <option value="GCV">GCV</option>
              <option value="Misc.">Misc.</option>
            </select>
          </div>
        )}

        {formData.typeOfVehicle === 'Commercial' && (
          <>
            {formData.category === 'PCV' ? (
              <div className="form-group">
                <label htmlFor="noOfPassenger">No. of Passenger</label>
                <input
                  type="number"
                  id="noOfPassenger"
                  name="noOfPassenger"
                  value={formData.noOfPassenger || ''}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <div className="form-group">
                <label htmlFor="gvw">GVW (Ton or Kg.)</label>
                <input
                  type="text"
                  id="gvw"
                  name="gvw"
                  value={formData.gvw || ''}
                  onChange={handleChange}
                />
              </div>
            )}
          </>
        )}

        <div className="form-group">
          <label htmlFor="engineNo">Engine No.</label>
          <input
            type="text"
            id="engineNo"
            name="engineNo"
            value={formData.engineNo || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="vehicleClass">Vehicle Class</label>
          <select
            id="vehicleClass"
            name="vehicleClass"
            value={formData.vehicleClass || ''}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="all">ALL</option>
            <option value="goodsCarrying">Goods Carrying Vehicles</option>
            <option value="miscellaneous">Miscellaneous</option>
            <option value="passengerCarrying">
              Passenger Carrying Vehicles
            </option>
            <option value="privateCar">Private Car</option>
            <option value="twoWheeler">Two Wheeler</option>
          </select>
        </div>

        {/* <div className="form-group required">
          <label htmlFor="make">Make</label>
          <select
            id="make"
            name="make"
            value={formData.make || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Honda">Honda</option>
            <option value="Toyota">Toyota</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="model">Model</label>
          <select
            id="model"
            name="model"
            value={formData.model || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Accord">Accord</option>
            <option value="Civic">Civic</option>
          </select>
        </div> */}
        {formData.department === 'bike' && (
          <>
            <div className="form-group required">
              <label htmlFor="make">
                Make<span className="required-strick">*</span>
              </label>
              <select
                onChange={(e) => {
                  handleChange(e);

                  const selectedMake = JSON.parse(e.target.value);
                  const selectedMakeName = selectedMake.name;

                  fetchBikeModels(selectedMakeName);
                  setFormData((prevData) => ({
                    ...prevData,
                    make: selectedMakeName,
                  }));
                }}
                required
              >
                <option value="">Select a make</option>
                {makeData &&
                  [...new Set(makeData.map((make) => make?.Make))].map(
                    (makeName) => {
                      const selectedMake = makeData.find(
                        (m) => m.Make === makeName
                      );
                      return (
                        <option
                          key={selectedMake._id}
                          value={JSON.stringify({
                            _id: selectedMake._id,
                            name: makeName,
                          })}
                        >
                          {makeName}
                        </option>
                      );
                    }
                  )}
              </select>
            </div>

            <div className="form-group required">
              <label htmlFor="model">
                Model<span className="required-strick">*</span>
              </label>
              <select
                onChange={(e) => {
                  handleChange(e);
                  const selectedModel = JSON.parse(e.target.value);
                  const selectedModelName = selectedModel.name;
                  setFormData((prevData) => ({
                    ...prevData,
                    model: selectedModelName,
                  }));
                }}
                required
              >
                <option value="">--Select--</option>
                {/* Dynamically populate model options */}
                {modelData &&
                  [...new Set(modelData.map((model) => model?.Model))].map(
                    (modelName) => {
                      const selectedModel = modelData.find(
                        (m) => m.Model === modelName
                      );
                      return (
                        <option
                          key={selectedModel._id}
                          value={JSON.stringify({
                            _id: selectedModel._id,
                            name: modelName,
                          })}
                        >
                          {modelName}
                        </option>
                      );
                    }
                  )}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="variant">Variant</label>
              <select
                id="variant"
                name="variant"
                value={formData.variant || ''}
                onChange={(e) => {
                  handleChange(e);
                  const selectedModel = JSON.parse(e.target.value);
                  setFormData((prevData) => ({
                    ...prevData,
                    variant: selectedModel
                  }));
                }}
              >
                <option value="">--Select--</option>
                {formData.model &&
                  modelData
                    .filter((m) => m.Model === formData.model)
                    .map((m) => (
                      <option key={m.Variant} value={m.Variant}>
                        {m.Variant}
                      </option>
                    ))}
              </select>
            </div>
          </>
        )}

        {formData.department === 'motor' && (
          <>
            <div className="form-group required">
              <label htmlFor="make">
                Make<span className="required-strick">*</span>
              </label>
              <select
                onChange={(e) => {
                  handleChange(e);

                  const selectedMake = JSON.parse(e.target.value);
                  const selectedMakeId = selectedMake._id;
                  const selectedMakeName = selectedMake.name;

                  fetchModels(selectedMakeId);
                  setFormData((prevData) => ({
                    ...prevData,
                    makeId: selectedMakeId,
                    make: selectedMakeName,
                  }));
                }}
                required
              >
                <option value="">Select a make</option>
                {makeData.map((make) => (
                  <option
                    key={make._id}
                    value={JSON.stringify({ _id: make._id, name: make.name })}
                  >
                    {make.name} {/* Display name but value is _id */}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group required">
              <label htmlFor="model">
                Model<span className="required-strick">*</span>
              </label>
              <select
                onChange={(e) => {
                  handleChange(e);
                  const selectedModel = JSON.parse(e.target.value);
                  const selectedModelId = selectedModel._id;
                  const selectedModelName = selectedModel.name;
                  setFormData((prevData) => ({
                    ...prevData,
                    modelId: selectedModelId,
                    model: selectedModelName,
                  }));
                }}
                required
              >
                <option value="">--Select--</option>
                {/* Dynamically populate model options */}
                {modelData &&
                  modelData?.map((model) => (
                    <option
                      key={model._id}
                      value={JSON.stringify({
                        _id: model._id,
                        name: model.name,
                      })}
                    >
                      {model.modelName}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="variant">Variant</label>
              <input
                type="text"
                id="variant"
                name="variant"
                value={formData.variant || ''}
                onChange={handleChange}
              />
            </div>
          </>
        )}

        <div className="form-group">
          <label htmlFor="chasisNo">Chasis No.</label>
          <input
            type="text"
            id="chasisNo"
            name="chasisNo"
            value={formData.chasisNo || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="cc">CC</label>
          <input
            type="text"
            id="cc"
            name="cc"
            value={formData.cc || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="yearOfManufacturing">
            Year of Manufacturing<span className="required-strick">*</span>
          </label>
          <select
            id="yearOfManufacturing"
            name="yearOfManufacturing"
            value={formData.yearOfManufacturing || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="dateOfReg">Date of Reg.</label>
          <input
            type="date"
            id="dateOfReg"
            name="dateOfReg"
            value={formData.dateOfReg || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="invoiceDate">Invoice Date</label>
          <input
            type="date"
            id="invoiceDate"
            name="invoiceDate"
            value={formData.invoiceDate || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="seats">Seats</label>
          <input
            type="number"
            id="seats"
            name="seats"
            value={formData.seats || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="fuel">
            Fuel
            <span className="required-strick">*</span>
          </label>
          <select
            id="fuel"
            name="fuel"
            value={formData.fuel || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Petrol">Petrol</option>
            <option value="Diesel">Diesel</option>
            <option value="Diesel">CNG</option>
            <option value="Diesel">Electric</option>
            <option value="Diesel">LPG</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="rtoState">
            RTO State<span className="required-strick">*</span>
          </label>
          <select
            id="rtoState"
            name="rtoState"
            onChange={(e) => {
              handleChange(e);
              setFormData((prevData) => ({
                ...prevData,
                rtoState: e.target.value,
                rto: '',
              }));
            }}
            required
          >
            <option value="">--Select--</option>
            {rtoStateData?.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="rto">
            RTO<span className="required-strick">*</span>
          </label>
          <select
            id="rto"
            name="rto"
            onChange={(e) => {
              const selectedRto = e.target.value;
              handleChange(e);
              setFormData((prevData) => ({
                ...prevData,
                rto: selectedRto,
              }));
            }}
            required
          >
            <option value="">--Select--</option>

            {rtoCodeData &&
              rtoCodeData.map((code) => (
                <option key={code.code} value={code.code}>
                  {code.code} - {code.cityName}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="zone">
            Zone<span className="required-strick">*</span>
          </label>
          <select
            id="zone"
            name="zone"
            value={formData.zone || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="a">A</option>
            <option value="b">B</option>
            <option value="c">C</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="ncb">
            NCB(%)<span className="required-strick">*</span>
          </label>
          <input
            type="number"
            id="ncb"
            name="ncb"
            value={formData.ncb || ''}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="odd">
            ODD(%)<span className="required-strick">*</span>
          </label>
          <input
            type="number"
            id="odd"
            name="odd"
            value={formData.odd || ''}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="refNo">Ref No.</label>
          <input
            type="text"
            id="refNo"
            name="refNo"
            value={formData.refNo || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="wheels">Wheels</label>
          <input
            type="text"
            id="wheels"
            name="wheels"
            value={formData.wheels || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="inspectionAgency">Inspection Agency</label>
          <input
            type="text"
            id="inspectionAgency"
            name="inspectionAgency"
            value={formData.inspectionAgency || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="roadType">Road Type</label>
          <select
            id="roadType"
            name="roadType"
            value={formData.roadType || ''}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="NA">NA</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="color">Color</label>
          <input
            type="text"
            id="color"
            name="color"
            value={formData.color || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="vehUser">Veh.User/Allottee/Emp</label>
          <input
            type="text"
            id="vehUser"
            name="vehUser"
            value={formData.vehUser || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="transmissionType">Transmission Type</label>
          <select
            id="transmissionType"
            name="transmissionType"
            value={formData.transmissionType || ''}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="Manual">Manual</option>
            <option value="Automatic">Automatic</option>
          </select>
        </div>

        {formData.typeOfVehicle === 'Commercial' && (
          <div className="form-group">
            <label htmlFor="withoutNilDep">Without Nil Dep.</label>
            <select
              id="withoutNilDep"
              name="withoutNilDep"
              value={formData.withoutNilDep || 'YES'}
              onChange={handleChange}
            >
              <option value="YES">YES</option>
              <option value="NO">NO</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

export default MotorPolicyDetails;
