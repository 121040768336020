import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetCall, apiPostCall, apiPostCall1} from "../utilities/site-apis";
import  {axiosPOSTCall, axiosGETCall, }  from "../utilities/utils/apiPost";

const initialState = {
  isFetching: false,
  combinedRedux: {},
  lifeData: [],
  lifeQuote: [],
  success: false,
  error: false,
}

// main API call for the life insurance
export const lifeEnquiryAPI = createAsyncThunk(
  "lifeInsurance/lifeEnquiryAPI",
  async (params, { rejectWithValue }) => {
    // console.log("🚀 ~ file: CarsRedux.jsx ~ line 106 ~ params", params.type);
    const response = await apiGetCall(`/life/enquiry/` + params.enquiryId, params);
      console.log('response',response.data);
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    console.log("response.data ", response.data);
    return response.data;
  }
);

// let mainCurrentRequest = null;

// export const lifeEnquiryAPI = createAsyncThunk(
//   "lifeInsurance/lifeEnquiryAPI",
//   async (params, { rejectWithValue }) => {
//     // Abort the previous request if it's still pending
//     if (mainCurrentRequest) {
//       mainCurrentRequest.abort();
//     }

//     // Create a new AbortController for this request
//     mainCurrentRequest = new AbortController();
//     const { signal } = mainCurrentRequest;

//     try {
//       const response = await apiGetCall(`/life/enquiry/${params.enquiryId}`, { signal });
//       console.log('response', response.data);

//       if (response.data.status === "error") {
//         return rejectWithValue(response.data.data);
//       }

//       console.log("response.data ", response.data);
//       return response.data;

//     } catch (error) {
//       if (error.name === 'AbortError') {
//         return rejectWithValue({ message: "Request was canceled" });
//       } else {
//         return rejectWithValue(error.response ? error.response.data : error.message);
//       }
//     } finally {
//       mainCurrentRequest = null;
//     }
//   }
// );


export const updateCombinedModel = createAsyncThunk(
  "lifeReduxSlice/updateCombinedModel",
  async (params, { rejectWithValue }) => {
    return params;
  }
);



export const updateFirstPageData = createAsyncThunk(
  'lifeReduxSlice/updateFirstPageData',
  async (params, { rejectWithValue }) => {
    try {
      const response = await apiPostCall1('/life/enquiry', params);
      console.log({ response1: response });

      if (response.data.status === 'error') {
        return rejectWithValue(response.data.data);
      }

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || 'Something went wrong');
    }
  }
);


// For EBI PDF
export const lifeIciciIpsEbiPdf = createAsyncThunk(
  "lifeReduxSlice/lifeIciciIpsEbiPdf",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`lifeInsurance/life/ebi-pdf/life-icici`, params);
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);

export const lifeBajajBiPdf = createAsyncThunk(
  'lifeReduxSlice/lifeBajajBiPdf',
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(
      `lifeInsurance/life/ebi-pdf/bajaj-life`,
      params
    );
    // if error
    if (response.data.status === 'error') {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);

export const lifeBajajPlusBiPdf = createAsyncThunk(
  'lifeReduxSlice/lifeBajajPlusBiPdf',
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(
      `lifeInsurance/life/ebi-pdf/bajaj-life-plus`,
      params
    );
    // if error
    if (response.data.status === 'error') {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);

export const lifeBajajRopBiPdf = createAsyncThunk(
  'lifeReduxSlice/lifeBajajRopBiPdf',
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(
      `lifeInsurance/life/ebi-pdf/bajaj-life-rop`,
      params
    );
    // if error
    if (response.data.status === 'error') {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);


export const lifeGoDigitBIPdf = createAsyncThunk(
  'lifeReduxSlice/LifeGoDigitBIPdf',
  async (params, { rejectWithValue }) => {  
    var response;
    response = await apiPostCall(
      `lifeInsurance/life/ebi-pdf/goDigit-life`,
      params
    );
    // if error
    if (response.data.status === 'error') {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);

export const lifeIciciSjbEbiPdf = createAsyncThunk(
  "lifeReduxSlice/lifeIciciSjbEbiPdf",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`lifeInsurance/life/ebi-pdf/life-icici-sjb`, params);
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);
export const lifeIciciRopEbiPdf = createAsyncThunk(
  "lifeReduxSlice/lifeIciciRopEbiPdf",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`lifeInsurance/life/ebi-pdf/life-icici-rop`, params);
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);

// for the TATA sis PDF
export const lifeTataSISPdf = createAsyncThunk(
  "lifeReduxSlice/lifeTataSISPdf",
  async (params, { rejectWithValue }) => {
    let response;
    response = await apiPostCall(`lifeInsurance/life/ebi-pdf/tata-life-srp`, params);
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);

export const lifeTataMrsSISPdf = createAsyncThunk(
  "lifeReduxSlice/lifeTataMrsSISPdf",
  async (params, { rejectWithValue }) => {
    let response;
    response = await apiPostCall(`lifeInsurance/life/ebi-pdf/tata-mrss`, params);
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);

export const paymentRedirect = createAsyncThunk(
  "lifeReduxSlice/paymentRedirect",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`/lifeInsurance/life/payment-redirect/life-icici`, params);
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);
export const paymentRedirectGPP = createAsyncThunk(
  "lifeReduxSlice/paymentRedirectGPP",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`/lifeInsurance/life/payment-redirect/life-icici-GPP`, params);
  
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);
export const paymentRedirectGift = createAsyncThunk(
  "lifeReduxSlice/paymentRedirectGift",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`/lifeInsurance/life/payment-redirect/life-icici-gift`, params);
  
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);

// for SJB
export const paymentRedirectSJB = createAsyncThunk(
  "lifeReduxSlice/paymentRedirectSJB",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`/lifeInsurance/life/payment-redirect/life-icici-sjb`, params);
  
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);
export const paymentRedirectROP = createAsyncThunk(
  "lifeReduxSlice/paymentRedirectROP",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`/lifeInsurance/life/payment-redirect/life-icici-rop`, params);
  
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);
export const paymentRedirectMRS = createAsyncThunk(
  "lifeReduxSlice/paymentRedirectMRS",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`/lifeInsurance/life/payment-redirect/tata-mrss`, params);
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);
export const paymentRedirectGoDigit = createAsyncThunk(
  'lifeReduxSlice/paymentRedirectGoDigit',
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(
      `/lifeInsurance/life/payment-redirect/goDigit-life`,
      params
    );
    // if error
    if (response.data.status === 'error') {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);
// payment redirect for bajaj allianz
export const paymentRedirectBajaj = createAsyncThunk(
  'lifeReduxSlice/paymentRedirectBajaj',
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(
      `/lifeInsurance/life/payment-redirect/bajaj-life`,
      params
    );
    // if error
    if (response.data.status === 'error') {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);
export const paymentRedirectBajajPlus = createAsyncThunk(
  'lifeReduxSlice/paymentRedirectBajajPlus',
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(
      `/lifeInsurance/life/payment-redirect/bajaj-life-plus`,
      params
    );
    // if error
    if (response.data.status === 'error') {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);
export const paymentRedirectBajajRop = createAsyncThunk(
  'lifeReduxSlice/paymentRedirectBajajRop',
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(
      `/lifeInsurance/life/payment-redirect/bajaj-life-rop`,
      params
    );
    // if error
    if (response.data.status === 'error') {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);

export const paymentRedirectTATA = createAsyncThunk(
  "lifeReduxSlice/paymentRedirectTATA",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`/lifeInsurance/life/payment-redirect/tata-life-srp`, params);
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);

export const paymentRedirectHDFC = createAsyncThunk(
  "lifeReduxSlice/paymentRedirectHDFC",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`/lifeInsurance/life/payment-redirect/life-hdfc`, params);
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);

export const paymentRedirectHDFCPlus = createAsyncThunk(
  "lifeReduxSlice/paymentRedirectHDFCPlus",
  async (params, { rejectWithValue }) => {
    var response;
    response = await apiPostCall(`/lifeInsurance/life/payment-redirect/life-hdfc-plus`, params);
    // if error
    if (response.data.status === "error") {
      return rejectWithValue(response.data.data);
    }
    return response.data;
  }
);


export const IciciLifeQuote = createAsyncThunk(
  "life/IciciLifeQuote",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/life-icici`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data, 
        ...{
          newData: params.newData,
          enquiryId: params.enquiryId,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
)

// for the ICICI-IPS addons...
export const lifeQuoteUpdate = createAsyncThunk(
  'life/LifeQuoteUpdate',async(param,{rejectWithValue})=>{
    const response=await apiPostCall(
      '/lifeInsurance/life/quote/life-icici',param
    );
    if(response.data.status === "error") {
      return rejectWithValue(response.data)
    } else {
      return response.data
    }
  }
)

// for the HDFC life addons...
export const addonsUpdateHdfc = createAsyncThunk(
  'life/addonsUpdateHdfc',async(param,{rejectWithValue})=>{
    const response = await apiPostCall(
      '/lifeInsurance/life/quote/life-hdfc',param
    );
    if(response.data.status === "error") {
      return rejectWithValue(response.data)
    } else {
      return response.data
    }
  }
)
// for the HDFC life addons...
export const addonsUpdateHdfcPlus = createAsyncThunk(
  'life/addonsUpdateHdfcPlus',async(param,{rejectWithValue})=>{
    const response = await apiPostCall(
      '/lifeInsurance/life/quote/life-hdfc-plus',param
    );
    if(response.data.status === "error") {
      return rejectWithValue(response.data)
    } else {
      return response.data
    }
  }
)

// Rider for the TATA SRS
export const addonsUpdateTataSrs = createAsyncThunk(
  'life/addonsUpdateTataSrs',async(param,{rejectWithValue})=>{
    const response = await apiPostCall(
      '/lifeInsurance/life/quote/tata-life-srp',param
    );
    if(response.data.status === "error") {
      return rejectWithValue(response.data)
    } else {
      return response.data
    }
  }
)
// Rider for the Go Digit
export const addonsUpdateGoDigit = createAsyncThunk(
  'life/addonsUpdateGoDigit',
  async (param, { rejectWithValue }) => {
    const response = await apiPostCall(
      '/lifeInsurance/life/quote/goDigit-life',
      param
    );
    if (response.data.status === 'error') {
      return rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

// Rider for the TATA MRSS
export const addonsUpdateTataMrs = createAsyncThunk(
  'life/addonsUpdateTataMrs',async(param,{rejectWithValue})=>{
    const response = await apiPostCall(
      '/lifeInsurance/life/quote/tata-mrss',param
    );
    if(response.data.status === "error") {
      return rejectWithValue(response.data)
    } else {
      return response.data
    }
  }
)

export const lifeQuoteUpdateForRop = createAsyncThunk(
  'life/lifeQuoteUpdateForRop',async(param,{rejectWithValue})=>{
    const response=await apiPostCall(
      '/lifeInsurance/life/quote/life-icici-rop',param
    );
    if(response.data.status === "error") {
      return rejectWithValue(response.data)
    } else {
      return response.data
    } 
  }
)
export const IciciLifeQuoteGPP = createAsyncThunk(
  "life/IciciLifeQuoteGPP",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/life-icici-GPP`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
)
export const IciciLifeQuoteGift = createAsyncThunk(
  "life/IciciLifeQuoteGift",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/life-icici-gift`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
)
export const IciciLifeQuoteSJB = createAsyncThunk(
  "life/IciciLifeQuoteSJB",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/life-icici-sjb`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
)

// for ROP
export const IciciLifeQuoteROP = createAsyncThunk(
  "life/IciciLifeQuoteROP",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/life-icici-rop`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
)

export const KotakLifeQuote = createAsyncThunk(
  "KotakLife/quote",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/life-kotak`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);

// tata SRP product
export const TataLifeQuote = createAsyncThunk(
  "TataLife/quote",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/tata-life-srp`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);
export const TataLifeMrsQuote = createAsyncThunk(
  "TataLifeMrs/quote",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/tata-mrss`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);

// for bajaj allianz
export const BajajLifeQuote = createAsyncThunk(
  'BajajLifeQuote/quote',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/bajaj-life`,
      params
    );
    if (response.data.status === 'error') {
      return rejectWithValue(response.data);
    }
    if (params.newData == 'new') {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);
export const BajajLifePlusQuote = createAsyncThunk(
  'BajajLifePlusQuote/quote',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/bajaj-life-plus`,
      params
    );
    if (response.data.status === 'error') {
      return rejectWithValue(response.data);
    }
    if (params.newData == 'new') {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);
// for the bajaj plus addons...
export const bajajQuoteUpdate = createAsyncThunk(
  'life/bajajQuoteUpdate',
  async (param, { rejectWithValue }) => {
    const response = await apiPostCall(
      '/lifeInsurance/life/quote/bajaj-life-plus',
      param
    );
    if (response.data.status === 'error') {
      return rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const bajajRopQuoteUpdate = createAsyncThunk(
  'life/bajajRopQuoteUpdate',
  async (param, { rejectWithValue }) => {
    const response = await apiPostCall(
      '/lifeInsurance/life/quote/bajaj-life-rop',
      param
    );
    if (response.data.status === 'error') {
      return rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const bajajQuoteRiders = createAsyncThunk(
  'life/bajajQuoteRiders',
  async (param, { rejectWithValue }) => {
    const response = await apiPostCall(
      '/lifeInsurance/life/quote/bajaj-life',
      param
    );
    if (response.data.status === 'error') {
      return rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const BajajLifeRopQuote = createAsyncThunk(
  'BajajLifeRopQuote/quote',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/bajaj-life-rop`,
      params
    );
    if (response.data.status === 'error') {
      return rejectWithValue(response.data);
    }
    if (params.newData == 'new') {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);
// for go digit life..
export const GoDigitLifeQuote = createAsyncThunk(
  'GoDigitLifeQuote/quote',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/goDigit-life`,
      params
    );
    if (response.data.status === 'error') {
      return rejectWithValue(response.data);
    }
    return params.newData === 'new'
      ? { ...response.data, newData: params.newData }
      : response.data;
  }
);

export const resetRedux = createAsyncThunk(
  "life/resetRedux",
  async (params, { rejectWithValue }) => {
    return 0;
  }
);

export const resetLifeQuoteRedux = createAsyncThunk(
  "life/resetLifeQuoteRedux",
  async (params, { rejectWithValue }) => {
    return 0;
  }
);

// hdfc plus life quote
export const HdfcLifePlusQuote = createAsyncThunk(
  "HdfcLifePlus/quote",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/life-hdfc-plus`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);

// hdfc life quote
export const HdfcLifeQuote = createAsyncThunk(
  "HdfcLife/quote",
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(
      `/lifeInsurance/life/quote/life-hdfc`,
      params
    );
    if (response.data.status === "error") {
      return rejectWithValue(response.data);
    }
    if (params.newData == "new") {
      var obj2 = {
        ...response.data,
        ...{
          newData: params.newData,
        },
      };
      return obj2;
    } else {
      return response.data;
    }
  }
);






export const counterSlice = createSlice({
  name: 'lifeReduxSlice',
  initialState,
  reducers: {
    setLifeInsurance: (state, action) => {
      state.lifeData = action.payload;
    },
    removeLifeQuoteBySlug: (state, action) => {
      const slugToRemove = action.payload;
      state.lifeQuote = state.lifeQuote.filter(
        (entry) => entry.slug !== slugToRemove
      );
    },
  },

  extraReducers: {
    [updateFirstPageData.pending]: (state, action) => {
      state.isFetching = true;
    },
    [updateFirstPageData.rejected]: (state, action) => {
      state.isFetching = false;
        state.isFetching = false;
        state.error = action.payload || 'Something went wrong';
    },
    [updateFirstPageData.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    [paymentRedirect.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirect.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirect.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    //HDFC payment redirect
    [paymentRedirectHDFC.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirectHDFC.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirectHDFC.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    //HDFC payment redirect
    [paymentRedirectHDFCPlus.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirectHDFCPlus.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirectHDFCPlus.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    // for go digit life
    [paymentRedirectGoDigit.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirectGoDigit.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirectGoDigit.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    // for bajaj allianz
    [paymentRedirectBajaj.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirectBajaj.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirectBajaj.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    // for bajaj allianz
    [paymentRedirectBajajPlus.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirectBajajPlus.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirectBajajPlus.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    // EBI PDF FOR IPS
    [lifeIciciIpsEbiPdf.pending]: (state, action) => {
      state.isFetching = true;
    },
    [lifeIciciIpsEbiPdf.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [lifeIciciIpsEbiPdf.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    // bajaj life BI pdf
    [lifeBajajBiPdf.pending]: (state, action) => {
      state.isFetching = true;
    },
    [lifeBajajBiPdf.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [lifeBajajBiPdf.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    // bajaj life BI pdf
    [lifeBajajPlusBiPdf.pending]: (state, action) => {
      state.isFetching = true;
    },
    [lifeBajajPlusBiPdf.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [lifeBajajPlusBiPdf.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    //     // goDigit pdf
    // [lifeGoDigitBIPdf.pending]: (state, action) => {
    //   state.isFetching = true;
    // },
    // [lifeGoDigitBIPdf.rejected]: (state, action) => {
    //   state.isFetching = false;
    // },
    // [lifeGoDigitBIPdf.fulfilled]: (state, action) => {
    //   state.isFetching = false;
    //   state.combinedRedux = action.payload;
    // },

    // bajaj life plus addons - bajajQuoteUpdate
    [bajajQuoteUpdate.pending]: (state, action) => {
      state.isFetching = true;
    },
    [bajajQuoteUpdate.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [bajajQuoteUpdate.fulfilled]: (state, action) => {
       const existingIndex = state.lifeQuote.findIndex(
         (obj) => obj.slug === 'bajaj-life-plus'
       );
       if (existingIndex !== -1) {
         // If the object already exists, update it
         state.lifeQuote[existingIndex] = action.payload.data;
       }
       state.success = true;
       state.error = false;
    },

     [bajajRopQuoteUpdate.pending]: (state, action) => {
      state.isFetching = true;
    },
    [bajajRopQuoteUpdate.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [bajajRopQuoteUpdate.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'bajaj-life-rop'
      );
      if (existingIndex !== -1) {
        // If the object already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      }
      state.success = true;
      state.error = false;
    },

    [bajajQuoteRiders.pending]: (state, action) => {
      state.isFetching = true;
    },
    [bajajQuoteRiders.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [bajajQuoteRiders.fulfilled]: (state, action) => {
       const existingIndex = state.lifeQuote.findIndex(
         (obj) => obj.slug === 'bajaj-life'
       );
       if (existingIndex !== -1) {
         // If the object already exists, update it
         state.lifeQuote[existingIndex] = action.payload.data;
       }
       state.success = true;
       state.error = false;
    },

    // EBI PDF FOR SJB
    [lifeIciciSjbEbiPdf.pending]: (state, action) => {
      state.isFetching = true;
    },
    [lifeIciciSjbEbiPdf.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [lifeIciciSjbEbiPdf.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },
    // EBI PDF FOR ROP
    [lifeIciciRopEbiPdf.pending]: (state, action) => {
      state.isFetching = true;
    },
    [lifeIciciRopEbiPdf.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [lifeIciciRopEbiPdf.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    // SIS PDF FOR SRS
    [lifeTataSISPdf.pending]: (state, action) => {
      state.isFetching = true;
    },
    [lifeTataSISPdf.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [lifeTataSISPdf.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    [lifeTataMrsSISPdf.pending]: (state, action) => {
      state.isFetching = true;
    },
    [lifeTataMrsSISPdf.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [lifeTataMrsSISPdf.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    // redirection for GPP
    [paymentRedirectGPP.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirectGPP.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirectGPP.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    // redirection for Gift
    [paymentRedirectGift.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirectGift.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirectGift.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    // for SJB
    [paymentRedirectSJB.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirectSJB.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirectSJB.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },
    [paymentRedirectROP.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirectROP.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirectROP.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },
    [paymentRedirectSJB.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    [paymentRedirectTATA.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirectTATA.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirectTATA.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    [paymentRedirectMRS.pending]: (state, action) => {
      state.isFetching = true;
    },
    [paymentRedirectMRS.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [paymentRedirectMRS.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.combinedRedux = action.payload;
    },

    // Combine redux
    [lifeEnquiryAPI.pending]: (state, action) => {
      state.isFetching = true;
    },
    [lifeEnquiryAPI.rejected]: (state, action) => {
      state.isFetching = false;
    },
    [lifeEnquiryAPI.fulfilled]: (state, action) => {
      var payload = action.payload.data;
      console.log('action.payload.data', payload);
      state.combinedRedux = action.payload.data;
      state.success = true;
      state.error = false;
    },

    // hdfc life quote
    // [HdfcLifeQuote.pending]: (state, action) => {
    //   state.isFetching = true;
    // },
    // [HdfcLifeQuote.rejected]: (state, action) => {
    //   // state.isFetching = false
    //   state.error = true;
    // },
    // [HdfcLifeQuote.fulfilled]: (state, action) => {
    //   state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
    //   state.success = true;
    //   state.error = false;
    //   state.isFetching = false;
    // },

    //Life Quote
    [HdfcLifeQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [HdfcLifeQuote.rejected]: (state, action) => {
      state.error = true;
    },
    [HdfcLifeQuote.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'life-hdfc'
      );

      if (existingIndex !== -1) {
        // If the object with the slug "life-icici" already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      } else {
        // If it doesn't exist, add the new data to the array
        state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      }

      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    // [HdfcLifePlusQuote.pending]: (state, action) => {
    //   state.isFetching = true;
    // },
    // [HdfcLifePlusQuote.rejected]: (state, action) => {
    //   // state.isFetching = false
    //   state.error = true;
    // },
    // [HdfcLifePlusQuote.fulfilled]: (state, action) => {
    //   state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
    //   state.success = true;
    //   state.error = false;
    //   state.isFetching = false;
    // },
    [HdfcLifePlusQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [HdfcLifePlusQuote.rejected]: (state, action) => {
      state.error = true;
    },
    [HdfcLifePlusQuote.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'life-hdfc-plus'
      );

      if (existingIndex !== -1) {
        // If the object with the slug "hdfc-life-plus" already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      } else {
        // If it doesn't exist, add the new data to the array
        state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      }

      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    // tata life quote
    // [TataLifeQuote.pending]: (state, action) => {
    //   state.isFetching = true;
    // },
    // [TataLifeQuote.rejected]: (state, action) => {
    //   // state.isFetching = false
    //   state.error = true;
    // },
    // [TataLifeQuote.fulfilled]: (state, action) => {
    //   state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
    //   state.success = true;
    //   state.error = false;
    //   state.isFetching = false;
    // },
    [TataLifeQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [TataLifeQuote.rejected]: (state, action) => {
      state.error = true;
    },
    [TataLifeQuote.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'tata-life-srp'
      );

      if (existingIndex !== -1) {
        // If the object with the slug "tata-life-srp" already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      } else {
        // If it doesn't exist, add the new data to the array
        state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      }

      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    // [TataLifeMrsQuote.pending]: (state, action) => {
    //   state.isFetching = true;
    // },
    // [TataLifeMrsQuote.rejected]: (state, action) => {
    //   // state.isFetching = false
    //   state.error = true;
    // },
    // [TataLifeMrsQuote.fulfilled]: (state, action) => {
    //   state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
    //   state.success = true;
    //   state.error = false;
    //   state.isFetching = false;
    // },

    [TataLifeMrsQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [TataLifeMrsQuote.rejected]: (state, action) => {
      state.error = true;
    },
    [TataLifeMrsQuote.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'tata-mrss'
      );

      if (existingIndex !== -1) {
        // If the object with the slug "tata-mrss" already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      } else {
        // If it doesn't exist, add the new data to the array
        state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      }

      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    [BajajLifeQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [BajajLifeQuote.rejected]: (state, action) => {
      state.error = true;
    },
    [BajajLifeQuote.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'bajaj-life'
      );

      if (existingIndex !== -1) {
        // If the object with the slug "tata-mrss" already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      } else {
        // If it doesn't exist, add the new data to the array
        state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      }

      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    // for bajaj life plus
    [BajajLifePlusQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [BajajLifePlusQuote.rejected]: (state, action) => {
      state.error = true;
    },
    [BajajLifePlusQuote.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'bajaj-life-plus'
      );
      if (existingIndex !== -1) {
        // If the object with the slug "tata-mrss" already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      } else {
        // If it doesn't exist, add the new data to the array
        state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      }

      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    // for bajaj life rop
    [BajajLifeRopQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [BajajLifeRopQuote.rejected]: (state, action) => {
      state.error = true;
    },
    [BajajLifeRopQuote.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'bajaj-life-rop'
      );
      if (existingIndex !== -1) {
        // If the object with the slug "tata-mrss" already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      } else {
        // If it doesn't exist, add the new data to the array
        state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      }

      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    // for go digit life
    [GoDigitLifeQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [GoDigitLifeQuote.rejected]: (state, action) => {
        state.error = true;
        state.isFetching = false; 
    },
    [GoDigitLifeQuote.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'goDigit-life'
      );

      if (existingIndex !== -1) {
        // If the object with the slug "goDigit-life" already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      } else {
        // If it doesn't exist, add the new data to the array
        state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      }

      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    [addonsUpdateGoDigit.rejected]: (state, action) => {
      state.error = true;
    },
    [addonsUpdateGoDigit.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'goDigit-life'
      );
      if (existingIndex !== -1) {
        // If the object already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      }
      state.success = true;
      state.error = false;
    },

    // icici life quote

    [IciciLifeQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [IciciLifeQuote.rejected]: (state, action) => {
      // state.isFetching = false
      state.error = true;
    },
    [IciciLifeQuote.fulfilled]: (state, action) => {
      state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    // ICICI IPS addons quote update
    [lifeQuoteUpdate.rejected]: (state, action) => {
      state.error = true;
    },
    [lifeQuoteUpdate.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'life-icici'
      );
      if (existingIndex !== -1) {
        // If the object already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      }
      state.success = true;
      state.error = false;
    },

    //HDFC addons update
    [addonsUpdateHdfc.rejected]: (state, action) => {
      state.error = true;
    },
    [addonsUpdateHdfc.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'life-hdfc'
      );
      if (existingIndex !== -1) {
        // If the object already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      }
      state.success = true;
      state.error = false;
    },

    //HDFC Life Plus addons update
    [addonsUpdateHdfcPlus.rejected]: (state, action) => {
      state.error = true;
    },
    [addonsUpdateHdfcPlus.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'life-hdfc-plus'
      );
      if (existingIndex !== -1) {
        // If the object already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      }
      state.success = true;
      state.error = false;
    },

    // TATA SRS addons update
    [addonsUpdateTataSrs.rejected]: (state, action) => {
      state.error = true;
    },
    [addonsUpdateTataSrs.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'tata-life-srp'
      );
      if (existingIndex !== -1) {
        // If the object already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      }
      state.success = true;
      state.error = false;
    },

    // TATA MRSS addons update
    [addonsUpdateTataMrs.rejected]: (state, action) => {
      state.error = true;
    },
    [addonsUpdateTataMrs.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'tata-mrss'
      );
      if (existingIndex !== -1) {
        // If the object already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      }
      state.success = true;
      state.error = false;
    },

    // ICICI ROP quote update
    [lifeQuoteUpdateForRop.rejected]: (state, action) => {
      state.error = true;
    },
    [lifeQuoteUpdateForRop.fulfilled]: (state, action) => {
      const existingIndex = state.lifeQuote.findIndex(
        (obj) => obj.slug === 'life-icici-rop'
      );
      if (existingIndex !== -1) {
        // If the object already exists, update it
        state.lifeQuote[existingIndex] = action.payload.data;
      }
      state.success = true;
      state.error = false;
    },

    // ICICI GPP life quote
    [IciciLifeQuoteGPP.pending]: (state, action) => {
      state.isFetching = true;
    },
    [IciciLifeQuoteGPP.rejected]: (state, action) => {
      // state.isFetching = false
      state.error = true;
    },
    [IciciLifeQuoteGPP.fulfilled]: (state, action) => {
      state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    // ICICI Gift life quote
    [IciciLifeQuoteGift.pending]: (state, action) => {
      state.isFetching = true;
    },
    [IciciLifeQuoteGift.rejected]: (state, action) => {
      // state.isFetching = false
      state.error = true;
    },
    [IciciLifeQuoteGift.fulfilled]: (state, action) => {
      state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      state.success = true;
      state.error = false;
      state.isFetching = false;
    },

    // for SJB
    [IciciLifeQuoteSJB.pending]: (state, action) => {
      state.isFetching = true;
    },
    [IciciLifeQuoteSJB.rejected]: (state, action) => {
      // state.isFetching = false
      state.error = true;
    },
    [IciciLifeQuoteSJB.fulfilled]: (state, action) => {
      state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      state.success = true;
      state.error = false;
      state.isFetching = false;
    },
    // for ROP
    [IciciLifeQuoteROP.pending]: (state, action) => {
      state.isFetching = true;
    },
    [IciciLifeQuoteROP.rejected]: (state, action) => {
      // state.isFetching = false
      state.error = true;
    },
    [IciciLifeQuoteROP.fulfilled]: (state, action) => {
      state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      state.success = true;
      state.error = false;
      state.isFetching = false;
    },
    // kotak life quote
    [KotakLifeQuote.pending]: (state, action) => {
      state.isFetching = true;
    },
    [KotakLifeQuote.rejected]: (state, action) => {
      // state.isFetching = false
      state.error = true;
    },
    [KotakLifeQuote.fulfilled]: (state, action) => {
      state.lifeQuote = [...state.lifeQuote, { ...action.payload.data }];
      state.success = true;
      state.error = false;
      state.isFetching = false;
    },
    [resetLifeQuoteRedux.fulfilled]: (state, action) => {
      state.lifeQuote = [];
    },
  },
});

export const { setLifeInsurance, removeLifeQuoteBySlug } = counterSlice.actions;
export default counterSlice.reducer;
