import React, { useEffect, useState } from 'react';
import { Container, Title, RadioGroup, RadioLabel, Input, Select, DateInput, TextInput, ButtonGroup, Button1 } from './tpStyles';
import { PrevInsurerData } from '../../Redux/CarsRedux';
import { useSelector, useDispatch } from "react-redux";
const moment = require('moment');
const WarningPopup = ({ closePopUp, removeCPA }) => {
    const [tpInsurer, setTPInsurer] = useState("");
    const [tpPolicyNumber, setTPPolicyNumber] = useState("");
    const [tpExpiryDate, setTPExpiryDate] = useState("");
    const [selectedReason, setSelectedReason] = useState("");
    const prevInsurerRedux = useSelector((state) => state.carsRedux.prevInsurerList);
    const dispatch = useDispatch();

    useEffect(() => {
        if (prevInsurerRedux.length === 0)
            dispatch(PrevInsurerData({}))
    }, [])
    const handlePrevInsurer = () => {
        removeCPA(tpInsurer, tpPolicyNumber, tpExpiryDate)
    }
    return (
        <Container>
            <Title>Please choose reason for not opting owner driver cover</Title>
            <RadioGroup>
                <RadioLabel>
                    <Input
                        type="radio"
                        name="reason"
                        value="no_license"
                        onChange={() => setSelectedReason("no_license")}
                    />
                    Do Not have valid driving licence
                </RadioLabel>
                <RadioLabel>
                    <Input
                        type="radio"
                        name="reason"
                        value="valid_pa_cover"
                        onChange={() => setSelectedReason("valid_pa_cover")}
                    />
                    Do You have valid PA Cover Policy for SI 15 Lakhs and Above (Including Death, Total disability, partial disability)
                </RadioLabel>
            </RadioGroup>
            {selectedReason === "valid_pa_cover" && (
                <div className='details'>
                    <div>
                        <label>Previous PA Policy Insurer</label>
                        <Select onChange={e => setTPInsurer(e.target.value)}>
                            <option disabled>Select Previous PA insurer</option>
                            {
                                prevInsurerRedux.map((element) => {
                                    return <option key={element._id} value={element.name}>{element.name}</option>
                                })
                            }
                        </Select>
                    </div>
                    <div>
                        <label>Previous CPA Expiry Date</label>
                        <DateInput type="date" onChange={e => setTPExpiryDate(e.target.value)} value={tpExpiryDate ? moment(tpExpiryDate).format('YYYY-MM-DD') : ''} />
                    </div>
                    <div>
                        <label>Previous CPA Policy Number</label>
                        <TextInput type="text" onChange={e => setTPPolicyNumber(e.target.value)} />
                    </div>
                </div>
            )}
            <ButtonGroup>
                <Button1 className='tpButton' onClick={closePopUp}>Cancel</Button1>
                <Button1 className='tpButton' onClick={handlePrevInsurer}>Save</Button1>
            </ButtonGroup>
        </Container>
    );
};


export default WarningPopup;